<template>
    <div class="skin-gallery">
        <div class="s-g-title-wrapp" v-scroll-lock="isShowed">
            <div class="s-g-title">
                Галерея скинов
            </div>
        </div>

        <div class="setupInfo">
            <div v-if="capeTiming !== null && (capeTiming / 1000) > 0">
                Бесплатная замена <span style="color: #00e6ff">плаща:</span>
                <span>{{ this.timerTimeFormatting(this.capeTiming) }}</span>.
            </div>
            <div v-if="hd_skinTiming !== null && (hd_skinTiming / 1000) > 0">
                Бесплатная замена <span style="color: #ff3333">HD скина:</span>
                <span> {{ this.timerTimeFormatting(this.hd_skinTiming) }}</span>.
            </div>
            <div v-if="hd_capeTiming !== null && (hd_capeTiming / 1000) > 0">
                Бесплатная замена <span style="color: #ffb500">HD плаща: </span>
                <span> {{ this.timerTimeFormatting(this.hd_capeTiming) }}</span>.
            </div>
        </div>

        <div class="b-b-sm"/>

        <div class="s-g-menu">
            <div class="s-g-menu-el" :class="{'active_tab': frames.mySkinsTab}"
                 @click="changeFrameCustom('mySkinsTab', frames); k+=1">
                Мои скины
            </div>
            <div class="s-g-menu-el" :class="{'active_tab': frames.myCapesTab}"
                 @click="changeFrameCustom('myCapesTab', frames); k+=1">
                Мои плащи
            </div>
            <div class="s-g-menu-el" :class="{'active_tab': frames.publicSkinsTab}" v-tooltip="{content: 'Тут можно посмотреть публичные <span>скины</span> других игроков :)',
                                    theme:'GalleryToolTips-top',
                                    shown: false,
                                    html:true}"
                 @click="changeFrameCustom('publicSkinsTab', frames); k+=1">
                Публичные скины
            </div>
            <div class="s-g-menu-el" :class="{'active_tab': frames.publicCapesTab}" v-tooltip="{content: 'Тут можно посмотреть публичные <span>плащи</span> других игроков :)',
                                    theme:'GalleryToolTips-top',
                                    shown: false,
                                    html:true}"
                 @click="changeFrameCustom('publicCapesTab', frames); k+=1">
                Публичные плащи
            </div>
        </div>

        <div class="s-g-action-frame-wrappa" v-if="frames.mySkinsTab" :key="k">
            <MyGallery v-bind:type="'skin'" v-bind:filters="`player_id=${$root.getPlayerData().id}&nogroup=1`"
                       v-bind:pp="pCountToLoad"/>
        </div>

        <div class="s-g-action-frame-wrappa" v-if="frames.myCapesTab" :key="k">
            <MyGallery v-bind:type="'cape'" v-bind:filters="`player_id=${$root.getPlayerData().id}&nogroup=1`"
                       v-bind:pp="pCountToLoad"/>
        </div>

        <div class="s-g-action-frame-wrappa" v-if="frames.publicSkinsTab" :key="k">
            <PublicGallery type="skin" v-bind:filters="`type=public`" v-bind:pp="pCountToLoad"/>
        </div>

        <div class="s-g-action-frame-wrappa" v-if="frames.publicCapesTab" :key="k">
            <PublicGallery type="cape" v-bind:filters="`type=public`" v-bind:pp="pCountToLoad"/>
        </div>

        <div class="paginationRow">
            <div class="pagesCount">
                {{ pCurrent }} / {{ pCountAll }}
            </div>

            <div class="pagesCount">
                Текстур : {{ txtrTotal }}
            </div>


            <div class="toUp" v-tooltip="{content: 'Загрузить <span>прошлую</span> страницу',
                                    theme:'GalleryToolTips-top',
                                    html:true}" v-if="pCurrent > 1 ">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white"
                     class="bi bi-arrow-up-circle" viewBox="0 0 16 16" @click="doThatShit('loadBack')">
                    <path fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                </svg>
            </div>
            <div class="toDown" v-tooltip="{content: 'Загрузить <span>следующую</span> страницу',
                                    theme:'GalleryToolTips-top',
                                    html:true}" v-if="pCurrent != pCountAll">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white"
                     class="bi bi-arrow-up-circle" viewBox="0 0 16 16" @click="doThatShit('loadMore')">
                    <path fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                </svg>
            </div>
            <input v-tooltip="{content: '<span>Количество</span> элементов на странице.<br> <span>Внимание!:</span> установка большого количества отображаемых текстур негативно влияет на производительность в браузере google chrome. К firefox - это не относится.',
                                    theme:'GalleryToolTips-top-moreWidth',
                                    html:true}" class="s-m-input" v-on:keyup.enter="doThatShit('reload')"
                   v-on:keypress="onlyNumber" v-model="pCountToLoad">
        </div>

    </div>
</template>

<script>

import {ChangeTabMixin} from "@/mixins/ChangeTabMixin";
import {sexyTooltipMixin} from "@/mixins/SexyTooltipMixin";
import {TextInputControlMixin} from "@/mixins/TextInputControlMixin";
import MyGallery from "@/components/elements/lk/LkSkinGallery/MyGallery";
import PublicGallery from "@/components/elements/lk/LkSkinGallery/PublicGallery";

export default {
    name: "LkSkinGalleryModal",
    mixins: [ChangeTabMixin, sexyTooltipMixin, TextInputControlMixin],
    components: {MyGallery, PublicGallery},
    props: [],
    data() {
        return {
            myCapes: null,
            k: 0,

            pCountToLoad: 8,
            pCountAll: null,
            pCurrent: null,
            txtrEvType: null,
            txtrFilters: null,
            txtrTotal: null,

            isShowed: true,

            frames: {
                'mySkinsTab': true,
                'myCapesTab': false,
                'publicSkinsTab': false,
                'publicCapesTab': false,
            }

        }
    },
    methods: {
        selectPagesCountToLoad($count) {
            this.pCountToLoad = $count
        },

        doThatShit(operation) {
            this.$root.$emit('sempaiPlzLoadMoreThatShit', {
                perPage: this.pCountToLoad,
                type: this.txtrEvType,
                filters: this.txtrFilters,
                operation: operation
            })
        },
    },

    created() {
        this.$root.$on('sexyGalleryUpdTimer', () => {
            this.$root.update_PlayerData().then(() => {
                this.syncTxrTimings('gallery')
            })
        })
    },

    mounted() {
        this.$root.update_PlayerData().then(() => {
            this.syncTxrTimings('gallery')
        })

        this.$root.$on('pagesCountPipe', (data) => {
            this.pCountAll = data.pCountAll
            this.pCurrent = data.pCurrent
            this.txtrEvType = data.txtrEvType
            this.txtrFilters = data.txtrFilters
            this.pCountToLoad = data.ppage
            this.txtrTotal = data.txtrTotal
        })

    },

    beforeDestroy() {
        this.isShowed = false
        this.$root.$off('sexyGalleryUpdTimer')
        this.$root.$off('pagesCountPipe')
    }

}
</script>

<style scoped lang="scss">

@import "src/assets/scss/vars";
@import "src/assets/scss/mixins";
@import "src/assets/scss/animations";

.skin-gallery {
    -webkit-user-select: none;
    @include flexible-column-start();
    z-index: 50000;
    width: 900px;
    height: 500px;
    padding: 20px 0px;
    background: rgba(28, 28, 29, 0.90);

    .s-g-title-wrapp {
        @include flexible-row-start();
        @include title-1-2();
        width: 92%;
        color: $brightless-white;
    }

    .s-g-menu {
        @include flexible-row-start;
        width: 95%;

        margin-top: 10px;

        .s-g-menu-el {
            @include actions-els();
        }
    }

    .s-g-action-frame-wrappa {
        @include flexible-row-start;
        align-items: flex-start;
        justify-content: flex-start;
        width: 95%;
        height: 80%;

        margin-top: 10px;

        border: 1px solid rgba(128, 128, 128, 0.66);
    }

}

.b-b-sm {
    @include smooth-border();
    width: 92%;
    margin-top: 10px;
}

@include active-el;

.setupInfo {
    @include flexible-column;
    align-items: flex-end;
    @include desctiption-0-8;
    position: absolute;
    top: 5px;
    right: 20px;
    color: $brightless-gray;

    span {
        color: #00ff00;
    }
}

.paginationRow {
    @include flexible-row-start;
    justify-content: flex-end;
    position: absolute;
    bottom: 5px;
    right: 30px;
    width: auto;
    height: 30px;
    border-bottom: 1px solid grey;
    padding-bottom: 1px;

    .pagesCount {
        @include desctiption-0-8;
        color: $not-bright-white;
        margin-right: 5px;
    }

    .s-m-input {
        width: 30px;
        height: 20px;
        border-radius: 2px;
        background-color: rgb(31, 32, 35);
        border: 1px solid rgb(38, 44, 57);
        outline: none;
        color: white;
        text-align: center;
        font-family: $main-font;
        font-size: 0.8em;
        margin-left: 5px;
    }

    .toUp {
        height: 20px;
    }

    .toDown {
        transform: rotate(180deg);
        height: 30px;
        width: 29px;
    }

    .toUp, .toDown {
        margin-bottom: 4px;
        cursor: pointer;
    }

    .labelz {
        @include desctiption-0-8;
        color: rgba(0, 255, 0, 0.8);
    }

}

</style>