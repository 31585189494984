<template>

    <!--    .mc-skin-viewer(2);-->

    <!--    .mc-skin-viewer(4);-->

    <!--    .mc-skin-viewer(5);-->

    <!--    .mc-skin-viewer(7);-->

    <!--    .mc-skin-viewer(8);-->

    <!--    .mc-skin-viewer(9);-->
    <div id="skin-viewer"
         class="setViewPosition legacy cape"
         v-bind:style="userStyle"
         :class="{'mc-skin-viewer-7x': frame === 'lkSkinFront' || frame === 'lkSkinBack',
                'setViewPositionFront': frame === 'lkSkinFront',
                'setViewPositionBack': frame === 'lkSkinBack',
                'mc-skin-viewer-2x': frame ==='mainMenuSkin',
                'mc-skin-viewer-4x': frame === 'accountsSkin',
                'spin': spinSkin}"
         @mouseenter="spinIt" @mouseleave="stopIt"
    >

        <div class="player" :class="{'mgnBot': frame === 'mainMenuSkin', 'headOnly': frame === 'accountsSkin', }"
        >
            <!-- Head -->
            <div class="head">
                <div class="top"></div>
                <div class="left"></div>
                <div class="front"></div>
                <div class="right"></div>
                <div class="back"></div>
                <div class="bottom"></div>
                <div class="accessory">
                    <div class="top"></div>
                    <div class="left"></div>
                    <div class="front"></div>
                    <div class="right"></div>
                    <div class="back"></div>
                    <div class="bottom"></div>
                </div>
            </div>
            <!-- Body -->
            <div class="body" v-if="frame !== 'accountsSkin'">
                <div class="top"></div>
                <div class="left"></div>
                <div class="front"></div>
                <div class="right"></div>
                <div class="back"></div>
                <div class="bottom"></div>
                <div class="accessory">
                    <div class="top"></div>
                    <div class="left"></div>
                    <div class="front"></div>
                    <div class="right"></div>
                    <div class="back"></div>
                    <div class="bottom"></div>
                </div>
            </div>
            <!-- Left Arm -->
            <div class="left-arm" v-if="frame !== 'accountsSkin'">
                <div class="top"></div>
                <div class="left"></div>
                <div class="front"></div>
                <div class="right"></div>
                <div class="back"></div>
                <div class="bottom"></div>
                <div class="accessory">
                    <div class="top"></div>
                    <div class="left"></div>
                    <div class="front"></div>
                    <div class="right"></div>
                    <div class="back"></div>
                    <div class="bottom"></div>
                </div>
            </div>
            <!-- Right Arm -->
            <div class="right-arm" v-if="frame !== 'accountsSkin'">
                <div class="top"></div>
                <div class="left"></div>
                <div class="front"></div>
                <div class="right"></div>
                <div class="back"></div>
                <div class="bottom"></div>
                <div class="accessory">
                    <div class="top"></div>
                    <div class="left"></div>
                    <div class="front"></div>
                    <div class="right"></div>
                    <div class="back"></div>
                    <div class="bottom"></div>
                </div>
            </div>
            <!-- Left Leg -->
            <div class="left-leg" v-if="frame !== 'accountsSkin'">
                <div class="top"></div>
                <div class="left"></div>
                <div class="front"></div>
                <div class="right"></div>
                <div class="back"></div>
                <div class="bottom"></div>
                <div class="accessory">
                    <div class="top"></div>
                    <div class="left"></div>
                    <div class="front"></div>
                    <div class="right"></div>
                    <div class="back"></div>
                    <div class="bottom"></div>
                </div>
            </div>
            <!-- Right Leg -->
            <div class="right-leg" v-if="frame !== 'accountsSkin'">
                <div class="top"></div>
                <div class="left"></div>
                <div class="front"></div>
                <div class="right"></div>
                <div class="back"></div>
                <div class="bottom"></div>
                <div class="accessory">
                    <div class="top"></div>
                    <div class="left"></div>
                    <div class="front"></div>
                    <div class="right"></div>
                    <div class="back"></div>
                    <div class="bottom"></div>
                </div>
            </div>
            <!-- Cape -->
            <div class="cape">
                <div class="top"></div>
                <div class="left"></div>
                <div class="front"></div>
                <div class="right"></div>
                <div class="back"></div>
                <div class="bottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkinViewer',
    props: ['skinUrl', 'capeUrl', 'frame', 'gallery'],
    data() {
        return {
            spinSkin: false,
        }
    },

    methods: {
        spinIt() {
            this.spinSkin = true
        },
        stopIt() {
            this.spinSkin = false
        },
    },

    computed: {
        userStyle() {
            return {
                '--skin-url': this.skinUrl !== undefined ? `url(${this.skinUrl})` : undefined,
                '--cape-url': this.capeUrl !== undefined ? `url(${this.capeUrl})` : undefined
            }
        }
    },
}


</script>

<style scoped lang="scss">
/*Ничего тут не трогать*/

@if(--skin-url != undefined) {
    #skin-viewer * {
        background-image: var(--skin-url);
    }
}

@if(--cape-url != undefined) {
    #skin-viewer .cape {
        background-image: var(--cape-url);
    }
}

.mc-skin-viewer-2x .player .front, .mc-skin-viewer-2x .player .back, .mc-skin-viewer-2x .player .left, .mc-skin-viewer-2x .player .right, .mc-skin-viewer-2x .player .top, .mc-skin-viewer-2x .player .bottom {
    position: absolute;
    background-size: 128px;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-2x .player .accessory {
    background-image: none
}

.mc-skin-viewer-2x .player .accessory .front, .mc-skin-viewer-2x .player .accessory .back, .mc-skin-viewer-2x .player .accessory .left, .mc-skin-viewer-2x .player .accessory .right, .mc-skin-viewer-2x .player .accessory .top, .mc-skin-viewer-2x .player .accessory .bottom {
    -webkit-backface-visibility: visible;
    backface-visibility: visible
}

.mc-skin-viewer-2x .player > .head {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    background-image: none !important
}

.mc-skin-viewer-2x .player > .head .front, .mc-skin-viewer-2x .player > .head .back, .mc-skin-viewer-2x .player > .head .left, .mc-skin-viewer-2x .player > .head .right, .mc-skin-viewer-2x .player > .head .top, .mc-skin-viewer-2x .player > .head .bottom {
    width: 16px;
    height: 16px
}

.mc-skin-viewer-2x .player > .head .front {
    background-position: -16px -16px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-2x .player > .head .back {
    background-position: -48px -16px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-2x .player > .head .right {
    background-position: 0 -16px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-2x .player > .head .left {
    background-position: -32px -16px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-2x .player > .head .top {
    background-position: -16px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-2x .player > .head .bottom {
    background-position: -32px 0;
    -webkit-transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-2x .player > .head > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.125, 1.125, 1.125) translateY(0px);
    transform: scale3d(1.125, 1.125, 1.125) translateY(0px)
}

.mc-skin-viewer-2x .player > .head > .accessory > .front {
    background-position: -80px -16px
}

.mc-skin-viewer-2x .player > .head > .accessory > .back {
    background-position: -112px -16px
}

.mc-skin-viewer-2x .player > .head > .accessory > .right {
    background-position: -64px -16px
}

.mc-skin-viewer-2x .player > .head > .accessory > .left {
    background-position: -96px -16px
}

.mc-skin-viewer-2x .player > .head > .accessory > .top {
    background-position: -80px 0
}

.mc-skin-viewer-2x .player > .head > .accessory > .bottom {
    background-position: -96px 0
}

.mc-skin-viewer-2x .player > .body {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    -webkit-transform: translateY(16px);
    -ms-transform: translateY(16px);
    transform: translateY(16px);
    background-image: none !important
}

.mc-skin-viewer-2x .player > .body .front, .mc-skin-viewer-2x .player > .body .back, .mc-skin-viewer-2x .player > .body .left, .mc-skin-viewer-2x .player > .body .right, .mc-skin-viewer-2x .player > .body .top, .mc-skin-viewer-2x .player > .body .bottom {
    width: 16px;
    height: 24px
}

.mc-skin-viewer-2x .player > .body .left, .mc-skin-viewer-2x .player > .body .right {
    width: 8px
}

.mc-skin-viewer-2x .player > .body .top, .mc-skin-viewer-2x .player > .body .bottom {
    height: 8px
}

.mc-skin-viewer-2x .player > .body .front {
    background-position: -40px -40px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .body .back {
    background-position: -64px -40px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .body .right {
    background-position: -32px -40px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .body .left {
    background-position: -56px -40px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(12px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(12px)
}

.mc-skin-viewer-2x .player > .body .top {
    background-position: -40px -32px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .body .bottom {
    background-position: -56px -32px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-2x .player > .body > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-0.75px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-0.75px)
}

.mc-skin-viewer-2x .player > .body > .accessory > .front {
    background-position: -40px -72px
}

.mc-skin-viewer-2x .player > .body > .accessory > .back {
    background-position: -64px -72px
}

.mc-skin-viewer-2x .player > .body > .accessory > .right {
    background-position: -32px -72px
}

.mc-skin-viewer-2x .player > .body > .accessory > .left {
    background-position: -56px -72px
}

.mc-skin-viewer-2x .player > .body > .accessory > .top {
    background-position: -40px -64px
}

.mc-skin-viewer-2x .player > .body > .accessory > .bottom {
    background-position: -56px -64px
}

.mc-skin-viewer-2x .player > .left-arm, .mc-skin-viewer-2x .player > .right-arm {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 8px;
    height: 24px;
    margin: 0 auto;
    -webkit-transform: translateY(16px) translateX(-8px);
    -ms-transform: translateY(16px) translateX(-8px);
    transform: translateY(16px) translateX(-8px);
    background-image: none !important
}

.mc-skin-viewer-2x .player > .left-arm .front, .mc-skin-viewer-2x .player > .right-arm .front, .mc-skin-viewer-2x .player > .left-arm .back, .mc-skin-viewer-2x .player > .right-arm .back, .mc-skin-viewer-2x .player > .left-arm .left, .mc-skin-viewer-2x .player > .right-arm .left, .mc-skin-viewer-2x .player > .left-arm .right, .mc-skin-viewer-2x .player > .right-arm .right, .mc-skin-viewer-2x .player > .left-arm .top, .mc-skin-viewer-2x .player > .right-arm .top, .mc-skin-viewer-2x .player > .left-arm .bottom, .mc-skin-viewer-2x .player > .right-arm .bottom {
    width: 8px;
    height: 24px
}

.mc-skin-viewer-2x .player > .left-arm .top, .mc-skin-viewer-2x .player > .right-arm .top, .mc-skin-viewer-2x .player > .left-arm .bottom, .mc-skin-viewer-2x .player > .right-arm .bottom {
    height: 8px
}

.mc-skin-viewer-2x .player > .left-arm .front, .mc-skin-viewer-2x .player > .right-arm .front {
    background-position: -88px -40px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-arm .back, .mc-skin-viewer-2x .player > .right-arm .back {
    background-position: -104px -40px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-arm .right, .mc-skin-viewer-2x .player > .right-arm .right {
    background-position: -80px -40px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-arm .left, .mc-skin-viewer-2x .player > .right-arm .left {
    background-position: -96px -40px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-arm .top, .mc-skin-viewer-2x .player > .right-arm .top {
    background-position: -88px -32px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-arm .bottom, .mc-skin-viewer-2x .player > .right-arm .bottom {
    background-position: -96px -32px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-2x .player > .left-arm > .accessory, .mc-skin-viewer-2x .player > .right-arm > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-0.75px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-0.75px)
}

.mc-skin-viewer-2x .player > .left-arm > .accessory .front, .mc-skin-viewer-2x .player > .right-arm > .accessory .front {
    background-position: -88px -72px
}

.mc-skin-viewer-2x .player > .left-arm > .accessory .back, .mc-skin-viewer-2x .player > .right-arm > .accessory .back {
    background-position: -104px -72px
}

.mc-skin-viewer-2x .player > .left-arm > .accessory .right, .mc-skin-viewer-2x .player > .right-arm > .accessory .right {
    background-position: -80px -72px
}

.mc-skin-viewer-2x .player > .left-arm > .accessory .left, .mc-skin-viewer-2x .player > .right-arm > .accessory .left {
    background-position: -96px -72px
}

.mc-skin-viewer-2x .player > .left-arm > .accessory .top, .mc-skin-viewer-2x .player > .right-arm > .accessory .top {
    background-position: -88px -64px
}

.mc-skin-viewer-2x .player > .left-arm > .accessory .bottom, .mc-skin-viewer-2x .player > .right-arm > .accessory .bottom {
    background-position: -96px -64px
}

.mc-skin-viewer-2x .player > .right-arm {
    -webkit-transform: translateY(16px) translateX(16px) scaleX(-1);
    -ms-transform: translateY(16px) translateX(16px) scaleX(-1);
    transform: translateY(16px) translateX(16px) scaleX(-1)
}

.mc-skin-viewer-2x .player > .left-leg, .mc-skin-viewer-2x .player > .right-leg {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 8px;
    height: 24px;
    margin: 0 auto;
    -webkit-transform: translateY(40px);
    -ms-transform: translateY(40px);
    transform: translateY(40px);
    background-image: none !important
}

.mc-skin-viewer-2x .player > .left-leg .front, .mc-skin-viewer-2x .player > .right-leg .front, .mc-skin-viewer-2x .player > .left-leg .back, .mc-skin-viewer-2x .player > .right-leg .back, .mc-skin-viewer-2x .player > .left-leg .left, .mc-skin-viewer-2x .player > .right-leg .left, .mc-skin-viewer-2x .player > .left-leg .right, .mc-skin-viewer-2x .player > .right-leg .right, .mc-skin-viewer-2x .player > .left-leg .top, .mc-skin-viewer-2x .player > .right-leg .top, .mc-skin-viewer-2x .player > .left-leg .bottom, .mc-skin-viewer-2x .player > .right-leg .bottom {
    width: 8px;
    height: 24px
}

.mc-skin-viewer-2x .player > .left-leg .top, .mc-skin-viewer-2x .player > .right-leg .top, .mc-skin-viewer-2x .player > .left-leg .bottom, .mc-skin-viewer-2x .player > .right-leg .bottom {
    height: 8px
}

.mc-skin-viewer-2x .player > .left-leg .front, .mc-skin-viewer-2x .player > .right-leg .front {
    background-position: -8px -40px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-leg .back, .mc-skin-viewer-2x .player > .right-leg .back {
    background-position: -24px -40px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-leg .right, .mc-skin-viewer-2x .player > .right-leg .right {
    background-position: 0 -40px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-leg .left, .mc-skin-viewer-2x .player > .right-leg .left {
    background-position: -16px -40px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-leg .top, .mc-skin-viewer-2x .player > .right-leg .top {
    background-position: -8px -32px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x .player > .left-leg .bottom, .mc-skin-viewer-2x .player > .right-leg .bottom {
    background-position: -16px -32px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-2x .player > .left-leg > .accessory, .mc-skin-viewer-2x .player > .right-leg > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-0.375px);
    transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-0.375px)
}

.mc-skin-viewer-2x .player > .left-leg > .accessory .front, .mc-skin-viewer-2x .player > .right-leg > .accessory .front {
    background-position: -8px -72px
}

.mc-skin-viewer-2x .player > .left-leg > .accessory .back, .mc-skin-viewer-2x .player > .right-leg > .accessory .back {
    background-position: -24px -72px
}

.mc-skin-viewer-2x .player > .left-leg > .accessory .right, .mc-skin-viewer-2x .player > .right-leg > .accessory .right {
    background-position: 0 -72px
}

.mc-skin-viewer-2x .player > .left-leg > .accessory .left, .mc-skin-viewer-2x .player > .right-leg > .accessory .left {
    background-position: -16px -72px
}

.mc-skin-viewer-2x .player > .left-leg > .accessory .top, .mc-skin-viewer-2x .player > .right-leg > .accessory .top {
    background-position: -8px -64px
}

.setViewPositionFront {
    -webkit-transform: rotateX(-30deg) rotateY(45deg);
    transform: rotateX(-30deg) rotateY(45deg);
}

.setViewPositionBack {
    -webkit-transform: rotateX(-30deg) rotateY(225deg);
    transform: rotateX(-30deg) rotateY(225deg);
}

.mgnBot {
    margin-top: -8px;
}

.headOnly {
    margin-top: -14px;
}


@-webkit-keyframes spin {
    from {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
    to {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
}


@keyframes spin {
    from {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
    to {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
}

@-webkit-keyframes waving {
    0% {
        -webkit-transform: rotateX(-2deg);
        transform: rotateX(-2deg);
    }
    50% {
        -webkit-transform: rotateX(2deg);
        transform: rotateX(2deg);
    }
    100% {
        -webkit-transform: rotateX(-2deg);
        transform: rotateX(-2deg);
    }
}

@keyframes waving {
    0% {
        -webkit-transform: rotateX(-2deg);
        transform: rotateX(-2deg);
    }
    50% {
        -webkit-transform: rotateX(2deg);
        transform: rotateX(2deg);
    }
    100% {
        -webkit-transform: rotateX(-2deg);
        transform: rotateX(-2deg);
    }
}


.mc-skin-viewer-2x {
    width: 16px;
    height: 64px;
    margin: 15px auto 40px;
    -webkit-perspective: 800px;
    perspective: 800px;
    -webkit-perspective-origin: 50% 100px;
    perspective-origin: 50% 100px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-image: none !important;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor
}

.mc-skin-viewer-2x.hide-accessories .accessory {
    display: none
}

.mc-skin-viewer-2x.spin > .player {
    -webkit-animation: spin 12s infinite linear;
    animation: spin 12s infinite linear
}

.mc-skin-viewer-2x .player {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.mc-skin-viewer-2x .player > .left-leg > .accessory .bottom, .mc-skin-viewer-2x .player > .right-leg > .accessory .bottom {
    background-position: -16px -64px
}

.mc-skin-viewer-2x .player > .right-leg {
    -webkit-transform: translateY(40px) translateX(8px) scaleX(-1);
    -ms-transform: translateY(40px) translateX(8px) scaleX(-1);
    transform: translateY(40px) translateX(8px) scaleX(-1)
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm {
    -webkit-transform: translateY(16px) translateX(16px);
    -ms-transform: translateY(16px) translateX(16px);
    transform: translateY(16px) translateX(16px)
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm .front {
    background-position: -72px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm .back {
    background-position: -88px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm .right {
    background-position: -64px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm .left {
    background-position: -80px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm .top {
    background-position: -72px -96px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm .bottom {
    background-position: -80px -96px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm > .accessory .front {
    background-position: -104px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm > .accessory .back {
    background-position: -120px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm > .accessory .right {
    background-position: -96px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm > .accessory .left {
    background-position: -112px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm > .accessory .top {
    background-position: -104px -96px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-arm > .accessory .bottom {
    background-position: -112px -96px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg {
    -webkit-transform: translateY(40px) translateX(8px);
    -ms-transform: translateY(40px) translateX(8px);
    transform: translateY(40px) translateX(8px)
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg .front {
    background-position: -40px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg .back {
    background-position: -56px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg .right {
    background-position: -32px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg .left {
    background-position: -48px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg .top {
    background-position: -40px -96px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg .bottom {
    background-position: -48px -96px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg > .accessory .front {
    background-position: -8px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg > .accessory .back {
    background-position: -24px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg > .accessory .right {
    background-position: 0 -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg > .accessory .left {
    background-position: -16px -104px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg > .accessory .top {
    background-position: -8px -96px
}

.mc-skin-viewer-2x:not(.legacy) .player > .right-leg > .accessory .bottom {
    background-position: -16px -96px
}

.mc-skin-viewer-2x.slim .player > .left-arm .front, .mc-skin-viewer-2x.slim .player > .right-arm .front, .mc-skin-viewer-2x.slim .player > .left-arm .back, .mc-skin-viewer-2x.slim .player > .right-arm .back, .mc-skin-viewer-2x.slim .player > .left-arm .top, .mc-skin-viewer-2x.slim .player > .right-arm .top, .mc-skin-viewer-2x.slim .player > .left-arm .bottom, .mc-skin-viewer-2x.slim .player > .right-arm .bottom {
    width: 6px
}

.mc-skin-viewer-2x.slim .player > .left-arm .front {
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(2px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(0deg) translateX(2px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x.slim .player > .left-arm .back {
    background-position: -102px -40px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(-2px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(180deg) translateX(-2px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x.slim .player > .left-arm .right {
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(2px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(2px)
}

.mc-skin-viewer-2x.slim .player > .left-arm .left {
    background-position: -94px -40px
}

.mc-skin-viewer-2x.slim .player > .left-arm .top {
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(2px) translateY(0px) translateZ(4px);
    transform: rotateX(90deg) rotateY(0deg) translateX(2px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-2x.slim .player > .left-arm .bottom {
    background-position: -94px -32px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(2px) translateY(0px) translateZ(20px);
    transform: rotateX(270deg) rotateY(0deg) translateX(2px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-2x.slim .player > .left-arm > .accessory .back {
    background-position: -102px -72px
}

.mc-skin-viewer-2x.slim .player > .left-arm > .accessory .left {
    background-position: -94px -72px
}

.mc-skin-viewer-2x.slim .player > .left-arm > .accessory .bottom {
    background-position: -94px -64px
}

.mc-skin-viewer-2x.slim .player > .right-arm .back {
    background-position: -86px -104px
}

.mc-skin-viewer-2x.slim .player > .right-arm .left {
    background-position: -78px -104px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(2px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(2px)
}

.mc-skin-viewer-2x.slim .player > .right-arm .bottom {
    background-position: -78px -96px
}

.mc-skin-viewer-2x.slim .player > .right-arm > .accessory .back {
    background-position: -118px -104px
}

.mc-skin-viewer-2x.slim .player > .right-arm > .accessory .left {
    background-position: -110px -104px
}

.mc-skin-viewer-2x.slim .player > .right-arm > .accessory .bottom {
    background-position: -110px -96px
}

.mc-skin-viewer-2x.slim.legacy .player > .right-arm {
    -webkit-transform: translateY(16px) translateX(14px) scaleX(-1);
    -ms-transform: translateY(16px) translateX(14px) scaleX(-1);
    transform: translateY(16px) translateX(14px) scaleX(-1)
}

.mc-skin-viewer-2x.slim.legacy .player > .right-arm .back {
    background-position: -102px -40px
}

.mc-skin-viewer-2x.slim.legacy .player > .right-arm > .accessory .back {
    background-position: -102px -72px
}

.mc-skin-viewer-2x .player .cape {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(180deg) translateX(-2px) translateZ(5px) translateY(15px) rotateX(5deg);
    transform: rotateY(180deg) translateX(-2px) translateZ(5px) translateY(15px) rotateX(5deg)
}

.mc-skin-viewer-2x .player .cape > .front, .mc-skin-viewer-2x .player .cape > .back, .mc-skin-viewer-2x .player .cape > .left, .mc-skin-viewer-2x .player .cape > .right, .mc-skin-viewer-2x .player .cape > .top, .mc-skin-viewer-2x .player .cape > .bottom {
    position: absolute;
    background-image: inherit !important;
    background-size: 128px 64px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-2x .player .cape > .front, .mc-skin-viewer-2x .player .cape > .back {
    width: 20px;
    height: 30px
}

.mc-skin-viewer-2x .player .cape > .left, .mc-skin-viewer-2x .player .cape > .right {
    width: 2px;
    height: 30px
}

.mc-skin-viewer-2x .player .cape > .top, .mc-skin-viewer-2x .player .cape > .bottom {
    width: 20px;
    height: 2px
}

.mc-skin-viewer-2x .player .cape > .front {
    background-position: -2px -2px;
    -webkit-transform: translateZ(1px);
    transform: translateZ(1px)
}

.mc-skin-viewer-2x .player .cape > .back {
    background-position: -24px -2px;
    -webkit-transform: translateZ(-1px) rotateY(180deg);
    transform: translateZ(-1px) rotateY(180deg)
}

.mc-skin-viewer-2x .player .cape > .left {
    background-position: 0 -2px;
    -webkit-transform: rotateY(270deg) translateZ(1px);
    transform: rotateY(270deg) translateZ(1px)
}

.mc-skin-viewer-2x .player .cape > .right {
    background-position: -22px -2px;
    -webkit-transform: rotateY(-270deg) translateZ(19px);
    transform: rotateY(-270deg) translateZ(19px)
}

.mc-skin-viewer-2x .player .cape > .top {
    background-position: -2px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(1px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(1px)
}

.mc-skin-viewer-2x .player .cape > .bottom {
    background-position: -22px 0;
    -webkit-transform: rotateX(-90deg) translateZ(29px);
    transform: rotateX(-90deg) translateZ(29px)
}

.mc-skin-viewer-2x.legacy-cape > .player .cape > .front, .mc-skin-viewer-2x.legacy-cape > .player .cape > .back, .mc-skin-viewer-2x.legacy-cape > .player .cape > .left, .mc-skin-viewer-2x.legacy-cape > .player .cape > .right, .mc-skin-viewer-2x.legacy-cape > .player .cape > .top, .mc-skin-viewer-2x.legacy-cape > .player .cape > .bottom {
    background-size: 44px 34px
}

.mc-skin-viewer-4x {
    width: 32px;
    height: 128px;
    margin: 15px auto 40px;
    -webkit-perspective: 800px;
    perspective: 800px;
    -webkit-perspective-origin: 50% 100px;
    perspective-origin: 50% 100px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-image: none !important;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor
}

.mc-skin-viewer-4x.hide-accessories .accessory {
    display: none
}

.mc-skin-viewer-4x.spin > .player {
    -webkit-animation: spin 12s infinite linear;
    animation: spin 12s infinite linear
}

.mc-skin-viewer-4x .player {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.mc-skin-viewer-4x .player .front, .mc-skin-viewer-4x .player .back, .mc-skin-viewer-4x .player .left, .mc-skin-viewer-4x .player .right, .mc-skin-viewer-4x .player .top, .mc-skin-viewer-4x .player .bottom {
    position: absolute;
    background-size: 256px;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-4x .player .accessory {
    background-image: none
}

.mc-skin-viewer-4x .player .accessory .front, .mc-skin-viewer-4x .player .accessory .back, .mc-skin-viewer-4x .player .accessory .left, .mc-skin-viewer-4x .player .accessory .right, .mc-skin-viewer-4x .player .accessory .top, .mc-skin-viewer-4x .player .accessory .bottom {
    -webkit-backface-visibility: visible;
    backface-visibility: visible
}

.mc-skin-viewer-4x .player > .head {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background-image: none !important
}

.mc-skin-viewer-4x .player > .head .front, .mc-skin-viewer-4x .player > .head .back, .mc-skin-viewer-4x .player > .head .left, .mc-skin-viewer-4x .player > .head .right, .mc-skin-viewer-4x .player > .head .top, .mc-skin-viewer-4x .player > .head .bottom {
    width: 32px;
    height: 32px
}

.mc-skin-viewer-4x .player > .head .front {
    background-position: -32px -32px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-4x .player > .head .back {
    background-position: -96px -32px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-4x .player > .head .right {
    background-position: 0 -32px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-4x .player > .head .left {
    background-position: -64px -32px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-4x .player > .head .top {
    background-position: -32px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-4x .player > .head .bottom {
    background-position: -64px 0;
    -webkit-transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-4x .player > .head > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.125, 1.125, 1.125) translateY(-1px);
    transform: scale3d(1.125, 1.125, 1.125) translateY(-1px)
}

.mc-skin-viewer-4x .player > .head > .accessory > .front {
    background-position: -160px -32px
}

.mc-skin-viewer-4x .player > .head > .accessory > .back {
    background-position: -224px -32px
}

.mc-skin-viewer-4x .player > .head > .accessory > .right {
    background-position: -128px -32px
}

.mc-skin-viewer-4x .player > .head > .accessory > .left {
    background-position: -192px -32px
}

.mc-skin-viewer-4x .player > .head > .accessory > .top {
    background-position: -160px 0
}

.mc-skin-viewer-4x .player > .head > .accessory > .bottom {
    background-position: -192px 0
}

.mc-skin-viewer-4x .player > .body {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    -webkit-transform: translateY(32px);
    -ms-transform: translateY(32px);
    transform: translateY(32px);
    background-image: none !important
}

.mc-skin-viewer-4x .player > .body .front, .mc-skin-viewer-4x .player > .body .back, .mc-skin-viewer-4x .player > .body .left, .mc-skin-viewer-4x .player > .body .right, .mc-skin-viewer-4x .player > .body .top, .mc-skin-viewer-4x .player > .body .bottom {
    width: 32px;
    height: 48px
}

.mc-skin-viewer-4x .player > .body .left, .mc-skin-viewer-4x .player > .body .right {
    width: 16px
}

.mc-skin-viewer-4x .player > .body .top, .mc-skin-viewer-4x .player > .body .bottom {
    height: 16px
}

.mc-skin-viewer-4x .player > .body .front {
    background-position: -80px -80px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .body .back {
    background-position: -128px -80px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .body .right {
    background-position: -64px -80px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .body .left {
    background-position: -112px -80px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(24px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(24px)
}

.mc-skin-viewer-4x .player > .body .top {
    background-position: -80px -64px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .body .bottom {
    background-position: -112px -64px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(40px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(40px)
}

.mc-skin-viewer-4x .player > .body > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-1.5px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-1.5px)
}

.mc-skin-viewer-4x .player > .body > .accessory > .front {
    background-position: -80px -144px
}

.mc-skin-viewer-4x .player > .body > .accessory > .back {
    background-position: -128px -144px
}

.mc-skin-viewer-4x .player > .body > .accessory > .right {
    background-position: -64px -144px
}

.mc-skin-viewer-4x .player > .body > .accessory > .left {
    background-position: -112px -144px
}

.mc-skin-viewer-4x .player > .body > .accessory > .top {
    background-position: -80px -128px
}

.mc-skin-viewer-4x .player > .body > .accessory > .bottom {
    background-position: -112px -128px
}

.mc-skin-viewer-4x .player > .left-arm, .mc-skin-viewer-4x .player > .right-arm {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 16px;
    height: 48px;
    margin: 0 auto;
    -webkit-transform: translateY(32px) translateX(-16px);
    -ms-transform: translateY(32px) translateX(-16px);
    transform: translateY(32px) translateX(-16px);
    background-image: none !important
}

.mc-skin-viewer-4x .player > .left-arm .front, .mc-skin-viewer-4x .player > .right-arm .front, .mc-skin-viewer-4x .player > .left-arm .back, .mc-skin-viewer-4x .player > .right-arm .back, .mc-skin-viewer-4x .player > .left-arm .left, .mc-skin-viewer-4x .player > .right-arm .left, .mc-skin-viewer-4x .player > .left-arm .right, .mc-skin-viewer-4x .player > .right-arm .right, .mc-skin-viewer-4x .player > .left-arm .top, .mc-skin-viewer-4x .player > .right-arm .top, .mc-skin-viewer-4x .player > .left-arm .bottom, .mc-skin-viewer-4x .player > .right-arm .bottom {
    width: 16px;
    height: 48px
}

.mc-skin-viewer-4x .player > .left-arm .top, .mc-skin-viewer-4x .player > .right-arm .top, .mc-skin-viewer-4x .player > .left-arm .bottom, .mc-skin-viewer-4x .player > .right-arm .bottom {
    height: 16px
}

.mc-skin-viewer-4x .player > .left-arm .front, .mc-skin-viewer-4x .player > .right-arm .front {
    background-position: -176px -80px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-arm .back, .mc-skin-viewer-4x .player > .right-arm .back {
    background-position: -208px -80px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-arm .right, .mc-skin-viewer-4x .player > .right-arm .right {
    background-position: -160px -80px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-arm .left, .mc-skin-viewer-4x .player > .right-arm .left {
    background-position: -192px -80px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-arm .top, .mc-skin-viewer-4x .player > .right-arm .top {
    background-position: -176px -64px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-arm .bottom, .mc-skin-viewer-4x .player > .right-arm .bottom {
    background-position: -192px -64px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(40px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(40px)
}

.mc-skin-viewer-4x .player > .left-arm > .accessory, .mc-skin-viewer-4x .player > .right-arm > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-1.5px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-1.5px)
}

.mc-skin-viewer-4x .player > .left-arm > .accessory .front, .mc-skin-viewer-4x .player > .right-arm > .accessory .front {
    background-position: -176px -144px
}

.mc-skin-viewer-4x .player > .left-arm > .accessory .back, .mc-skin-viewer-4x .player > .right-arm > .accessory .back {
    background-position: -208px -144px
}

.mc-skin-viewer-4x .player > .left-arm > .accessory .right, .mc-skin-viewer-4x .player > .right-arm > .accessory .right {
    background-position: -160px -144px
}

.mc-skin-viewer-4x .player > .left-arm > .accessory .left, .mc-skin-viewer-4x .player > .right-arm > .accessory .left {
    background-position: -192px -144px
}

.mc-skin-viewer-4x .player > .left-arm > .accessory .top, .mc-skin-viewer-4x .player > .right-arm > .accessory .top {
    background-position: -176px -128px
}

.mc-skin-viewer-4x .player > .left-arm > .accessory .bottom, .mc-skin-viewer-4x .player > .right-arm > .accessory .bottom {
    background-position: -192px -128px
}

.mc-skin-viewer-4x .player > .right-arm {
    -webkit-transform: translateY(32px) translateX(32px) scaleX(-1);
    -ms-transform: translateY(32px) translateX(32px) scaleX(-1);
    transform: translateY(32px) translateX(32px) scaleX(-1)
}

.mc-skin-viewer-4x .player > .left-leg, .mc-skin-viewer-4x .player > .right-leg {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 16px;
    height: 48px;
    margin: 0 auto;
    -webkit-transform: translateY(80px);
    -ms-transform: translateY(80px);
    transform: translateY(80px);
    background-image: none !important
}

.mc-skin-viewer-4x .player > .left-leg .front, .mc-skin-viewer-4x .player > .right-leg .front, .mc-skin-viewer-4x .player > .left-leg .back, .mc-skin-viewer-4x .player > .right-leg .back, .mc-skin-viewer-4x .player > .left-leg .left, .mc-skin-viewer-4x .player > .right-leg .left, .mc-skin-viewer-4x .player > .left-leg .right, .mc-skin-viewer-4x .player > .right-leg .right, .mc-skin-viewer-4x .player > .left-leg .top, .mc-skin-viewer-4x .player > .right-leg .top, .mc-skin-viewer-4x .player > .left-leg .bottom, .mc-skin-viewer-4x .player > .right-leg .bottom {
    width: 16px;
    height: 48px
}

.mc-skin-viewer-4x .player > .left-leg .top, .mc-skin-viewer-4x .player > .right-leg .top, .mc-skin-viewer-4x .player > .left-leg .bottom, .mc-skin-viewer-4x .player > .right-leg .bottom {
    height: 16px
}

.mc-skin-viewer-4x .player > .left-leg .front, .mc-skin-viewer-4x .player > .right-leg .front {
    background-position: -16px -80px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-leg .back, .mc-skin-viewer-4x .player > .right-leg .back {
    background-position: -48px -80px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-leg .right, .mc-skin-viewer-4x .player > .right-leg .right {
    background-position: 0 -80px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-leg .left, .mc-skin-viewer-4x .player > .right-leg .left {
    background-position: -32px -80px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-leg .top, .mc-skin-viewer-4x .player > .right-leg .top {
    background-position: -16px -64px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x .player > .left-leg .bottom, .mc-skin-viewer-4x .player > .right-leg .bottom {
    background-position: -32px -64px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(40px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(40px)
}

.mc-skin-viewer-4x .player > .left-leg > .accessory, .mc-skin-viewer-4x .player > .right-leg > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-0.75px);
    transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-0.75px)
}

.mc-skin-viewer-4x .player > .left-leg > .accessory .front, .mc-skin-viewer-4x .player > .right-leg > .accessory .front {
    background-position: -16px -144px
}

.mc-skin-viewer-4x .player > .left-leg > .accessory .back, .mc-skin-viewer-4x .player > .right-leg > .accessory .back {
    background-position: -48px -144px
}

.mc-skin-viewer-4x .player > .left-leg > .accessory .right, .mc-skin-viewer-4x .player > .right-leg > .accessory .right {
    background-position: 0 -144px
}

.mc-skin-viewer-4x .player > .left-leg > .accessory .left, .mc-skin-viewer-4x .player > .right-leg > .accessory .left {
    background-position: -32px -144px
}

.mc-skin-viewer-4x .player > .left-leg > .accessory .top, .mc-skin-viewer-4x .player > .right-leg > .accessory .top {
    background-position: -16px -128px
}

.mc-skin-viewer-4x .player > .left-leg > .accessory .bottom, .mc-skin-viewer-4x .player > .right-leg > .accessory .bottom {
    background-position: -32px -128px
}

.mc-skin-viewer-4x .player > .right-leg {
    -webkit-transform: translateY(80px) translateX(16px) scaleX(-1);
    -ms-transform: translateY(80px) translateX(16px) scaleX(-1);
    transform: translateY(80px) translateX(16px) scaleX(-1)
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm {
    -webkit-transform: translateY(32px) translateX(32px);
    -ms-transform: translateY(32px) translateX(32px);
    transform: translateY(32px) translateX(32px)
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm .front {
    background-position: -144px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm .back {
    background-position: -176px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm .right {
    background-position: -128px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm .left {
    background-position: -160px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm .top {
    background-position: -144px -192px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm .bottom {
    background-position: -160px -192px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm > .accessory .front {
    background-position: -208px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm > .accessory .back {
    background-position: -240px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm > .accessory .right {
    background-position: -192px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm > .accessory .left {
    background-position: -224px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm > .accessory .top {
    background-position: -208px -192px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-arm > .accessory .bottom {
    background-position: -224px -192px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg {
    -webkit-transform: translateY(80px) translateX(16px);
    -ms-transform: translateY(80px) translateX(16px);
    transform: translateY(80px) translateX(16px)
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg .front {
    background-position: -80px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg .back {
    background-position: -112px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg .right {
    background-position: -64px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg .left {
    background-position: -96px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg .top {
    background-position: -80px -192px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg .bottom {
    background-position: -96px -192px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg > .accessory .front {
    background-position: -16px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg > .accessory .back {
    background-position: -48px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg > .accessory .right {
    background-position: 0 -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg > .accessory .left {
    background-position: -32px -208px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg > .accessory .top {
    background-position: -16px -192px
}

.mc-skin-viewer-4x:not(.legacy) .player > .right-leg > .accessory .bottom {
    background-position: -32px -192px
}

.mc-skin-viewer-4x.slim .player > .left-arm .front, .mc-skin-viewer-4x.slim .player > .right-arm .front, .mc-skin-viewer-4x.slim .player > .left-arm .back, .mc-skin-viewer-4x.slim .player > .right-arm .back, .mc-skin-viewer-4x.slim .player > .left-arm .top, .mc-skin-viewer-4x.slim .player > .right-arm .top, .mc-skin-viewer-4x.slim .player > .left-arm .bottom, .mc-skin-viewer-4x.slim .player > .right-arm .bottom {
    width: 12px
}

.mc-skin-viewer-4x.slim .player > .left-arm .front {
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(4px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(0deg) translateX(4px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x.slim .player > .left-arm .back {
    background-position: -204px -80px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(-4px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(180deg) translateX(-4px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x.slim .player > .left-arm .right {
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-4x.slim .player > .left-arm .left {
    background-position: -188px -80px
}

.mc-skin-viewer-4x.slim .player > .left-arm .top {
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(4px) translateY(0px) translateZ(8px);
    transform: rotateX(90deg) rotateY(0deg) translateX(4px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-4x.slim .player > .left-arm .bottom {
    background-position: -188px -64px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(4px) translateY(0px) translateZ(40px);
    transform: rotateX(270deg) rotateY(0deg) translateX(4px) translateY(0px) translateZ(40px)
}

.mc-skin-viewer-4x.slim .player > .left-arm > .accessory .back {
    background-position: -204px -144px
}

.mc-skin-viewer-4x.slim .player > .left-arm > .accessory .left {
    background-position: -188px -144px
}

.mc-skin-viewer-4x.slim .player > .left-arm > .accessory .bottom {
    background-position: -188px -128px
}

.mc-skin-viewer-4x.slim .player > .right-arm .back {
    background-position: -172px -208px
}

.mc-skin-viewer-4x.slim .player > .right-arm .left {
    background-position: -156px -208px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-4x.slim .player > .right-arm .bottom {
    background-position: -156px -192px
}

.mc-skin-viewer-4x.slim .player > .right-arm > .accessory .back {
    background-position: -236px -208px
}

.mc-skin-viewer-4x.slim .player > .right-arm > .accessory .left {
    background-position: -220px -208px
}

.mc-skin-viewer-4x.slim .player > .right-arm > .accessory .bottom {
    background-position: -220px -192px
}

.mc-skin-viewer-4x.slim.legacy .player > .right-arm {
    -webkit-transform: translateY(32px) translateX(28px) scaleX(-1);
    -ms-transform: translateY(32px) translateX(28px) scaleX(-1);
    transform: translateY(32px) translateX(28px) scaleX(-1)
}

.mc-skin-viewer-4x.slim.legacy .player > .right-arm .back {
    background-position: -204px -80px
}

.mc-skin-viewer-4x.slim.legacy .player > .right-arm > .accessory .back {
    background-position: -204px -144px
}

.mc-skin-viewer-4x .player .cape {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(180deg) translateX(-4px) translateZ(10px) translateY(30px) rotateX(5deg);
    transform: rotateY(180deg) translateX(-4px) translateZ(10px) translateY(30px) rotateX(5deg)
}

.mc-skin-viewer-4x .player .cape > .front, .mc-skin-viewer-4x .player .cape > .back, .mc-skin-viewer-4x .player .cape > .left, .mc-skin-viewer-4x .player .cape > .right, .mc-skin-viewer-4x .player .cape > .top, .mc-skin-viewer-4x .player .cape > .bottom {
    position: absolute;
    background-image: inherit !important;
    background-size: 256px 128px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-4x .player .cape > .front, .mc-skin-viewer-4x .player .cape > .back {
    width: 40px;
    height: 60px
}

.mc-skin-viewer-4x .player .cape > .left, .mc-skin-viewer-4x .player .cape > .right {
    width: 4px;
    height: 60px
}

.mc-skin-viewer-4x .player .cape > .top, .mc-skin-viewer-4x .player .cape > .bottom {
    width: 40px;
    height: 4px
}

.mc-skin-viewer-4x .player .cape > .front {
    background-position: -4px -4px;
    -webkit-transform: translateZ(2px);
    transform: translateZ(2px)
}

.mc-skin-viewer-4x .player .cape > .back {
    background-position: -48px -4px;
    -webkit-transform: translateZ(-2px) rotateY(180deg);
    transform: translateZ(-2px) rotateY(180deg)
}

.mc-skin-viewer-4x .player .cape > .left {
    background-position: 0 -4px;
    -webkit-transform: rotateY(270deg) translateZ(2px);
    transform: rotateY(270deg) translateZ(2px)
}

.mc-skin-viewer-4x .player .cape > .right {
    background-position: -44px -4px;
    -webkit-transform: rotateY(-270deg) translateZ(38px);
    transform: rotateY(-270deg) translateZ(38px)
}

.mc-skin-viewer-4x .player .cape > .top {
    background-position: -4px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(2px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(2px)
}

.mc-skin-viewer-4x .player .cape > .bottom {
    background-position: -44px 0;
    -webkit-transform: rotateX(-90deg) translateZ(58px);
    transform: rotateX(-90deg) translateZ(58px)
}

.mc-skin-viewer-4x.legacy-cape > .player .cape > .front, .mc-skin-viewer-4x.legacy-cape > .player .cape > .back, .mc-skin-viewer-4x.legacy-cape > .player .cape > .left, .mc-skin-viewer-4x.legacy-cape > .player .cape > .right, .mc-skin-viewer-4x.legacy-cape > .player .cape > .top, .mc-skin-viewer-4x.legacy-cape > .player .cape > .bottom {
    background-size: 88px 68px
}

.mc-skin-viewer-5x {
    width: 40px;
    height: 160px;
    margin: 15px auto 40px;
    -webkit-perspective: 800px;
    perspective: 800px;
    -webkit-perspective-origin: 50% 100px;
    perspective-origin: 50% 100px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-image: none !important;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor
}

.mc-skin-viewer-5x.hide-accessories .accessory {
    display: none
}

.mc-skin-viewer-5x.spin > .player {
    -webkit-animation: spin 12s infinite linear;
    animation: spin 12s infinite linear
}

.mc-skin-viewer-5x .player {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.mc-skin-viewer-5x .player .front, .mc-skin-viewer-5x .player .back, .mc-skin-viewer-5x .player .left, .mc-skin-viewer-5x .player .right, .mc-skin-viewer-5x .player .top, .mc-skin-viewer-5x .player .bottom {
    position: absolute;
    background-size: 320px;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-5x .player .accessory {
    background-image: none
}

.mc-skin-viewer-5x .player .accessory .front, .mc-skin-viewer-5x .player .accessory .back, .mc-skin-viewer-5x .player .accessory .left, .mc-skin-viewer-5x .player .accessory .right, .mc-skin-viewer-5x .player .accessory .top, .mc-skin-viewer-5x .player .accessory .bottom {
    -webkit-backface-visibility: visible;
    backface-visibility: visible
}

.mc-skin-viewer-5x .player > .head {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    background-image: none !important
}

.mc-skin-viewer-5x .player > .head .front, .mc-skin-viewer-5x .player > .head .back, .mc-skin-viewer-5x .player > .head .left, .mc-skin-viewer-5x .player > .head .right, .mc-skin-viewer-5x .player > .head .top, .mc-skin-viewer-5x .player > .head .bottom {
    width: 40px;
    height: 40px
}

.mc-skin-viewer-5x .player > .head .front {
    background-position: -40px -40px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-5x .player > .head .back {
    background-position: -120px -40px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-5x .player > .head .right {
    background-position: 0 -40px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-5x .player > .head .left {
    background-position: -80px -40px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-5x .player > .head .top {
    background-position: -40px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-5x .player > .head .bottom {
    background-position: -80px 0;
    -webkit-transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(20px);
    transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(20px)
}

.mc-skin-viewer-5x .player > .head > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.125, 1.125, 1.125) translateY(-1.5px);
    transform: scale3d(1.125, 1.125, 1.125) translateY(-1.5px)
}

.mc-skin-viewer-5x .player > .head > .accessory > .front {
    background-position: -200px -40px
}

.mc-skin-viewer-5x .player > .head > .accessory > .back {
    background-position: -280px -40px
}

.mc-skin-viewer-5x .player > .head > .accessory > .right {
    background-position: -160px -40px
}

.mc-skin-viewer-5x .player > .head > .accessory > .left {
    background-position: -240px -40px
}

.mc-skin-viewer-5x .player > .head > .accessory > .top {
    background-position: -200px 0
}

.mc-skin-viewer-5x .player > .head > .accessory > .bottom {
    background-position: -240px 0
}

.mc-skin-viewer-5x .player > .body {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    -webkit-transform: translateY(40px);
    -ms-transform: translateY(40px);
    transform: translateY(40px);
    background-image: none !important
}

.mc-skin-viewer-5x .player > .body .front, .mc-skin-viewer-5x .player > .body .back, .mc-skin-viewer-5x .player > .body .left, .mc-skin-viewer-5x .player > .body .right, .mc-skin-viewer-5x .player > .body .top, .mc-skin-viewer-5x .player > .body .bottom {
    width: 40px;
    height: 60px
}

.mc-skin-viewer-5x .player > .body .left, .mc-skin-viewer-5x .player > .body .right {
    width: 20px
}

.mc-skin-viewer-5x .player > .body .top, .mc-skin-viewer-5x .player > .body .bottom {
    height: 20px
}

.mc-skin-viewer-5x .player > .body .front {
    background-position: -100px -100px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .body .back {
    background-position: -160px -100px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .body .right {
    background-position: -80px -100px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .body .left {
    background-position: -140px -100px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(30px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(30px)
}

.mc-skin-viewer-5x .player > .body .top {
    background-position: -100px -80px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .body .bottom {
    background-position: -140px -80px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(50px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(50px)
}

.mc-skin-viewer-5x .player > .body > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-1.875px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-1.875px)
}

.mc-skin-viewer-5x .player > .body > .accessory > .front {
    background-position: -100px -180px
}

.mc-skin-viewer-5x .player > .body > .accessory > .back {
    background-position: -160px -180px
}

.mc-skin-viewer-5x .player > .body > .accessory > .right {
    background-position: -80px -180px
}

.mc-skin-viewer-5x .player > .body > .accessory > .left {
    background-position: -140px -180px
}

.mc-skin-viewer-5x .player > .body > .accessory > .top {
    background-position: -100px -160px
}

.mc-skin-viewer-5x .player > .body > .accessory > .bottom {
    background-position: -140px -160px
}

.mc-skin-viewer-5x .player > .left-arm, .mc-skin-viewer-5x .player > .right-arm {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 20px;
    height: 60px;
    margin: 0 auto;
    -webkit-transform: translateY(40px) translateX(-20px);
    -ms-transform: translateY(40px) translateX(-20px);
    transform: translateY(40px) translateX(-20px);
    background-image: none !important
}

.mc-skin-viewer-5x .player > .left-arm .front, .mc-skin-viewer-5x .player > .right-arm .front, .mc-skin-viewer-5x .player > .left-arm .back, .mc-skin-viewer-5x .player > .right-arm .back, .mc-skin-viewer-5x .player > .left-arm .left, .mc-skin-viewer-5x .player > .right-arm .left, .mc-skin-viewer-5x .player > .left-arm .right, .mc-skin-viewer-5x .player > .right-arm .right, .mc-skin-viewer-5x .player > .left-arm .top, .mc-skin-viewer-5x .player > .right-arm .top, .mc-skin-viewer-5x .player > .left-arm .bottom, .mc-skin-viewer-5x .player > .right-arm .bottom {
    width: 20px;
    height: 60px
}

.mc-skin-viewer-5x .player > .left-arm .top, .mc-skin-viewer-5x .player > .right-arm .top, .mc-skin-viewer-5x .player > .left-arm .bottom, .mc-skin-viewer-5x .player > .right-arm .bottom {
    height: 20px
}

.mc-skin-viewer-5x .player > .left-arm .front, .mc-skin-viewer-5x .player > .right-arm .front {
    background-position: -220px -100px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-arm .back, .mc-skin-viewer-5x .player > .right-arm .back {
    background-position: -260px -100px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-arm .right, .mc-skin-viewer-5x .player > .right-arm .right {
    background-position: -200px -100px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-arm .left, .mc-skin-viewer-5x .player > .right-arm .left {
    background-position: -240px -100px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-arm .top, .mc-skin-viewer-5x .player > .right-arm .top {
    background-position: -220px -80px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-arm .bottom, .mc-skin-viewer-5x .player > .right-arm .bottom {
    background-position: -240px -80px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(50px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(50px)
}

.mc-skin-viewer-5x .player > .left-arm > .accessory, .mc-skin-viewer-5x .player > .right-arm > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-1.875px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-1.875px)
}

.mc-skin-viewer-5x .player > .left-arm > .accessory .front, .mc-skin-viewer-5x .player > .right-arm > .accessory .front {
    background-position: -220px -180px
}

.mc-skin-viewer-5x .player > .left-arm > .accessory .back, .mc-skin-viewer-5x .player > .right-arm > .accessory .back {
    background-position: -260px -180px
}

.mc-skin-viewer-5x .player > .left-arm > .accessory .right, .mc-skin-viewer-5x .player > .right-arm > .accessory .right {
    background-position: -200px -180px
}

.mc-skin-viewer-5x .player > .left-arm > .accessory .left, .mc-skin-viewer-5x .player > .right-arm > .accessory .left {
    background-position: -240px -180px
}

.mc-skin-viewer-5x .player > .left-arm > .accessory .top, .mc-skin-viewer-5x .player > .right-arm > .accessory .top {
    background-position: -220px -160px
}

.mc-skin-viewer-5x .player > .left-arm > .accessory .bottom, .mc-skin-viewer-5x .player > .right-arm > .accessory .bottom {
    background-position: -240px -160px
}

.mc-skin-viewer-5x .player > .right-arm {
    -webkit-transform: translateY(40px) translateX(40px) scaleX(-1);
    -ms-transform: translateY(40px) translateX(40px) scaleX(-1);
    transform: translateY(40px) translateX(40px) scaleX(-1)
}

.mc-skin-viewer-5x .player > .left-leg, .mc-skin-viewer-5x .player > .right-leg {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 20px;
    height: 60px;
    margin: 0 auto;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    background-image: none !important
}

.mc-skin-viewer-5x .player > .left-leg .front, .mc-skin-viewer-5x .player > .right-leg .front, .mc-skin-viewer-5x .player > .left-leg .back, .mc-skin-viewer-5x .player > .right-leg .back, .mc-skin-viewer-5x .player > .left-leg .left, .mc-skin-viewer-5x .player > .right-leg .left, .mc-skin-viewer-5x .player > .left-leg .right, .mc-skin-viewer-5x .player > .right-leg .right, .mc-skin-viewer-5x .player > .left-leg .top, .mc-skin-viewer-5x .player > .right-leg .top, .mc-skin-viewer-5x .player > .left-leg .bottom, .mc-skin-viewer-5x .player > .right-leg .bottom {
    width: 20px;
    height: 60px
}

.mc-skin-viewer-5x .player > .left-leg .top, .mc-skin-viewer-5x .player > .right-leg .top, .mc-skin-viewer-5x .player > .left-leg .bottom, .mc-skin-viewer-5x .player > .right-leg .bottom {
    height: 20px
}

.mc-skin-viewer-5x .player > .left-leg .front, .mc-skin-viewer-5x .player > .right-leg .front {
    background-position: -20px -100px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-leg .back, .mc-skin-viewer-5x .player > .right-leg .back {
    background-position: -60px -100px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-leg .right, .mc-skin-viewer-5x .player > .right-leg .right {
    background-position: 0 -100px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-leg .left, .mc-skin-viewer-5x .player > .right-leg .left {
    background-position: -40px -100px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-leg .top, .mc-skin-viewer-5x .player > .right-leg .top {
    background-position: -20px -80px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x .player > .left-leg .bottom, .mc-skin-viewer-5x .player > .right-leg .bottom {
    background-position: -40px -80px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(50px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(50px)
}

.mc-skin-viewer-5x .player > .left-leg > .accessory, .mc-skin-viewer-5x .player > .right-leg > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-0.9375px);
    transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-0.9375px)
}

.mc-skin-viewer-5x .player > .left-leg > .accessory .front, .mc-skin-viewer-5x .player > .right-leg > .accessory .front {
    background-position: -20px -180px
}

.mc-skin-viewer-5x .player > .left-leg > .accessory .back, .mc-skin-viewer-5x .player > .right-leg > .accessory .back {
    background-position: -60px -180px
}

.mc-skin-viewer-5x .player > .left-leg > .accessory .right, .mc-skin-viewer-5x .player > .right-leg > .accessory .right {
    background-position: 0 -180px
}

.mc-skin-viewer-5x .player > .left-leg > .accessory .left, .mc-skin-viewer-5x .player > .right-leg > .accessory .left {
    background-position: -40px -180px
}

.mc-skin-viewer-5x .player > .left-leg > .accessory .top, .mc-skin-viewer-5x .player > .right-leg > .accessory .top {
    background-position: -20px -160px
}

.mc-skin-viewer-5x .player > .left-leg > .accessory .bottom, .mc-skin-viewer-5x .player > .right-leg > .accessory .bottom {
    background-position: -40px -160px
}

.mc-skin-viewer-5x .player > .right-leg {
    -webkit-transform: translateY(100px) translateX(20px) scaleX(-1);
    -ms-transform: translateY(100px) translateX(20px) scaleX(-1);
    transform: translateY(100px) translateX(20px) scaleX(-1)
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm {
    -webkit-transform: translateY(40px) translateX(40px);
    -ms-transform: translateY(40px) translateX(40px);
    transform: translateY(40px) translateX(40px)
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm .front {
    background-position: -180px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm .back {
    background-position: -220px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm .right {
    background-position: -160px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm .left {
    background-position: -200px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm .top {
    background-position: -180px -240px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm .bottom {
    background-position: -200px -240px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm > .accessory .front {
    background-position: -260px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm > .accessory .back {
    background-position: -300px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm > .accessory .right {
    background-position: -240px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm > .accessory .left {
    background-position: -280px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm > .accessory .top {
    background-position: -260px -240px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-arm > .accessory .bottom {
    background-position: -280px -240px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg {
    -webkit-transform: translateY(100px) translateX(20px);
    -ms-transform: translateY(100px) translateX(20px);
    transform: translateY(100px) translateX(20px)
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg .front {
    background-position: -100px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg .back {
    background-position: -140px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg .right {
    background-position: -80px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg .left {
    background-position: -120px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg .top {
    background-position: -100px -240px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg .bottom {
    background-position: -120px -240px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg > .accessory .front {
    background-position: -20px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg > .accessory .back {
    background-position: -60px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg > .accessory .right {
    background-position: 0 -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg > .accessory .left {
    background-position: -40px -260px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg > .accessory .top {
    background-position: -20px -240px
}

.mc-skin-viewer-5x:not(.legacy) .player > .right-leg > .accessory .bottom {
    background-position: -40px -240px
}

.mc-skin-viewer-5x.slim .player > .left-arm .front, .mc-skin-viewer-5x.slim .player > .right-arm .front, .mc-skin-viewer-5x.slim .player > .left-arm .back, .mc-skin-viewer-5x.slim .player > .right-arm .back, .mc-skin-viewer-5x.slim .player > .left-arm .top, .mc-skin-viewer-5x.slim .player > .right-arm .top, .mc-skin-viewer-5x.slim .player > .left-arm .bottom, .mc-skin-viewer-5x.slim .player > .right-arm .bottom {
    width: 15px
}

.mc-skin-viewer-5x.slim .player > .left-arm .front {
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(5px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(0deg) translateX(5px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x.slim .player > .left-arm .back {
    background-position: -255px -100px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(-5px) translateY(0px) translateZ(10px);
    transform: rotateX(0deg) rotateY(180deg) translateX(-5px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x.slim .player > .left-arm .right {
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(5px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(5px)
}

.mc-skin-viewer-5x.slim .player > .left-arm .left {
    background-position: -235px -100px
}

.mc-skin-viewer-5x.slim .player > .left-arm .top {
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(5px) translateY(0px) translateZ(10px);
    transform: rotateX(90deg) rotateY(0deg) translateX(5px) translateY(0px) translateZ(10px)
}

.mc-skin-viewer-5x.slim .player > .left-arm .bottom {
    background-position: -235px -80px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(5px) translateY(0px) translateZ(50px);
    transform: rotateX(270deg) rotateY(0deg) translateX(5px) translateY(0px) translateZ(50px)
}

.mc-skin-viewer-5x.slim .player > .left-arm > .accessory .back {
    background-position: -255px -180px
}

.mc-skin-viewer-5x.slim .player > .left-arm > .accessory .left {
    background-position: -235px -180px
}

.mc-skin-viewer-5x.slim .player > .left-arm > .accessory .bottom {
    background-position: -235px -160px
}

.mc-skin-viewer-5x.slim .player > .right-arm .back {
    background-position: -215px -260px
}

.mc-skin-viewer-5x.slim .player > .right-arm .left {
    background-position: -195px -260px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(5px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(5px)
}

.mc-skin-viewer-5x.slim .player > .right-arm .bottom {
    background-position: -195px -240px
}

.mc-skin-viewer-5x.slim .player > .right-arm > .accessory .back {
    background-position: -295px -260px
}

.mc-skin-viewer-5x.slim .player > .right-arm > .accessory .left {
    background-position: -275px -260px
}

.mc-skin-viewer-5x.slim .player > .right-arm > .accessory .bottom {
    background-position: -275px -240px
}

.mc-skin-viewer-5x.slim.legacy .player > .right-arm {
    -webkit-transform: translateY(40px) translateX(35px) scaleX(-1);
    -ms-transform: translateY(40px) translateX(35px) scaleX(-1);
    transform: translateY(40px) translateX(35px) scaleX(-1)
}

.mc-skin-viewer-5x.slim.legacy .player > .right-arm .back {
    background-position: -255px -100px
}

.mc-skin-viewer-5x.slim.legacy .player > .right-arm > .accessory .back {
    background-position: -255px -180px
}

.mc-skin-viewer-5x .player .cape {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(180deg) translateX(-5px) translateZ(12.5px) translateY(37.5px) rotateX(5deg);
    transform: rotateY(180deg) translateX(-5px) translateZ(12.5px) translateY(37.5px) rotateX(5deg)
}

.mc-skin-viewer-5x .player .cape > .front, .mc-skin-viewer-5x .player .cape > .back, .mc-skin-viewer-5x .player .cape > .left, .mc-skin-viewer-5x .player .cape > .right, .mc-skin-viewer-5x .player .cape > .top, .mc-skin-viewer-5x .player .cape > .bottom {
    position: absolute;
    background-image: inherit !important;
    background-size: 320px 160px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-5x .player .cape > .front, .mc-skin-viewer-5x .player .cape > .back {
    width: 50px;
    height: 75px
}

.mc-skin-viewer-5x .player .cape > .left, .mc-skin-viewer-5x .player .cape > .right {
    width: 5px;
    height: 75px
}

.mc-skin-viewer-5x .player .cape > .top, .mc-skin-viewer-5x .player .cape > .bottom {
    width: 50px;
    height: 5px
}

.mc-skin-viewer-5x .player .cape > .front {
    background-position: -5px -5px;
    -webkit-transform: translateZ(2.5px);
    transform: translateZ(2.5px)
}

.mc-skin-viewer-5x .player .cape > .back {
    background-position: -60px -5px;
    -webkit-transform: translateZ(-2.5px) rotateY(180deg);
    transform: translateZ(-2.5px) rotateY(180deg)
}

.mc-skin-viewer-5x .player .cape > .left {
    background-position: 0 -5px;
    -webkit-transform: rotateY(270deg) translateZ(2.5px);
    transform: rotateY(270deg) translateZ(2.5px)
}

.mc-skin-viewer-5x .player .cape > .right {
    background-position: -55px -5px;
    -webkit-transform: rotateY(-270deg) translateZ(47.5px);
    transform: rotateY(-270deg) translateZ(47.5px)
}

.mc-skin-viewer-5x .player .cape > .top {
    background-position: -5px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(2.5px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(2.5px)
}

.mc-skin-viewer-5x .player .cape > .bottom {
    background-position: -55px 0;
    -webkit-transform: rotateX(-90deg) translateZ(72.5px);
    transform: rotateX(-90deg) translateZ(72.5px)
}

.mc-skin-viewer-5x.legacy-cape > .player .cape > .front, .mc-skin-viewer-5x.legacy-cape > .player .cape > .back, .mc-skin-viewer-5x.legacy-cape > .player .cape > .left, .mc-skin-viewer-5x.legacy-cape > .player .cape > .right, .mc-skin-viewer-5x.legacy-cape > .player .cape > .top, .mc-skin-viewer-5x.legacy-cape > .player .cape > .bottom {
    background-size: 110px 85px
}

.mc-skin-viewer-7x {
    width: 56px;
    height: 224px;
    margin: 15px auto 40px;
    -webkit-perspective: 800px;
    perspective: 800px;
    -webkit-perspective-origin: 50% 100px;
    perspective-origin: 50% 100px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-image: none !important;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor
}

.mc-skin-viewer-7x.hide-accessories .accessory {
    display: none
}

.mc-skin-viewer-7x.spin > .player {
    -webkit-animation: spin 12s infinite linear;
    animation: spin 12s infinite linear
}

.mc-skin-viewer-7x .player {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.mc-skin-viewer-7x .player .front, .mc-skin-viewer-7x .player .back, .mc-skin-viewer-7x .player .left, .mc-skin-viewer-7x .player .right, .mc-skin-viewer-7x .player .top, .mc-skin-viewer-7x .player .bottom {
    position: absolute;
    background-size: 448px;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-7x .player .accessory {
    background-image: none
}

.mc-skin-viewer-7x .player .accessory .front, .mc-skin-viewer-7x .player .accessory .back, .mc-skin-viewer-7x .player .accessory .left, .mc-skin-viewer-7x .player .accessory .right, .mc-skin-viewer-7x .player .accessory .top, .mc-skin-viewer-7x .player .accessory .bottom {
    -webkit-backface-visibility: visible;
    backface-visibility: visible
}

.mc-skin-viewer-7x .player > .head {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 56px;
    height: 56px;
    margin: 0 auto;
    background-image: none !important
}

.mc-skin-viewer-7x .player > .head .front, .mc-skin-viewer-7x .player > .head .back, .mc-skin-viewer-7x .player > .head .left, .mc-skin-viewer-7x .player > .head .right, .mc-skin-viewer-7x .player > .head .top, .mc-skin-viewer-7x .player > .head .bottom {
    width: 56px;
    height: 56px
}

.mc-skin-viewer-7x .player > .head .front {
    background-position: -56px -56px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(28px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(28px)
}

.mc-skin-viewer-7x .player > .head .back {
    background-position: -168px -56px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(28px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(28px)
}

.mc-skin-viewer-7x .player > .head .right {
    background-position: 0 -56px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(28px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(28px)
}

.mc-skin-viewer-7x .player > .head .left {
    background-position: -112px -56px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(28px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(28px)
}

.mc-skin-viewer-7x .player > .head .top {
    background-position: -56px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(28px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(28px)
}

.mc-skin-viewer-7x .player > .head .bottom {
    background-position: -112px 0;
    -webkit-transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(28px);
    transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(28px)
}

.mc-skin-viewer-7x .player > .head > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.125, 1.125, 1.125) translateY(-2.5px);
    transform: scale3d(1.125, 1.125, 1.125) translateY(-2.5px)
}

.mc-skin-viewer-7x .player > .head > .accessory > .front {
    background-position: -280px -56px
}

.mc-skin-viewer-7x .player > .head > .accessory > .back {
    background-position: -392px -56px
}

.mc-skin-viewer-7x .player > .head > .accessory > .right {
    background-position: -224px -56px
}

.mc-skin-viewer-7x .player > .head > .accessory > .left {
    background-position: -336px -56px
}

.mc-skin-viewer-7x .player > .head > .accessory > .top {
    background-position: -280px 0
}

.mc-skin-viewer-7x .player > .head > .accessory > .bottom {
    background-position: -336px 0
}

.mc-skin-viewer-7x .player > .body {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 56px;
    height: 56px;
    margin: 0 auto;
    -webkit-transform: translateY(56px);
    -ms-transform: translateY(56px);
    transform: translateY(56px);
    background-image: none !important
}

.mc-skin-viewer-7x .player > .body .front, .mc-skin-viewer-7x .player > .body .back, .mc-skin-viewer-7x .player > .body .left, .mc-skin-viewer-7x .player > .body .right, .mc-skin-viewer-7x .player > .body .top, .mc-skin-viewer-7x .player > .body .bottom {
    width: 56px;
    height: 84px
}

.mc-skin-viewer-7x .player > .body .left, .mc-skin-viewer-7x .player > .body .right {
    width: 28px
}

.mc-skin-viewer-7x .player > .body .top, .mc-skin-viewer-7x .player > .body .bottom {
    height: 28px
}

.mc-skin-viewer-7x .player > .body .front {
    background-position: -140px -140px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .body .back {
    background-position: -224px -140px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .body .right {
    background-position: -112px -140px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .body .left {
    background-position: -196px -140px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(42px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(42px)
}

.mc-skin-viewer-7x .player > .body .top {
    background-position: -140px -112px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .body .bottom {
    background-position: -196px -112px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(70px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(70px)
}

.mc-skin-viewer-7x .player > .body > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-2.625px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-2.625px)
}

.mc-skin-viewer-7x .player > .body > .accessory > .front {
    background-position: -140px -252px
}

.mc-skin-viewer-7x .player > .body > .accessory > .back {
    background-position: -224px -252px
}

.mc-skin-viewer-7x .player > .body > .accessory > .right {
    background-position: -112px -252px
}

.mc-skin-viewer-7x .player > .body > .accessory > .left {
    background-position: -196px -252px
}

.mc-skin-viewer-7x .player > .body > .accessory > .top {
    background-position: -140px -224px
}

.mc-skin-viewer-7x .player > .body > .accessory > .bottom {
    background-position: -196px -224px
}

.mc-skin-viewer-7x .player > .left-arm, .mc-skin-viewer-7x .player > .right-arm {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 28px;
    height: 84px;
    margin: 0 auto;
    -webkit-transform: translateY(56px) translateX(-28px);
    -ms-transform: translateY(56px) translateX(-28px);
    transform: translateY(56px) translateX(-28px);
    background-image: none !important
}

.mc-skin-viewer-7x .player > .left-arm .front, .mc-skin-viewer-7x .player > .right-arm .front, .mc-skin-viewer-7x .player > .left-arm .back, .mc-skin-viewer-7x .player > .right-arm .back, .mc-skin-viewer-7x .player > .left-arm .left, .mc-skin-viewer-7x .player > .right-arm .left, .mc-skin-viewer-7x .player > .left-arm .right, .mc-skin-viewer-7x .player > .right-arm .right, .mc-skin-viewer-7x .player > .left-arm .top, .mc-skin-viewer-7x .player > .right-arm .top, .mc-skin-viewer-7x .player > .left-arm .bottom, .mc-skin-viewer-7x .player > .right-arm .bottom {
    width: 28px;
    height: 84px
}

.mc-skin-viewer-7x .player > .left-arm .top, .mc-skin-viewer-7x .player > .right-arm .top, .mc-skin-viewer-7x .player > .left-arm .bottom, .mc-skin-viewer-7x .player > .right-arm .bottom {
    height: 28px
}

.mc-skin-viewer-7x .player > .left-arm .front, .mc-skin-viewer-7x .player > .right-arm .front {
    background-position: -308px -140px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-arm .back, .mc-skin-viewer-7x .player > .right-arm .back {
    background-position: -364px -140px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-arm .right, .mc-skin-viewer-7x .player > .right-arm .right {
    background-position: -280px -140px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-arm .left, .mc-skin-viewer-7x .player > .right-arm .left {
    background-position: -336px -140px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-arm .top, .mc-skin-viewer-7x .player > .right-arm .top {
    background-position: -308px -112px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-arm .bottom, .mc-skin-viewer-7x .player > .right-arm .bottom {
    background-position: -336px -112px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(70px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(70px)
}

.mc-skin-viewer-7x .player > .left-arm > .accessory, .mc-skin-viewer-7x .player > .right-arm > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-2.625px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-2.625px)
}

.mc-skin-viewer-7x .player > .left-arm > .accessory .front, .mc-skin-viewer-7x .player > .right-arm > .accessory .front {
    background-position: -308px -252px
}

.mc-skin-viewer-7x .player > .left-arm > .accessory .back, .mc-skin-viewer-7x .player > .right-arm > .accessory .back {
    background-position: -364px -252px
}

.mc-skin-viewer-7x .player > .left-arm > .accessory .right, .mc-skin-viewer-7x .player > .right-arm > .accessory .right {
    background-position: -280px -252px
}

.mc-skin-viewer-7x .player > .left-arm > .accessory .left, .mc-skin-viewer-7x .player > .right-arm > .accessory .left {
    background-position: -336px -252px
}

.mc-skin-viewer-7x .player > .left-arm > .accessory .top, .mc-skin-viewer-7x .player > .right-arm > .accessory .top {
    background-position: -308px -224px
}

.mc-skin-viewer-7x .player > .left-arm > .accessory .bottom, .mc-skin-viewer-7x .player > .right-arm > .accessory .bottom {
    background-position: -336px -224px
}

.mc-skin-viewer-7x .player > .right-arm {
    -webkit-transform: translateY(56px) translateX(56px) scaleX(-1);
    -ms-transform: translateY(56px) translateX(56px) scaleX(-1);
    transform: translateY(56px) translateX(56px) scaleX(-1)
}

.mc-skin-viewer-7x .player > .left-leg, .mc-skin-viewer-7x .player > .right-leg {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 28px;
    height: 84px;
    margin: 0 auto;
    -webkit-transform: translateY(140px);
    -ms-transform: translateY(140px);
    transform: translateY(140px);
    background-image: none !important
}

.mc-skin-viewer-7x .player > .left-leg .front, .mc-skin-viewer-7x .player > .right-leg .front, .mc-skin-viewer-7x .player > .left-leg .back, .mc-skin-viewer-7x .player > .right-leg .back, .mc-skin-viewer-7x .player > .left-leg .left, .mc-skin-viewer-7x .player > .right-leg .left, .mc-skin-viewer-7x .player > .left-leg .right, .mc-skin-viewer-7x .player > .right-leg .right, .mc-skin-viewer-7x .player > .left-leg .top, .mc-skin-viewer-7x .player > .right-leg .top, .mc-skin-viewer-7x .player > .left-leg .bottom, .mc-skin-viewer-7x .player > .right-leg .bottom {
    width: 28px;
    height: 84px
}

.mc-skin-viewer-7x .player > .left-leg .top, .mc-skin-viewer-7x .player > .right-leg .top, .mc-skin-viewer-7x .player > .left-leg .bottom, .mc-skin-viewer-7x .player > .right-leg .bottom {
    height: 28px
}

.mc-skin-viewer-7x .player > .left-leg .front, .mc-skin-viewer-7x .player > .right-leg .front {
    background-position: -28px -140px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-leg .back, .mc-skin-viewer-7x .player > .right-leg .back {
    background-position: -84px -140px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-leg .right, .mc-skin-viewer-7x .player > .right-leg .right {
    background-position: 0 -140px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-leg .left, .mc-skin-viewer-7x .player > .right-leg .left {
    background-position: -56px -140px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-leg .top, .mc-skin-viewer-7x .player > .right-leg .top {
    background-position: -28px -112px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x .player > .left-leg .bottom, .mc-skin-viewer-7x .player > .right-leg .bottom {
    background-position: -56px -112px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(70px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(70px)
}

.mc-skin-viewer-7x .player > .left-leg > .accessory, .mc-skin-viewer-7x .player > .right-leg > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-1.3125px);
    transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-1.3125px)
}

.mc-skin-viewer-7x .player > .left-leg > .accessory .front, .mc-skin-viewer-7x .player > .right-leg > .accessory .front {
    background-position: -28px -252px
}

.mc-skin-viewer-7x .player > .left-leg > .accessory .back, .mc-skin-viewer-7x .player > .right-leg > .accessory .back {
    background-position: -84px -252px
}

.mc-skin-viewer-7x .player > .left-leg > .accessory .right, .mc-skin-viewer-7x .player > .right-leg > .accessory .right {
    background-position: 0 -252px
}

.mc-skin-viewer-7x .player > .left-leg > .accessory .left, .mc-skin-viewer-7x .player > .right-leg > .accessory .left {
    background-position: -56px -252px
}

.mc-skin-viewer-7x .player > .left-leg > .accessory .top, .mc-skin-viewer-7x .player > .right-leg > .accessory .top {
    background-position: -28px -224px
}

.mc-skin-viewer-7x .player > .left-leg > .accessory .bottom, .mc-skin-viewer-7x .player > .right-leg > .accessory .bottom {
    background-position: -56px -224px
}

.mc-skin-viewer-7x .player > .right-leg {
    -webkit-transform: translateY(140px) translateX(28px) scaleX(-1);
    -ms-transform: translateY(140px) translateX(28px) scaleX(-1);
    transform: translateY(140px) translateX(28px) scaleX(-1)
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm {
    -webkit-transform: translateY(56px) translateX(56px);
    -ms-transform: translateY(56px) translateX(56px);
    transform: translateY(56px) translateX(56px)
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm .front {
    background-position: -252px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm .back {
    background-position: -308px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm .right {
    background-position: -224px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm .left {
    background-position: -280px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm .top {
    background-position: -252px -336px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm .bottom {
    background-position: -280px -336px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm > .accessory .front {
    background-position: -364px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm > .accessory .back {
    background-position: -420px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm > .accessory .right {
    background-position: -336px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm > .accessory .left {
    background-position: -392px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm > .accessory .top {
    background-position: -364px -336px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-arm > .accessory .bottom {
    background-position: -392px -336px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg {
    -webkit-transform: translateY(140px) translateX(28px);
    -ms-transform: translateY(140px) translateX(28px);
    transform: translateY(140px) translateX(28px)
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg .front {
    background-position: -140px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg .back {
    background-position: -196px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg .right {
    background-position: -112px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg .left {
    background-position: -168px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg .top {
    background-position: -140px -336px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg .bottom {
    background-position: -168px -336px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg > .accessory .front {
    background-position: -28px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg > .accessory .back {
    background-position: -84px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg > .accessory .right {
    background-position: 0 -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg > .accessory .left {
    background-position: -56px -364px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg > .accessory .top {
    background-position: -28px -336px
}

.mc-skin-viewer-7x:not(.legacy) .player > .right-leg > .accessory .bottom {
    background-position: -56px -336px
}

.mc-skin-viewer-7x.slim .player > .left-arm .front, .mc-skin-viewer-7x.slim .player > .right-arm .front, .mc-skin-viewer-7x.slim .player > .left-arm .back, .mc-skin-viewer-7x.slim .player > .right-arm .back, .mc-skin-viewer-7x.slim .player > .left-arm .top, .mc-skin-viewer-7x.slim .player > .right-arm .top, .mc-skin-viewer-7x.slim .player > .left-arm .bottom, .mc-skin-viewer-7x.slim .player > .right-arm .bottom {
    width: 21px
}

.mc-skin-viewer-7x.slim .player > .left-arm .front {
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(7px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(0deg) translateX(7px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x.slim .player > .left-arm .back {
    background-position: -357px -140px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(-7px) translateY(0px) translateZ(14px);
    transform: rotateX(0deg) rotateY(180deg) translateX(-7px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x.slim .player > .left-arm .right {
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(7px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(7px)
}

.mc-skin-viewer-7x.slim .player > .left-arm .left {
    background-position: -329px -140px
}

.mc-skin-viewer-7x.slim .player > .left-arm .top {
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(7px) translateY(0px) translateZ(14px);
    transform: rotateX(90deg) rotateY(0deg) translateX(7px) translateY(0px) translateZ(14px)
}

.mc-skin-viewer-7x.slim .player > .left-arm .bottom {
    background-position: -329px -112px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(7px) translateY(0px) translateZ(70px);
    transform: rotateX(270deg) rotateY(0deg) translateX(7px) translateY(0px) translateZ(70px)
}

.mc-skin-viewer-7x.slim .player > .left-arm > .accessory .back {
    background-position: -357px -252px
}

.mc-skin-viewer-7x.slim .player > .left-arm > .accessory .left {
    background-position: -329px -252px
}

.mc-skin-viewer-7x.slim .player > .left-arm > .accessory .bottom {
    background-position: -329px -224px
}

.mc-skin-viewer-7x.slim .player > .right-arm .back {
    background-position: -301px -364px
}

.mc-skin-viewer-7x.slim .player > .right-arm .left {
    background-position: -273px -364px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(7px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(7px)
}

.mc-skin-viewer-7x.slim .player > .right-arm .bottom {
    background-position: -273px -336px
}

.mc-skin-viewer-7x.slim .player > .right-arm > .accessory .back {
    background-position: -413px -364px
}

.mc-skin-viewer-7x.slim .player > .right-arm > .accessory .left {
    background-position: -385px -364px
}

.mc-skin-viewer-7x.slim .player > .right-arm > .accessory .bottom {
    background-position: -385px -336px
}

.mc-skin-viewer-7x.slim.legacy .player > .right-arm {
    -webkit-transform: translateY(56px) translateX(49px) scaleX(-1);
    -ms-transform: translateY(56px) translateX(49px) scaleX(-1);
    transform: translateY(56px) translateX(49px) scaleX(-1)
}

.mc-skin-viewer-7x.slim.legacy .player > .right-arm .back {
    background-position: -357px -140px
}

.mc-skin-viewer-7x.slim.legacy .player > .right-arm > .accessory .back {
    background-position: -357px -252px
}

.mc-skin-viewer-7x .player .cape {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(180deg) translateX(-7px) translateZ(17.5px) translateY(52.5px) rotateX(5deg);
    transform: rotateY(180deg) translateX(-7px) translateZ(17.5px) translateY(52.5px) rotateX(5deg)
}

.mc-skin-viewer-7x .player .cape > .front, .mc-skin-viewer-7x .player .cape > .back, .mc-skin-viewer-7x .player .cape > .left, .mc-skin-viewer-7x .player .cape > .right, .mc-skin-viewer-7x .player .cape > .top, .mc-skin-viewer-7x .player .cape > .bottom {
    position: absolute;
    background-image: inherit !important;
    background-size: 448px 224px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-7x .player .cape > .front, .mc-skin-viewer-7x .player .cape > .back {
    width: 70px;
    height: 105px
}

.mc-skin-viewer-7x .player .cape > .left, .mc-skin-viewer-7x .player .cape > .right {
    width: 7px;
    height: 105px
}

.mc-skin-viewer-7x .player .cape > .top, .mc-skin-viewer-7x .player .cape > .bottom {
    width: 70px;
    height: 7px
}

.mc-skin-viewer-7x .player .cape > .front {
    background-position: -7px -7px;
    -webkit-transform: translateZ(3.5px);
    transform: translateZ(3.5px)
}

.mc-skin-viewer-7x .player .cape > .back {
    background-position: -84px -7px;
    -webkit-transform: translateZ(-3.5px) rotateY(180deg);
    transform: translateZ(-3.5px) rotateY(180deg)
}

.mc-skin-viewer-7x .player .cape > .left {
    background-position: 0 -7px;
    -webkit-transform: rotateY(270deg) translateZ(3.5px);
    transform: rotateY(270deg) translateZ(3.5px)
}

.mc-skin-viewer-7x .player .cape > .right {
    background-position: -77px -7px;
    -webkit-transform: rotateY(-270deg) translateZ(66.5px);
    transform: rotateY(-270deg) translateZ(66.5px)
}

.mc-skin-viewer-7x .player .cape > .top {
    background-position: -7px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(3.5px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(3.5px)
}

.mc-skin-viewer-7x .player .cape > .bottom {
    background-position: -77px 0;
    -webkit-transform: rotateX(-90deg) translateZ(101.5px);
    transform: rotateX(-90deg) translateZ(101.5px)
}

.mc-skin-viewer-7x.legacy-cape > .player .cape > .front, .mc-skin-viewer-7x.legacy-cape > .player .cape > .back, .mc-skin-viewer-7x.legacy-cape > .player .cape > .left, .mc-skin-viewer-7x.legacy-cape > .player .cape > .right, .mc-skin-viewer-7x.legacy-cape > .player .cape > .top, .mc-skin-viewer-7x.legacy-cape > .player .cape > .bottom {
    background-size: 154px 119px
}

.mc-skin-viewer-8x {
    width: 64px;
    height: 256px;
    margin: 15px auto 40px;
    -webkit-perspective: 800px;
    perspective: 800px;
    -webkit-perspective-origin: 50% 100px;
    perspective-origin: 50% 100px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-image: none !important;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor
}

.mc-skin-viewer-8x.hide-accessories .accessory {
    display: none
}

.mc-skin-viewer-8x.spin > .player {
    -webkit-animation: spin 12s infinite linear;
    animation: spin 12s infinite linear
}

.mc-skin-viewer-8x .player {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.mc-skin-viewer-8x .player .front, .mc-skin-viewer-8x .player .back, .mc-skin-viewer-8x .player .left, .mc-skin-viewer-8x .player .right, .mc-skin-viewer-8x .player .top, .mc-skin-viewer-8x .player .bottom {
    position: absolute;
    background-size: 512px;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-8x .player .accessory {
    background-image: none
}

.mc-skin-viewer-8x .player .accessory .front, .mc-skin-viewer-8x .player .accessory .back, .mc-skin-viewer-8x .player .accessory .left, .mc-skin-viewer-8x .player .accessory .right, .mc-skin-viewer-8x .player .accessory .top, .mc-skin-viewer-8x .player .accessory .bottom {
    -webkit-backface-visibility: visible;
    backface-visibility: visible
}

.mc-skin-viewer-8x .player > .head {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 64px;
    height: 64px;
    margin: 0 auto;
    background-image: none !important
}

.mc-skin-viewer-8x .player > .head .front, .mc-skin-viewer-8x .player > .head .back, .mc-skin-viewer-8x .player > .head .left, .mc-skin-viewer-8x .player > .head .right, .mc-skin-viewer-8x .player > .head .top, .mc-skin-viewer-8x .player > .head .bottom {
    width: 64px;
    height: 64px
}

.mc-skin-viewer-8x .player > .head .front {
    background-position: -64px -64px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(32px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(32px)
}

.mc-skin-viewer-8x .player > .head .back {
    background-position: -192px -64px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(32px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(32px)
}

.mc-skin-viewer-8x .player > .head .right {
    background-position: 0 -64px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(32px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(32px)
}

.mc-skin-viewer-8x .player > .head .left {
    background-position: -128px -64px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(32px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(32px)
}

.mc-skin-viewer-8x .player > .head .top {
    background-position: -64px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(32px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(32px)
}

.mc-skin-viewer-8x .player > .head .bottom {
    background-position: -128px 0;
    -webkit-transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(32px);
    transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(32px)
}

.mc-skin-viewer-8x .player > .head > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.125, 1.125, 1.125) translateY(-3px);
    transform: scale3d(1.125, 1.125, 1.125) translateY(-3px)
}

.mc-skin-viewer-8x .player > .head > .accessory > .front {
    background-position: -320px -64px
}

.mc-skin-viewer-8x .player > .head > .accessory > .back {
    background-position: -448px -64px
}

.mc-skin-viewer-8x .player > .head > .accessory > .right {
    background-position: -256px -64px
}

.mc-skin-viewer-8x .player > .head > .accessory > .left {
    background-position: -384px -64px
}

.mc-skin-viewer-8x .player > .head > .accessory > .top {
    background-position: -320px 0
}

.mc-skin-viewer-8x .player > .head > .accessory > .bottom {
    background-position: -384px 0
}

.mc-skin-viewer-8x .player > .body {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 64px;
    height: 64px;
    margin: 0 auto;
    -webkit-transform: translateY(64px);
    -ms-transform: translateY(64px);
    transform: translateY(64px);
    background-image: none !important
}

.mc-skin-viewer-8x .player > .body .front, .mc-skin-viewer-8x .player > .body .back, .mc-skin-viewer-8x .player > .body .left, .mc-skin-viewer-8x .player > .body .right, .mc-skin-viewer-8x .player > .body .top, .mc-skin-viewer-8x .player > .body .bottom {
    width: 64px;
    height: 96px
}

.mc-skin-viewer-8x .player > .body .left, .mc-skin-viewer-8x .player > .body .right {
    width: 32px
}

.mc-skin-viewer-8x .player > .body .top, .mc-skin-viewer-8x .player > .body .bottom {
    height: 32px
}

.mc-skin-viewer-8x .player > .body .front {
    background-position: -160px -160px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .body .back {
    background-position: -256px -160px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .body .right {
    background-position: -128px -160px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .body .left {
    background-position: -224px -160px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(48px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(48px)
}

.mc-skin-viewer-8x .player > .body .top {
    background-position: -160px -128px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .body .bottom {
    background-position: -224px -128px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(80px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(80px)
}

.mc-skin-viewer-8x .player > .body > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-3px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-3px)
}

.mc-skin-viewer-8x .player > .body > .accessory > .front {
    background-position: -160px -288px
}

.mc-skin-viewer-8x .player > .body > .accessory > .back {
    background-position: -256px -288px
}

.mc-skin-viewer-8x .player > .body > .accessory > .right {
    background-position: -128px -288px
}

.mc-skin-viewer-8x .player > .body > .accessory > .left {
    background-position: -224px -288px
}

.mc-skin-viewer-8x .player > .body > .accessory > .top {
    background-position: -160px -256px
}

.mc-skin-viewer-8x .player > .body > .accessory > .bottom {
    background-position: -224px -256px
}

.mc-skin-viewer-8x .player > .left-arm, .mc-skin-viewer-8x .player > .right-arm {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 32px;
    height: 96px;
    margin: 0 auto;
    -webkit-transform: translateY(64px) translateX(-32px);
    -ms-transform: translateY(64px) translateX(-32px);
    transform: translateY(64px) translateX(-32px);
    background-image: none !important
}

.mc-skin-viewer-8x .player > .left-arm .front, .mc-skin-viewer-8x .player > .right-arm .front, .mc-skin-viewer-8x .player > .left-arm .back, .mc-skin-viewer-8x .player > .right-arm .back, .mc-skin-viewer-8x .player > .left-arm .left, .mc-skin-viewer-8x .player > .right-arm .left, .mc-skin-viewer-8x .player > .left-arm .right, .mc-skin-viewer-8x .player > .right-arm .right, .mc-skin-viewer-8x .player > .left-arm .top, .mc-skin-viewer-8x .player > .right-arm .top, .mc-skin-viewer-8x .player > .left-arm .bottom, .mc-skin-viewer-8x .player > .right-arm .bottom {
    width: 32px;
    height: 96px
}

.mc-skin-viewer-8x .player > .left-arm .top, .mc-skin-viewer-8x .player > .right-arm .top, .mc-skin-viewer-8x .player > .left-arm .bottom, .mc-skin-viewer-8x .player > .right-arm .bottom {
    height: 32px
}

.mc-skin-viewer-8x .player > .left-arm .front, .mc-skin-viewer-8x .player > .right-arm .front {
    background-position: -352px -160px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-arm .back, .mc-skin-viewer-8x .player > .right-arm .back {
    background-position: -416px -160px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-arm .right, .mc-skin-viewer-8x .player > .right-arm .right {
    background-position: -320px -160px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-arm .left, .mc-skin-viewer-8x .player > .right-arm .left {
    background-position: -384px -160px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-arm .top, .mc-skin-viewer-8x .player > .right-arm .top {
    background-position: -352px -128px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-arm .bottom, .mc-skin-viewer-8x .player > .right-arm .bottom {
    background-position: -384px -128px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(80px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(80px)
}

.mc-skin-viewer-8x .player > .left-arm > .accessory, .mc-skin-viewer-8x .player > .right-arm > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-3px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-3px)
}

.mc-skin-viewer-8x .player > .left-arm > .accessory .front, .mc-skin-viewer-8x .player > .right-arm > .accessory .front {
    background-position: -352px -288px
}

.mc-skin-viewer-8x .player > .left-arm > .accessory .back, .mc-skin-viewer-8x .player > .right-arm > .accessory .back {
    background-position: -416px -288px
}

.mc-skin-viewer-8x .player > .left-arm > .accessory .right, .mc-skin-viewer-8x .player > .right-arm > .accessory .right {
    background-position: -320px -288px
}

.mc-skin-viewer-8x .player > .left-arm > .accessory .left, .mc-skin-viewer-8x .player > .right-arm > .accessory .left {
    background-position: -384px -288px
}

.mc-skin-viewer-8x .player > .left-arm > .accessory .top, .mc-skin-viewer-8x .player > .right-arm > .accessory .top {
    background-position: -352px -256px
}

.mc-skin-viewer-8x .player > .left-arm > .accessory .bottom, .mc-skin-viewer-8x .player > .right-arm > .accessory .bottom {
    background-position: -384px -256px
}

.mc-skin-viewer-8x .player > .right-arm {
    -webkit-transform: translateY(64px) translateX(64px) scaleX(-1);
    -ms-transform: translateY(64px) translateX(64px) scaleX(-1);
    transform: translateY(64px) translateX(64px) scaleX(-1)
}

.mc-skin-viewer-8x .player > .left-leg, .mc-skin-viewer-8x .player > .right-leg {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 32px;
    height: 96px;
    margin: 0 auto;
    -webkit-transform: translateY(160px);
    -ms-transform: translateY(160px);
    transform: translateY(160px);
    background-image: none !important
}

.mc-skin-viewer-8x .player > .left-leg .front, .mc-skin-viewer-8x .player > .right-leg .front, .mc-skin-viewer-8x .player > .left-leg .back, .mc-skin-viewer-8x .player > .right-leg .back, .mc-skin-viewer-8x .player > .left-leg .left, .mc-skin-viewer-8x .player > .right-leg .left, .mc-skin-viewer-8x .player > .left-leg .right, .mc-skin-viewer-8x .player > .right-leg .right, .mc-skin-viewer-8x .player > .left-leg .top, .mc-skin-viewer-8x .player > .right-leg .top, .mc-skin-viewer-8x .player > .left-leg .bottom, .mc-skin-viewer-8x .player > .right-leg .bottom {
    width: 32px;
    height: 96px
}

.mc-skin-viewer-8x .player > .left-leg .top, .mc-skin-viewer-8x .player > .right-leg .top, .mc-skin-viewer-8x .player > .left-leg .bottom, .mc-skin-viewer-8x .player > .right-leg .bottom {
    height: 32px
}

.mc-skin-viewer-8x .player > .left-leg .front, .mc-skin-viewer-8x .player > .right-leg .front {
    background-position: -32px -160px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-leg .back, .mc-skin-viewer-8x .player > .right-leg .back {
    background-position: -96px -160px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-leg .right, .mc-skin-viewer-8x .player > .right-leg .right {
    background-position: 0 -160px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-leg .left, .mc-skin-viewer-8x .player > .right-leg .left {
    background-position: -64px -160px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-leg .top, .mc-skin-viewer-8x .player > .right-leg .top {
    background-position: -32px -128px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x .player > .left-leg .bottom, .mc-skin-viewer-8x .player > .right-leg .bottom {
    background-position: -64px -128px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(80px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(80px)
}

.mc-skin-viewer-8x .player > .left-leg > .accessory, .mc-skin-viewer-8x .player > .right-leg > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-1.5px);
    transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-1.5px)
}

.mc-skin-viewer-8x .player > .left-leg > .accessory .front, .mc-skin-viewer-8x .player > .right-leg > .accessory .front {
    background-position: -32px -288px
}

.mc-skin-viewer-8x .player > .left-leg > .accessory .back, .mc-skin-viewer-8x .player > .right-leg > .accessory .back {
    background-position: -96px -288px
}

.mc-skin-viewer-8x .player > .left-leg > .accessory .right, .mc-skin-viewer-8x .player > .right-leg > .accessory .right {
    background-position: 0 -288px
}

.mc-skin-viewer-8x .player > .left-leg > .accessory .left, .mc-skin-viewer-8x .player > .right-leg > .accessory .left {
    background-position: -64px -288px
}

.mc-skin-viewer-8x .player > .left-leg > .accessory .top, .mc-skin-viewer-8x .player > .right-leg > .accessory .top {
    background-position: -32px -256px
}

.mc-skin-viewer-8x .player > .left-leg > .accessory .bottom, .mc-skin-viewer-8x .player > .right-leg > .accessory .bottom {
    background-position: -64px -256px
}

.mc-skin-viewer-8x .player > .right-leg {
    -webkit-transform: translateY(160px) translateX(32px) scaleX(-1);
    -ms-transform: translateY(160px) translateX(32px) scaleX(-1);
    transform: translateY(160px) translateX(32px) scaleX(-1)
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm {
    -webkit-transform: translateY(64px) translateX(64px);
    -ms-transform: translateY(64px) translateX(64px);
    transform: translateY(64px) translateX(64px)
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm .front {
    background-position: -288px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm .back {
    background-position: -352px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm .right {
    background-position: -256px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm .left {
    background-position: -320px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm .top {
    background-position: -288px -384px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm .bottom {
    background-position: -320px -384px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm > .accessory .front {
    background-position: -416px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm > .accessory .back {
    background-position: -480px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm > .accessory .right {
    background-position: -384px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm > .accessory .left {
    background-position: -448px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm > .accessory .top {
    background-position: -416px -384px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-arm > .accessory .bottom {
    background-position: -448px -384px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg {
    -webkit-transform: translateY(160px) translateX(32px);
    -ms-transform: translateY(160px) translateX(32px);
    transform: translateY(160px) translateX(32px)
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg .front {
    background-position: -160px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg .back {
    background-position: -224px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg .right {
    background-position: -128px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg .left {
    background-position: -192px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg .top {
    background-position: -160px -384px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg .bottom {
    background-position: -192px -384px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg > .accessory .front {
    background-position: -32px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg > .accessory .back {
    background-position: -96px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg > .accessory .right {
    background-position: 0 -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg > .accessory .left {
    background-position: -64px -416px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg > .accessory .top {
    background-position: -32px -384px
}

.mc-skin-viewer-8x:not(.legacy) .player > .right-leg > .accessory .bottom {
    background-position: -64px -384px
}

.mc-skin-viewer-8x.slim .player > .left-arm .front, .mc-skin-viewer-8x.slim .player > .right-arm .front, .mc-skin-viewer-8x.slim .player > .left-arm .back, .mc-skin-viewer-8x.slim .player > .right-arm .back, .mc-skin-viewer-8x.slim .player > .left-arm .top, .mc-skin-viewer-8x.slim .player > .right-arm .top, .mc-skin-viewer-8x.slim .player > .left-arm .bottom, .mc-skin-viewer-8x.slim .player > .right-arm .bottom {
    width: 24px
}

.mc-skin-viewer-8x.slim .player > .left-arm .front {
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(8px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(0deg) translateX(8px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x.slim .player > .left-arm .back {
    background-position: -408px -160px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(-8px) translateY(0px) translateZ(16px);
    transform: rotateX(0deg) rotateY(180deg) translateX(-8px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x.slim .player > .left-arm .right {
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-8x.slim .player > .left-arm .left {
    background-position: -376px -160px
}

.mc-skin-viewer-8x.slim .player > .left-arm .top {
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(8px) translateY(0px) translateZ(16px);
    transform: rotateX(90deg) rotateY(0deg) translateX(8px) translateY(0px) translateZ(16px)
}

.mc-skin-viewer-8x.slim .player > .left-arm .bottom {
    background-position: -376px -128px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(8px) translateY(0px) translateZ(80px);
    transform: rotateX(270deg) rotateY(0deg) translateX(8px) translateY(0px) translateZ(80px)
}

.mc-skin-viewer-8x.slim .player > .left-arm > .accessory .back {
    background-position: -408px -288px
}

.mc-skin-viewer-8x.slim .player > .left-arm > .accessory .left {
    background-position: -376px -288px
}

.mc-skin-viewer-8x.slim .player > .left-arm > .accessory .bottom {
    background-position: -376px -256px
}

.mc-skin-viewer-8x.slim .player > .right-arm .back {
    background-position: -344px -416px
}

.mc-skin-viewer-8x.slim .player > .right-arm .left {
    background-position: -312px -416px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(8px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(8px)
}

.mc-skin-viewer-8x.slim .player > .right-arm .bottom {
    background-position: -312px -384px
}

.mc-skin-viewer-8x.slim .player > .right-arm > .accessory .back {
    background-position: -472px -416px
}

.mc-skin-viewer-8x.slim .player > .right-arm > .accessory .left {
    background-position: -440px -416px
}

.mc-skin-viewer-8x.slim .player > .right-arm > .accessory .bottom {
    background-position: -440px -384px
}

.mc-skin-viewer-8x.slim.legacy .player > .right-arm {
    -webkit-transform: translateY(64px) translateX(56px) scaleX(-1);
    -ms-transform: translateY(64px) translateX(56px) scaleX(-1);
    transform: translateY(64px) translateX(56px) scaleX(-1)
}

.mc-skin-viewer-8x.slim.legacy .player > .right-arm .back {
    background-position: -408px -160px
}

.mc-skin-viewer-8x.slim.legacy .player > .right-arm > .accessory .back {
    background-position: -408px -288px
}

.mc-skin-viewer-8x .player .cape {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(180deg) translateX(-8px) translateZ(20px) translateY(60px) rotateX(5deg);
    transform: rotateY(180deg) translateX(-8px) translateZ(20px) translateY(60px) rotateX(5deg)
}

.mc-skin-viewer-8x .player .cape > .front, .mc-skin-viewer-8x .player .cape > .back, .mc-skin-viewer-8x .player .cape > .left, .mc-skin-viewer-8x .player .cape > .right, .mc-skin-viewer-8x .player .cape > .top, .mc-skin-viewer-8x .player .cape > .bottom {
    position: absolute;
    background-image: inherit !important;
    background-size: 512px 256px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-8x .player .cape > .front, .mc-skin-viewer-8x .player .cape > .back {
    width: 80px;
    height: 120px
}

.mc-skin-viewer-8x .player .cape > .left, .mc-skin-viewer-8x .player .cape > .right {
    width: 8px;
    height: 120px
}

.mc-skin-viewer-8x .player .cape > .top, .mc-skin-viewer-8x .player .cape > .bottom {
    width: 80px;
    height: 8px
}

.mc-skin-viewer-8x .player .cape > .front {
    background-position: -8px -8px;
    -webkit-transform: translateZ(4px);
    transform: translateZ(4px)
}

.mc-skin-viewer-8x .player .cape > .back {
    background-position: -96px -8px;
    -webkit-transform: translateZ(-4px) rotateY(180deg);
    transform: translateZ(-4px) rotateY(180deg)
}

.mc-skin-viewer-8x .player .cape > .left {
    background-position: 0 -8px;
    -webkit-transform: rotateY(270deg) translateZ(4px);
    transform: rotateY(270deg) translateZ(4px)
}

.mc-skin-viewer-8x .player .cape > .right {
    background-position: -88px -8px;
    -webkit-transform: rotateY(-270deg) translateZ(76px);
    transform: rotateY(-270deg) translateZ(76px)
}

.mc-skin-viewer-8x .player .cape > .top {
    background-position: -8px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4px)
}

.mc-skin-viewer-8x .player .cape > .bottom {
    background-position: -88px 0;
    -webkit-transform: rotateX(-90deg) translateZ(116px);
    transform: rotateX(-90deg) translateZ(116px)
}

.mc-skin-viewer-8x.legacy-cape > .player .cape > .front, .mc-skin-viewer-8x.legacy-cape > .player .cape > .back, .mc-skin-viewer-8x.legacy-cape > .player .cape > .left, .mc-skin-viewer-8x.legacy-cape > .player .cape > .right, .mc-skin-viewer-8x.legacy-cape > .player .cape > .top, .mc-skin-viewer-8x.legacy-cape > .player .cape > .bottom {
    background-size: 176px 136px
}

.mc-skin-viewer-9x {
    width: 72px;
    height: 288px;
    margin: 15px auto 40px;
    -webkit-perspective: 800px;
    perspective: 800px;
    -webkit-perspective-origin: 50% 100px;
    perspective-origin: 50% 100px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-image: none !important;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor
}

.mc-skin-viewer-9x.hide-accessories .accessory {
    display: none
}

.mc-skin-viewer-9x.spin > .player {
    -webkit-animation: spin 12s infinite linear;
    animation: spin 12s infinite linear
}

.mc-skin-viewer-9x .player {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.mc-skin-viewer-9x .player .front, .mc-skin-viewer-9x .player .back, .mc-skin-viewer-9x .player .left, .mc-skin-viewer-9x .player .right, .mc-skin-viewer-9x .player .top, .mc-skin-viewer-9x .player .bottom {
    position: absolute;
    background-size: 576px;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-9x .player .accessory {
    background-image: none
}

.mc-skin-viewer-9x .player .accessory .front, .mc-skin-viewer-9x .player .accessory .back, .mc-skin-viewer-9x .player .accessory .left, .mc-skin-viewer-9x .player .accessory .right, .mc-skin-viewer-9x .player .accessory .top, .mc-skin-viewer-9x .player .accessory .bottom {
    -webkit-backface-visibility: visible;
    backface-visibility: visible
}

.mc-skin-viewer-9x .player > .head {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 72px;
    height: 72px;
    margin: 0 auto;
    background-image: none !important
}

.mc-skin-viewer-9x .player > .head .front, .mc-skin-viewer-9x .player > .head .back, .mc-skin-viewer-9x .player > .head .left, .mc-skin-viewer-9x .player > .head .right, .mc-skin-viewer-9x .player > .head .top, .mc-skin-viewer-9x .player > .head .bottom {
    width: 72px;
    height: 72px
}

.mc-skin-viewer-9x .player > .head .front {
    background-position: -72px -72px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(36px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(36px)
}

.mc-skin-viewer-9x .player > .head .back {
    background-position: -216px -72px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(36px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(36px)
}

.mc-skin-viewer-9x .player > .head .right {
    background-position: 0 -72px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(36px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(36px)
}

.mc-skin-viewer-9x .player > .head .left {
    background-position: -144px -72px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(36px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(36px)
}

.mc-skin-viewer-9x .player > .head .top {
    background-position: -72px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(36px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(36px)
}

.mc-skin-viewer-9x .player > .head .bottom {
    background-position: -144px 0;
    -webkit-transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(36px);
    transform: rotateX(270deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(36px)
}

.mc-skin-viewer-9x .player > .head > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.125, 1.125, 1.125) translateY(-3.5px);
    transform: scale3d(1.125, 1.125, 1.125) translateY(-3.5px)
}

.mc-skin-viewer-9x .player > .head > .accessory > .front {
    background-position: -360px -72px
}

.mc-skin-viewer-9x .player > .head > .accessory > .back {
    background-position: -504px -72px
}

.mc-skin-viewer-9x .player > .head > .accessory > .right {
    background-position: -288px -72px
}

.mc-skin-viewer-9x .player > .head > .accessory > .left {
    background-position: -432px -72px
}

.mc-skin-viewer-9x .player > .head > .accessory > .top {
    background-position: -360px 0
}

.mc-skin-viewer-9x .player > .head > .accessory > .bottom {
    background-position: -432px 0
}

.mc-skin-viewer-9x .player > .body {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 72px;
    height: 72px;
    margin: 0 auto;
    -webkit-transform: translateY(72px);
    -ms-transform: translateY(72px);
    transform: translateY(72px);
    background-image: none !important
}

.mc-skin-viewer-9x .player > .body .front, .mc-skin-viewer-9x .player > .body .back, .mc-skin-viewer-9x .player > .body .left, .mc-skin-viewer-9x .player > .body .right, .mc-skin-viewer-9x .player > .body .top, .mc-skin-viewer-9x .player > .body .bottom {
    width: 72px;
    height: 108px
}

.mc-skin-viewer-9x .player > .body .left, .mc-skin-viewer-9x .player > .body .right {
    width: 36px
}

.mc-skin-viewer-9x .player > .body .top, .mc-skin-viewer-9x .player > .body .bottom {
    height: 36px
}

.mc-skin-viewer-9x .player > .body .front {
    background-position: -180px -180px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .body .back {
    background-position: -288px -180px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .body .right {
    background-position: -144px -180px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .body .left {
    background-position: -252px -180px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(54px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(54px)
}

.mc-skin-viewer-9x .player > .body .top {
    background-position: -180px -144px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .body .bottom {
    background-position: -252px -144px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(90px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(90px)
}

.mc-skin-viewer-9x .player > .body > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-3.375px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-3.375px)
}

.mc-skin-viewer-9x .player > .body > .accessory > .front {
    background-position: -180px -324px
}

.mc-skin-viewer-9x .player > .body > .accessory > .back {
    background-position: -288px -324px
}

.mc-skin-viewer-9x .player > .body > .accessory > .right {
    background-position: -144px -324px
}

.mc-skin-viewer-9x .player > .body > .accessory > .left {
    background-position: -252px -324px
}

.mc-skin-viewer-9x .player > .body > .accessory > .top {
    background-position: -180px -288px
}

.mc-skin-viewer-9x .player > .body > .accessory > .bottom {
    background-position: -252px -288px
}

.mc-skin-viewer-9x .player > .left-arm, .mc-skin-viewer-9x .player > .right-arm {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 36px;
    height: 108px;
    margin: 0 auto;
    -webkit-transform: translateY(72px) translateX(-36px);
    -ms-transform: translateY(72px) translateX(-36px);
    transform: translateY(72px) translateX(-36px);
    background-image: none !important
}

.mc-skin-viewer-9x .player > .left-arm .front, .mc-skin-viewer-9x .player > .right-arm .front, .mc-skin-viewer-9x .player > .left-arm .back, .mc-skin-viewer-9x .player > .right-arm .back, .mc-skin-viewer-9x .player > .left-arm .left, .mc-skin-viewer-9x .player > .right-arm .left, .mc-skin-viewer-9x .player > .left-arm .right, .mc-skin-viewer-9x .player > .right-arm .right, .mc-skin-viewer-9x .player > .left-arm .top, .mc-skin-viewer-9x .player > .right-arm .top, .mc-skin-viewer-9x .player > .left-arm .bottom, .mc-skin-viewer-9x .player > .right-arm .bottom {
    width: 36px;
    height: 108px
}

.mc-skin-viewer-9x .player > .left-arm .top, .mc-skin-viewer-9x .player > .right-arm .top, .mc-skin-viewer-9x .player > .left-arm .bottom, .mc-skin-viewer-9x .player > .right-arm .bottom {
    height: 36px
}

.mc-skin-viewer-9x .player > .left-arm .front, .mc-skin-viewer-9x .player > .right-arm .front {
    background-position: -396px -180px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-arm .back, .mc-skin-viewer-9x .player > .right-arm .back {
    background-position: -468px -180px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-arm .right, .mc-skin-viewer-9x .player > .right-arm .right {
    background-position: -360px -180px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-arm .left, .mc-skin-viewer-9x .player > .right-arm .left {
    background-position: -432px -180px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-arm .top, .mc-skin-viewer-9x .player > .right-arm .top {
    background-position: -396px -144px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-arm .bottom, .mc-skin-viewer-9x .player > .right-arm .bottom {
    background-position: -432px -144px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(90px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(90px)
}

.mc-skin-viewer-9x .player > .left-arm > .accessory, .mc-skin-viewer-9x .player > .right-arm > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-3.375px);
    transform: scale3d(1.0625, 1.0625, 1.0625) translateY(-3.375px)
}

.mc-skin-viewer-9x .player > .left-arm > .accessory .front, .mc-skin-viewer-9x .player > .right-arm > .accessory .front {
    background-position: -396px -324px
}

.mc-skin-viewer-9x .player > .left-arm > .accessory .back, .mc-skin-viewer-9x .player > .right-arm > .accessory .back {
    background-position: -468px -324px
}

.mc-skin-viewer-9x .player > .left-arm > .accessory .right, .mc-skin-viewer-9x .player > .right-arm > .accessory .right {
    background-position: -360px -324px
}

.mc-skin-viewer-9x .player > .left-arm > .accessory .left, .mc-skin-viewer-9x .player > .right-arm > .accessory .left {
    background-position: -432px -324px
}

.mc-skin-viewer-9x .player > .left-arm > .accessory .top, .mc-skin-viewer-9x .player > .right-arm > .accessory .top {
    background-position: -396px -288px
}

.mc-skin-viewer-9x .player > .left-arm > .accessory .bottom, .mc-skin-viewer-9x .player > .right-arm > .accessory .bottom {
    background-position: -432px -288px
}

.mc-skin-viewer-9x .player > .right-arm {
    -webkit-transform: translateY(72px) translateX(72px) scaleX(-1);
    -ms-transform: translateY(72px) translateX(72px) scaleX(-1);
    transform: translateY(72px) translateX(72px) scaleX(-1)
}

.mc-skin-viewer-9x .player > .left-leg, .mc-skin-viewer-9x .player > .right-leg {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 36px;
    height: 108px;
    margin: 0 auto;
    -webkit-transform: translateY(180px);
    -ms-transform: translateY(180px);
    transform: translateY(180px);
    background-image: none !important
}

.mc-skin-viewer-9x .player > .left-leg .front, .mc-skin-viewer-9x .player > .right-leg .front, .mc-skin-viewer-9x .player > .left-leg .back, .mc-skin-viewer-9x .player > .right-leg .back, .mc-skin-viewer-9x .player > .left-leg .left, .mc-skin-viewer-9x .player > .right-leg .left, .mc-skin-viewer-9x .player > .left-leg .right, .mc-skin-viewer-9x .player > .right-leg .right, .mc-skin-viewer-9x .player > .left-leg .top, .mc-skin-viewer-9x .player > .right-leg .top, .mc-skin-viewer-9x .player > .left-leg .bottom, .mc-skin-viewer-9x .player > .right-leg .bottom {
    width: 36px;
    height: 108px
}

.mc-skin-viewer-9x .player > .left-leg .top, .mc-skin-viewer-9x .player > .right-leg .top, .mc-skin-viewer-9x .player > .left-leg .bottom, .mc-skin-viewer-9x .player > .right-leg .bottom {
    height: 36px
}

.mc-skin-viewer-9x .player > .left-leg .front, .mc-skin-viewer-9x .player > .right-leg .front {
    background-position: -36px -180px;
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-leg .back, .mc-skin-viewer-9x .player > .right-leg .back {
    background-position: -108px -180px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(180deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-leg .right, .mc-skin-viewer-9x .player > .right-leg .right {
    background-position: 0 -180px;
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-leg .left, .mc-skin-viewer-9x .player > .right-leg .left {
    background-position: -72px -180px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-leg .top, .mc-skin-viewer-9x .player > .right-leg .top {
    background-position: -36px -144px;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x .player > .left-leg .bottom, .mc-skin-viewer-9x .player > .right-leg .bottom {
    background-position: -72px -144px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(90px);
    transform: rotateX(270deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(90px)
}

.mc-skin-viewer-9x .player > .left-leg > .accessory, .mc-skin-viewer-9x .player > .right-leg > .accessory {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-1.6875px);
    transform: scale3d(1.03125, 1.03125, 1.03125) translateY(-1.6875px)
}

.mc-skin-viewer-9x .player > .left-leg > .accessory .front, .mc-skin-viewer-9x .player > .right-leg > .accessory .front {
    background-position: -36px -324px
}

.mc-skin-viewer-9x .player > .left-leg > .accessory .back, .mc-skin-viewer-9x .player > .right-leg > .accessory .back {
    background-position: -108px -324px
}

.mc-skin-viewer-9x .player > .left-leg > .accessory .right, .mc-skin-viewer-9x .player > .right-leg > .accessory .right {
    background-position: 0 -324px
}

.mc-skin-viewer-9x .player > .left-leg > .accessory .left, .mc-skin-viewer-9x .player > .right-leg > .accessory .left {
    background-position: -72px -324px
}

.mc-skin-viewer-9x .player > .left-leg > .accessory .top, .mc-skin-viewer-9x .player > .right-leg > .accessory .top {
    background-position: -36px -288px
}

.mc-skin-viewer-9x .player > .left-leg > .accessory .bottom, .mc-skin-viewer-9x .player > .right-leg > .accessory .bottom {
    background-position: -72px -288px
}

.mc-skin-viewer-9x .player > .right-leg {
    -webkit-transform: translateY(180px) translateX(36px) scaleX(-1);
    -ms-transform: translateY(180px) translateX(36px) scaleX(-1);
    transform: translateY(180px) translateX(36px) scaleX(-1)
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm {
    -webkit-transform: translateY(72px) translateX(72px);
    -ms-transform: translateY(72px) translateX(72px);
    transform: translateY(72px) translateX(72px)
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm .front {
    background-position: -324px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm .back {
    background-position: -396px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm .right {
    background-position: -288px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm .left {
    background-position: -360px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm .top {
    background-position: -324px -432px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm .bottom {
    background-position: -360px -432px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm > .accessory .front {
    background-position: -468px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm > .accessory .back {
    background-position: -540px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm > .accessory .right {
    background-position: -432px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm > .accessory .left {
    background-position: -504px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm > .accessory .top {
    background-position: -468px -432px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-arm > .accessory .bottom {
    background-position: -504px -432px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg {
    -webkit-transform: translateY(180px) translateX(36px);
    -ms-transform: translateY(180px) translateX(36px);
    transform: translateY(180px) translateX(36px)
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg .front {
    background-position: -180px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg .back {
    background-position: -252px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg .right {
    background-position: -144px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg .left {
    background-position: -216px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg .top {
    background-position: -180px -432px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg .bottom {
    background-position: -216px -432px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg > .accessory .front {
    background-position: -36px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg > .accessory .back {
    background-position: -108px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg > .accessory .right {
    background-position: 0 -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg > .accessory .left {
    background-position: -72px -468px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg > .accessory .top {
    background-position: -36px -432px
}

.mc-skin-viewer-9x:not(.legacy) .player > .right-leg > .accessory .bottom {
    background-position: -72px -432px
}

.mc-skin-viewer-9x.slim .player > .left-arm .front, .mc-skin-viewer-9x.slim .player > .right-arm .front, .mc-skin-viewer-9x.slim .player > .left-arm .back, .mc-skin-viewer-9x.slim .player > .right-arm .back, .mc-skin-viewer-9x.slim .player > .left-arm .top, .mc-skin-viewer-9x.slim .player > .right-arm .top, .mc-skin-viewer-9x.slim .player > .left-arm .bottom, .mc-skin-viewer-9x.slim .player > .right-arm .bottom {
    width: 27px
}

.mc-skin-viewer-9x.slim .player > .left-arm .front {
    -webkit-transform: rotateX(0deg) rotateY(0deg) translateX(9px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(0deg) translateX(9px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x.slim .player > .left-arm .back {
    background-position: -459px -180px;
    -webkit-transform: rotateX(0deg) rotateY(180deg) translateX(-9px) translateY(0px) translateZ(18px);
    transform: rotateX(0deg) rotateY(180deg) translateX(-9px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x.slim .player > .left-arm .right {
    -webkit-transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(9px);
    transform: rotateX(0deg) rotateY(270deg) translateX(0px) translateY(0px) translateZ(9px)
}

.mc-skin-viewer-9x.slim .player > .left-arm .left {
    background-position: -423px -180px
}

.mc-skin-viewer-9x.slim .player > .left-arm .top {
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(9px) translateY(0px) translateZ(18px);
    transform: rotateX(90deg) rotateY(0deg) translateX(9px) translateY(0px) translateZ(18px)
}

.mc-skin-viewer-9x.slim .player > .left-arm .bottom {
    background-position: -423px -144px;
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateX(9px) translateY(0px) translateZ(90px);
    transform: rotateX(270deg) rotateY(0deg) translateX(9px) translateY(0px) translateZ(90px)
}

.mc-skin-viewer-9x.slim .player > .left-arm > .accessory .back {
    background-position: -459px -324px
}

.mc-skin-viewer-9x.slim .player > .left-arm > .accessory .left {
    background-position: -423px -324px
}

.mc-skin-viewer-9x.slim .player > .left-arm > .accessory .bottom {
    background-position: -423px -288px
}

.mc-skin-viewer-9x.slim .player > .right-arm .back {
    background-position: -387px -468px
}

.mc-skin-viewer-9x.slim .player > .right-arm .left {
    background-position: -351px -468px;
    -webkit-transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(9px);
    transform: rotateX(0deg) rotateY(90deg) translateX(0px) translateY(0px) translateZ(9px)
}

.mc-skin-viewer-9x.slim .player > .right-arm .bottom {
    background-position: -351px -432px
}

.mc-skin-viewer-9x.slim .player > .right-arm > .accessory .back {
    background-position: -531px -468px
}

.mc-skin-viewer-9x.slim .player > .right-arm > .accessory .left {
    background-position: -495px -468px
}

.mc-skin-viewer-9x.slim .player > .right-arm > .accessory .bottom {
    background-position: -495px -432px
}

.mc-skin-viewer-9x.slim.legacy .player > .right-arm {
    -webkit-transform: translateY(72px) translateX(63px) scaleX(-1);
    -ms-transform: translateY(72px) translateX(63px) scaleX(-1);
    transform: translateY(72px) translateX(63px) scaleX(-1)
}

.mc-skin-viewer-9x.slim.legacy .player > .right-arm .back {
    background-position: -459px -180px
}

.mc-skin-viewer-9x.slim.legacy .player > .right-arm > .accessory .back {
    background-position: -459px -324px
}

.mc-skin-viewer-9x .player .cape {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(180deg) translateX(-9px) translateZ(22.5px) translateY(67.5px) rotateX(5deg);
    transform: rotateY(180deg) translateX(-9px) translateZ(22.5px) translateY(67.5px) rotateX(5deg)
}

.mc-skin-viewer-9x .player .cape > .front, .mc-skin-viewer-9x .player .cape > .back, .mc-skin-viewer-9x .player .cape > .left, .mc-skin-viewer-9x .player .cape > .right, .mc-skin-viewer-9x .player .cape > .top, .mc-skin-viewer-9x .player .cape > .bottom {
    position: absolute;
    background-image: inherit !important;
    background-size: 576px 288px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mc-skin-viewer-9x .player .cape > .front, .mc-skin-viewer-9x .player .cape > .back {
    width: 90px;
    height: 135px
}

.mc-skin-viewer-9x .player .cape > .left, .mc-skin-viewer-9x .player .cape > .right {
    width: 9px;
    height: 135px
}

.mc-skin-viewer-9x .player .cape > .top, .mc-skin-viewer-9x .player .cape > .bottom {
    width: 90px;
    height: 9px
}

.mc-skin-viewer-9x .player .cape > .front {
    background-position: -9px -9px;
    -webkit-transform: translateZ(4.5px);
    transform: translateZ(4.5px)
}

.mc-skin-viewer-9x .player .cape > .back {
    background-position: -108px -9px;
    -webkit-transform: translateZ(-4.5px) rotateY(180deg);
    transform: translateZ(-4.5px) rotateY(180deg)
}

.mc-skin-viewer-9x .player .cape > .left {
    background-position: 0 -9px;
    -webkit-transform: rotateY(270deg) translateZ(4.5px);
    transform: rotateY(270deg) translateZ(4.5px)
}

.mc-skin-viewer-9x .player .cape > .right {
    background-position: -99px -9px;
    -webkit-transform: rotateY(-270deg) translateZ(85.5px);
    transform: rotateY(-270deg) translateZ(85.5px)
}

.mc-skin-viewer-9x .player .cape > .top {
    background-position: -9px 0;
    -webkit-transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4.5px);
    transform: rotateX(90deg) rotateY(0deg) translateX(0px) translateY(0px) translateZ(4.5px)
}

.mc-skin-viewer-9x .player .cape > .bottom {
    background-position: -99px 0;
    -webkit-transform: rotateX(-90deg) translateZ(130.5px);
    transform: rotateX(-90deg) translateZ(130.5px)
}

.mc-skin-viewer-9x.legacy-cape > .player .cape > .front, .mc-skin-viewer-9x.legacy-cape > .player .cape > .back, .mc-skin-viewer-9x.legacy-cape > .player .cape > .left, .mc-skin-viewer-9x.legacy-cape > .player .cape > .right, .mc-skin-viewer-9x.legacy-cape > .player .cape > .top, .mc-skin-viewer-9x.legacy-cape > .player .cape > .bottom {
    background-size: 198px 153px
}

</style>
