<template>

    <div class="f-r"
         :class="{'moreSpaceToFinancesRow': frames['frameBalanceInBalanceHistory']}">
        <div class="r-field">
            <div class="balance-row">
                <!-- Название блока -->

                <div
                    class="acc-balance"
                    v-if="frames['frameBalanceInTopBalance']">
                    Баланс аккаунта {{ $root.getPlayerData().balance }} ₽
                </div>

                <div
                    class="acc-balance"
                    v-if="frames['frameBalanceInBalanceHistory']">
                    История баланса
                </div>

                <div
                    class="acc-balance"
                    v-if="frames['frameBalanceInSendMoney']">
                    Перевод денежных средств
                </div>

                <div
                    class="acc-balance"
                    v-if="frames['frameBalanceDonate']">
                    Пополнение счёта (донат)
                </div>

                <div class="acc-balance-blank"
                     v-if="!frames['frameBalanceInTopBalance'] && !frames['frameBalanceInBalanceHistory']"/>

                <div class="btn-block">
                    <div
                        :class="{'main-btn': frames.frameBalanceInTopBalance, 'btn_inactive': !frames.frameBalanceInTopBalance}"
                        @click="switchSettingsFrame('frameBalanceInTopBalance')">
                        Топ игроков
                    </div>

                    <div
                        :class="{'main-btn': frames.frameBalanceInSendMoney, 'btn_inactive': !frames.frameBalanceInSendMoney}"
                        @click="switchSettingsFrame('frameBalanceInSendMoney')">
                        Перевод
                    </div>

                    <div
                        :class="{'main-btn': frames.frameBalanceInBalanceHistory, 'btn_inactive': !frames.frameBalanceInBalanceHistory}"
                        @click="switchSettingsFrame('frameBalanceInBalanceHistory')">
                        История баланса
                    </div>

                    <div
                        class="main-btn"
                        @click="TopUpWalletOpenModal">
                        Пополнить
                    </div>
                </div>
            </div>
            <div class="sm-border"
                 v-if="frames['frameBalanceInTopBalance'] || frames['frameBalanceInSendMoney'] || frames['frameBalanceDonate']"></div>
            <div class="bal-top"
                 v-if="frames['frameBalanceInTopBalance']">
                <div
                    class="bal-top-tc-wrapper">
                    <div
                        class="top-circle"
                        v-for="user in $root.getPlayerData().baltop"
                        :class="{'top-circle-you': user.name === $root.getPlayerData().name }">
                        <div
                            class="top-circle-place">
                            {{ user.position }}
                        </div>
                        <div
                            class="lk__accountBlock_userActions_frame_row_field_balanceTopRow_topCircle_name">
                            {{ user.name }}
                        </div>
                        <div
                            class="top-circle-balance">
                            {{ user.balance }} ₽
                        </div>
                    </div>
                </div>
                <div class="bal-top-text">
                    Ваше
                    место в рейтинге самых богатых игроков
                </div>
                <div class="bal-top-border"/>
                <div class="bal-top-text2">
                    Как
                    заработать?
                </div>
                <div class="to-earn-btns-block">
                    <div class="to-earn-btn"
                         @click="$root.$emit('chLkTab', 'referals')">
                        Рефералка
                    </div>
                    <div
                        class="to-earn-btn"
                        @click="$root.$emit('chLkTab', 'votes')">
                        Голосуй!
                    </div>
                    <div
                        class="to-earn-btn"
                        @click="TopUpWalletOpenModal">
                        Донат
                    </div>
                </div>
            </div>

            <div class="flexible-block" style=""
                 v-if="frames['frameBalanceInBalanceHistory']">

                <div class="balanceHistoryPreloader" v-if="financeOperations === null"/>
                <div class="lk-border" v-if="financeOperations !== null && financeOperations.length === 0"></div>

                <div class="balanceHistoryEmpty" v-if="financeOperations !== null && financeOperations.length === 0">

                    <span style="padding: 87px 0;">Ваша история операций пуста.</span>
                </div>

                <div v-if="financeOperations !== null && financeOperations.length !== 0">

                    <table class="tb">
                        <colgroup>
                            <col/>
                            <col/>
                            <col/>
                            <col/>
                        </colgroup>

                        <thead style="margin-bottom: 10px;border-top: 1px solid rgba(128, 128, 128, 0.29);">
                        <tr>
                            <th class="info-table-th">Дата операции</th>
                            <th class="info-table-th">Комментарий</th>
                            <th class="info-table-th title-th-smaller">
                                Δ
                            </th>
                            <th class="info-table-th">Итоговый баланс</th>

                        </tr>
                        </thead>

                        <tbody style="margin-top: 10px;">
                        <tr v-for="operation in financeOperations">
                            <th class="info-table-td">
                                {{ datetimeNormalizer(operation.created_at) }}
                            </th>
                            <th class="info-table-td">
                                {{ operation.data.data }}
                            </th>
                            <th class="info-table-td"
                                :class="{'red': operation.data.change < 0 , 'green': operation.data.change > 0 }">
                                {{ operation.data.change }}
                            </th>
                            <th class="info-table-td">
                                {{ operation.data.new }}
                            </th>
                        </tr>
                        </tbody>
                    </table>

                    <div
                        class="info-table-paginator"
                        v-if="pagesCount !== null">
                        <ul>
                            <li v-for="pageNumber in pagesCount"
                                v-if="Math.abs(pageNumber - currentPage) < 3 || pageNumber === pagesCount || pageNumber === 1">
                                                    <span @click="setFinanceOperationsPage(pageNumber)"
                                                          :class="{current: currentPage === pageNumber,
                                                        last: (pageNumber === pagesCount && Math.abs(pageNumber - currentPage) > 3),
                                                         first:(pageNumber === 1 && Math.abs(pageNumber - currentPage) > 3)}">{{
                                                            pageNumber
                                                        }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="send-money"
                 v-if="frames['frameBalanceInSendMoney']">
                <div class="s-m-left-block">

                    <div class="s-m-text">
                        <span style="font-size: 1.2em;">Будьте внимательны при переводе валюты.</span> <br>Администрация
                        или персонал проекта, не имеет права
                        просить у вас валюту. Всегда обращайте внимание на https подключение, а так же проверяйте ссылку
                        ~ greatray.ru
                    </div>
                    <input type="text" placeholder="Кому переводим?"
                           class="s-m-input"
                           v-model="sendMoneyNickname" @keyup="liveSearchPlayer(sendMoneyNickname)">
                    <input type="text" @keypress="onlyNumber" placeholder="Сумма перевода"
                           class="s-m-input"
                           v-model="sendMoneyCost">
                    <input type="text" placeholder="Комментарий"
                           class="s-m-input"
                           v-model="sendMoneyComment">

                    <div class="s-m-btn"
                         style=""
                         @click="sendMoneyConfirmModal" v-if="sendMoneyNickname !== '' && sendMoneyCost !== null">
                        Перевести
                    </div>

                    <div class="s-m-btn-incative" style="padding: 4px 15px"
                         v-else>
                        Перевести
                    </div>

                </div>
                <div class="s-m-right-block">
                    <div class="s-m-right-block-img">
                        <div v-for="name in liveSearchList" @click="sendMoneyNickname = name; liveSearchList = [name]">
                            <span class="s-m-live-search-name">{{ name }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>


</template>

<script>
import TopUpAccountWalletModal from "../../../modals/TopUpAccountWalletModal";
import confirmModal from "../../../modals/ConfirmModal";

import {TextInputControlMixin} from "../../../../mixins/TextInputControlMixin";
import {PreloaderMixin} from "../../../../mixins/PreloaderMixin";
import {TimeFormatterMixin} from "../../../../mixins/TimeFormatterMixin";
import {PlayerLiveSearchMixin} from "../../../../mixins/PlayerLiveSearchMixin";

export default {
    name: "LkFinances",
    mixins: [TextInputControlMixin, PreloaderMixin, TimeFormatterMixin, PlayerLiveSearchMixin],

    data() {
        return {
            financeOperations: null,

            sendMoneyNickname: "",
            sendMoneyComment: "",
            sendMoneyCost: null,

            currentPage: 1,
            pagesCount: null,

            in_work: false,
            switchTimeout: 0,

            donateSum: 100,

            frames: {
                frameBalanceInTopBalance: true,
                frameBalanceInBalanceHistory: false,
                frameBalanceInSendMoney: false,
                frameBalanceInTopUpBalance: false,
                frameBalanceDonate: false,
            },
        }
    },
    methods: {

        // Этот метод ни в коем случае нельзя менять на тот что в примеси!
        switchSettingsFrame(frame) {
            if (!this.frames[frame] && !this.in_work) {
                this.in_work = true
                for (let key in this.frames) {
                    this.frames[key] = false
                }
                let self = this
                setTimeout(function () {
                    self.frames[frame] = true
                }, this.switchTimeout)
                setTimeout(function () {
                    self.in_work = false
                    self.doAfterSwitchFrame()
                }, this.switchTimeout)
            }
        },

        doAfterSwitchFrame() {
            if (this.frames['frameBalanceInBalanceHistory']) {
                this.financeOperations === null && this.preloaderStartLk('.balanceHistoryPreloader')
                this.getFinanceOperations(this.currentPage)
            }
        },

        sendMoneyConfirmModal() {
            this.$modal.show(confirmModal, {
                'operation': 'moneySend',
                'data': {
                    'sendMoneyNickname': this.sendMoneyNickname,
                    'sendMoneyCost': this.sendMoneyCost,
                    'sendMoneyComment': this.sendMoneyComment
                }
            }, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "420px",
                height: "auto",
                reset: true
            })
        },

        setFinanceOperationsPage(pageNumber) {
            this.currentPage = pageNumber
            this.getFinanceOperations(this.currentPage)
        },

        getFinanceOperations(pageNumber) {
            require('axios').get(`/api/operation?page=${pageNumber}`).then(
                data => {
                    this.financeOperations === null && this.preloaderStop('.balanceHistoryPreloader')
                    this.financeOperations = data.data.data
                    this.pagesCount = data.data.last_page
                }
            ).catch(err => {
                console.log(err)
                this.financeOperations === null && this.preloaderStop('.balanceHistoryPreloader')
                this.$root.setPlayerData(null);
                this.$root.redirect('/')
            })
        },

        TopUpWalletOpenModal() {
            this.$modal.show(TopUpAccountWalletModal, {}, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "300px",
                height: "auto",
                reset: true,
            })
        },
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.f-r {
    @include frame-row();
    -webkit-user-select: none;
}

.moreSpaceToFinancesRow {
    height: auto;
}

.r-field {
    @include flexible-column-start();
    @include fill-all-block();
    background-color: $super-shitty-hex-gray;
    @include border-radius(3px);
}


.balance-row {
    @include flexible-row();
    justify-content: space-between;
    width: 100%;

}

.acc-balance {
    width: 45%;
    white-space: nowrap;
    font-size: 1.2em;
    color: $brightless-white;
    padding: 15px 20px;
}

.acc-balance-blank {
    width: 40%;
    height: 53px;
}

.btn-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    -webkit-user-select: none;
}

.btn_inactive,
.main-btn {
    @include main-button(4px, 15px);
    white-space: nowrap;
    margin-right: 10px;
}


.s-m-btn, .s-m-btn-incative {
    @include main-button(4px, 15px);
    margin-left: 4px;
}

.btn_inactive, .s-m-btn-incative {
    background-image: none;
    background-color: gray;

    &:hover {
        transform: none;
    }
}

.s-m-btn-incative {
    cursor: default;
}


.sm-border {
    @include smooth-border();
    height: 1px;
    width: 95%;
}

.bal-top {
    @include flexible-column-sp-ev();
    width: 100%;
    height: 68%;
    transition: all 1s ease;
}

.bal-top-tc-wrapper {
    @include flexible-row();
    margin-top: 30px;
}

.top-circle {
    @include flexible-column();
    width: 110px;
    height: 110px;
    @include border-radius(60px);
    border: 3px solid #8e8d8d;
    margin: 15px 10px 0 10px;
    transition: all 0.5s ease-in-out;
    font-size: 0.6em;
    color: $brightless-white;
    -webkit-user-select: none;

    &:hover {
        transform: scale(1.15);
        transition: all 0.5s ease-in-out;
        border: 3px solid #86f665;
    }
}

.top-circle-you {
    width: 150px;
    height: 150px;
    @include border-radius(80px);
    border: 3px solid #e22f2f;
    margin-top: 20px;
    font-size: 1.05em;
    color: $brightless-white;

    &:hover {
        transform: scale(1.05);
    }
}

.top-circle-place {
    font-size: 2.5em;
}

.top-circle-balance {
    font-size: 1em;
    margin-top: 3px;
}

.bal-top-text {
    font-size: 0.85em;
    background: linear-gradient(90deg, #fd1d1d 0, #fcb045 0, rgba(252, 0, 255, .8) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 10px;

    -webkit-user-select: none;
    transition: all 0.5s ease;
    animation: 10s ease infinite alternate multicolored-iridescent-gradient;
}

.bal-top-border {
    @include smooth-border();
    width: 50%;
    height: 2px;
    padding-top: 5px;
}

.bal-top-text2 {
    font-size: 0.85em;
    margin-top: 5px;
    -webkit-user-select: none;
    color: $brightless-white;
}

.to-earn-btns-block {
    @include flexible-row-sp-ev();
    width: 60%;
    margin-top: 10px;
    margin-left: 2.4%;
}

.to-earn-btn {
    @include main-button(5px, 15px);
    width: 90px;
    font-size: 0.8em;
    padding: 4px 10px;
    margin-right: 20px;
    white-space: nowrap;

    &:hover {
        transition: all 0.5s ease-in-out;
        transform: scale(1.05);
        color: $not-bright-white;
    }
}

.tb {
    flex-direction: column;
    border-spacing: 0;
    width: 100%;
    padding-top: 20px;
    border-collapse: collapse;
    border: 1px solid rgba(128, 128, 128, 0.29);
    border-top: none;
    -webkit-user-select: text;
}

@include info-table-with-paginator();

.info-table-td {
    font-size: 0.75em;
    @include text-normalize();
    color: $smooth-description-text;
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.29);
}

.title-th-smaller {
    width: 100px;
}

.green {
    color: #00ff00;
}

.red {
    color: #e73434;
}

.send-money {
    @include flexible-row();
    @include fill-all-block();
}

.s-m-left-block {
    @include flexible-column();
    width: 45%;
    height: 95%;
}

.s-m-text {
    width: 100%;
    color: $brightless-white;
    font-size: 0.80em;
    text-align: center;
}

.s-m-input {
    width: 80%;
    height: 32px;
    border-radius: 10px;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
    padding-left: 5px;
    text-align: center;
    margin: 10px 0;
    font-family: $main-font;
    font-size: 0.8em;
}

.s-m-right-block {
    @include flexible();
    width: 45%;
    height: 95%;
}

.s-m-right-block-img {
    width: 95%;
    height: 95%;
    background-image: url(../LkAssets/stronghold.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
}

.s-m-live-search-name {
    border-radius: 10px;
    display: inline-block;
    padding: 6px;
    margin: 2px;
    background-color: rgb(89, 51, 194);
    color: white;
    cursor: pointer;
}


.balanceHistoryPreloader {
    padding: 100px 100px;
}

.balanceHistoryEmpty {
    @include flexible-column();
    width: 100%;
    color: $brightless-white;
    font-size: 1.1em;
}

.lk-border {
    width: 95%;
    height: 1px;
    border-top: 1px solid rgba(128, 128, 128, 0.29);
}

.flexible-block {
    @include flexible-column();
    width: 100%;
}
</style>