<template>
    <div class="TopUp_window">
        <div class="topUp_Text">Сколько зачисляем? </div>
        <input type="text" class="m-input" style="margin-top: 10px" placeholder="<-->" @keypress="onlyNumber" v-model="donateSum">
        <div class="btn" style="margin-top: 10px" v-if="donateSum !== null && donateSum !== '' " @click="donateEnot">
            Перейти к оплате
        </div>
        <div class="btn-inactive" style="margin-top: 10px" v-else>Перейти к оплате</div>
    </div>
</template>

<script>

import {TextInputControlMixin} from "../../mixins/TextInputControlMixin";

export default {
    mixins: [TextInputControlMixin],
    name: "TopUpAccountWalletModal",

    data() {
        return {
            donateSum: null,
        }
    },

    methods: {
        donateEnot() {
            require('axios').post(`/api/donate/enot`, {
                sum: this.donateSum
            }).then(data => {
                window.location.href = data.data.url;
            }).catch(err => {
                console.log(err)
            })
        },

    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

.TopUp_window, btn, btn_inactive {
    @include flexible();
}


.TopUp_window {
    flex-direction: column;
    padding: 20px 0px;
    border-radius: 7px;
    background-color: $shitty-hex-gray;
    border: 1px solid rgba(128, 128, 128, 0.13);
    font-family: $main-font;
    color: $not-bright-white;
}

.topUp_Text {
    font-size: 1em;
    background: linear-gradient(90deg, #fd1d1d 0, #fcb045 0, rgba(252, 0, 255, .8) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-user-select: none;
    transition: all 0.5s ease;
    animation: 10s ease infinite alternate multicolored-iridescent-gradient;
}

.m-input {
    width: 40%;
    height: 28px;
    border-radius: 3px;
    margin: 5px 0;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
    text-align: center;
    font-family: AventirN;
    font-size: 0.9em;
}

.btn, .btn-inactive {
   @include main-button(4px, 15px)
}

.btn:hover {
    transition: all 0.5s ease-in-out;
    transform: scale(1.02);
    color: white;
}

.btn-inactive {
    background-image: none;
    background-color: gray;
    cursor: default;
}
.btn-inactive:hover {
    transform: none;
}


</style>