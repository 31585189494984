import { render, staticRenderFns } from "./LkShop.vue?vue&type=template&id=d2d16816&scoped=true&"
import script from "./LkShop.vue?vue&type=script&lang=js&"
export * from "./LkShop.vue?vue&type=script&lang=js&"
import style0 from "./LkShop.vue?vue&type=style&index=0&id=d2d16816&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2d16816",
  null
  
)

export default component.exports