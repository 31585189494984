<template>

</template>

<script>
export default {
    name: "Lk2FA_Auth"
}
</script>

<style scoped>

</style>