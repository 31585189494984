<template>
    <div class="buy-status">
        <div class="block" v-if="!confirm">
            <div class="confirm">
                <div class="commodity"
                     :style="`background: #242424 url(${item.icon}) center no-repeat`">
                </div>
                <div class="cf-text">
                    {{ item.description }}
                </div>

                <div class="c-sm-border">
                    <div class="sm-border"/>
                </div>
                <div class="cf-text">
                    <b v-show="item.end != null" style="color:orangered">Текущая цена действует до
                        {{ datetimeNormalizer(item.end) }} !</b>
                </div>
                <div class="cf-text">
                    {{ item.amount }} шт. - ₽{{ item.price }}
                </div>

                <div class="cf-text">
                    <input type="text" @keypress="onlyNumberNatural" placeholder="Количество"
                           class="s-m-input"
                           v-model="amount">
                </div>

                <div class="cf-text">
                    <span v-show="amount % item.amount === 0 && amount > 0">
                        Итоговая цена: <span style="color: #14d2bc">₽{{ item.price * amount / item.amount }}</span>
                    </span>
                    <span v-show="amount % item.amount !== 0 && amount > 0" style="color: #d27614">
                        Пожалуйста, укажите количество, кратное <span style="color: #e8481f">{{ item.amount }}</span>
                    </span>
                    <span v-show="amount <= 0" style="color: #d27614">
                        Пожалуйста, укажите количество больше 0
                    </span>
                </div>


                <div class="cf-btn-block">
                    <div class="cf-btn" @click="buyItem()">Купить</div>
                </div>
            </div>
        </div>

        <div class="block" v-if="confirm" style="height: 140px">
            <div v-if="response_status === 200">
                <div class="cf-text">
                    Вы успешно приобрели товар!
                    <br><br>
                    Сейчас он лежит в корзине.
                    <br>
                    Получить его можно на сервере командой /shop
                </div>
                <div class="c-sm-border">
                    <div class="sm-border"/>
                </div>
                <div class="cf-btn-block">
                    <div class="cf-btn" @click="$modal.hideAll()">Отлично!</div>
                </div>

            </div>
            <div v-else>
                <div class="cf-text" v-for="error in response_errors">
                    {{ error }}
                </div>
                <div class="c-sm-border">
                    <div class="sm-border"/>
                </div>
                <div class="cf-btn-block">
                    <div class="cf-btn" @click="$modal.hideAll()">Я понял</div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import {TextInputControlMixin} from "../../mixins/TextInputControlMixin";
import {PreloaderMixin} from "../../mixins/PreloaderMixin";
import {ModalsCommonMixin} from "../../mixins/ModalsCommonMixin";
import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";

export default {
    name: "CommodityBuyModal",
    mixins: [PreloaderMixin, ModalsCommonMixin, TextInputControlMixin, TimeFormatterMixin],
    props: ["item"],

    data() {
        return {
            confirm: false,
            amount: 1,
            response_status: null,
            response_errors: null
        }
    },

    methods: {
        buyItem() {
            if(this.amount % this.item.amount !== 0)
                return
            this.confirm = true
            this.preloaderStartModal('.buy-status')

            require('axios').post('/api/donate/shop', {
                "amount": this.amount / this.item.amount,
                "id": this.item.id,
            }).then(data => {
                this.response_status = data.status;
                this.$root.update_PlayerData();

                this.preloaderStop('.buy-status')
            }).catch(err => {
                this.preloaderStop('.buy-status')

                this.response_status = err.response.status;
                try {
                    this.response_errors = err.response.data.errors._all;
                } catch (e) {
                    this.response_errors = ['Внутренняя ошибка сервера, попробуйте позже']
                }
            });
        },
    },

}
</script>

<style scoped lang="scss">

@import "src/assets/scss/vars";
@import "src/assets/scss/mixins";
@import "src/assets/scss/animations";

.buy-status {
    @include flex-column();
    padding: 18px 35px;
    border-radius: 5px;
    background-color: $shitty-hex-gray;
    border: 1px solid rgba(128, 128, 128, 0.13);
    font-family: $main-font;
}

.block {
    @include flexible-column();
    margin-top: 0;

    .cf-text {
        @include modals-text-blx();
        width: 540px;
    }
}

.cf-btn-block {
    @include flexible-row-sp-ev();
    margin-top: 15px;
    font-weight: 600;
}

button,
button:active,
button:focus {
    outline: none;
}

.cf-btn, .rs-btn {
    @include main-button(4px, 30px)
}

.cf-btn:hover {
    color: #00ff00;
}

.rs-btn:hover {
    color: #e7311f;
}

.c-sm-border {
    margin: 10px 0;
    width: 100%;
    height: 1px;
    @include flexible();

    .sm-border {
        width: 85%;
        height: 1px;
        @include smooth-border();
    }
}

.commodity {
    display: block;
    width: 64px;
    height: 64px;
    margin: 5px auto;
    padding: 5px;
    border-radius: 5px;
}

.s-m-input {
    width: 100px;
    height: 32px;
    border-radius: 10px;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
    padding-left: 5px;
    text-align: center;
    margin: 10px auto;
    font-family: $main-font;
    font-size: 0.8em;
}

</style>
