<template>
    <div class="u-r-f-r">
        <div class="u-r-f-r-f">
            <div class="u-c-frame-r-f-tb">
                <div class="frame-title">Управление аккаунтом</div>

                <div class="lk-control-menu">
                    <div :class="{'lk__Control_menu_btn': frames.changePwd, 'btn_inactive': !frames.changePwd}"
                         @click="changeFrameCustom('changePwd', frames)">Сменить пароль
                    </div>
                    <div :class="{'lk__Control_menu_btn': frames.changeEmail, 'btn_inactive': !frames.changeEmail}"
                         @click="changeFrameCustom('changeEmail', frames)">Сменить Email
                    </div>
                    <div :class="{'lk__Control_menu_btn': frames.twofa, 'btn_inactive': !frames.twofa, 'unclicked': twoFaUnckicked}">2FA Auth
                    </div>

                </div>
            </div>

            <div class="u-r-f-r-f-flex-c-w">
                <div class="lk-border"/>

                <div class="lk-control-block" v-if="frames.changePwd">
                    <LkChangePwd/>
                </div>
                <div class="lk-control-block" v-if="frames.changeEmail">
                    <LkChangeEmail/>
                </div>
                <div class="lk-control-block" v-if="frames.twofa">
                    <Lk2FA_Auth/>
                </div>

            </div>

        </div>


    </div>
</template>

<script>
import LkChangePwd from "./LkControl/LkChangePwd";
import LkChangeEmail from "./LkControl/LkChangeEmail";
import Lk2FA_Auth from "./LkControl/Lk2FA_Auth";

import {ChangeTabMixin} from "../../../mixins/ChangeTabMixin";

export default {
    name: "LkControl",
    mixins: [ChangeTabMixin],
    components: {LkChangePwd, Lk2FA_Auth, LkChangeEmail},
    data() {
        return {
            twoFaUnckicked: true,

            frames: {
                'changePwd': true,
                'changeEmail': false,
                'twofa': false,
            }
        }
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

@include l-c-r();

.u-c-frame-r-f-tb {
    @include flexible-row-sp-bt();
    width: 100%;
}

.lk-control-menu {
    @include flexible-row-sp-ev();
    margin-right: 15px;
}

.lk__Control_menu_btn, .btn_inactive {
    @include main-button(4px, 15px);
    margin-left: 15px;
    -webkit-user-select: none;
}

.btn_inactive {
    background-image: none;
    background-color: gray;
}

.lk-control-block {
    @include flexible();
}

.unclicked {
    cursor: default;
    &:hover {
        transform: none;
    }
}


</style>