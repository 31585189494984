var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setViewPosition legacy cape",class:{'mc-skin-viewer-7x': _vm.frame === 'lkSkinFront' || _vm.frame === 'lkSkinBack',
            'setViewPositionFront': _vm.frame === 'lkSkinFront',
            'setViewPositionBack': _vm.frame === 'lkSkinBack',
            'mc-skin-viewer-2x': _vm.frame ==='mainMenuSkin',
            'mc-skin-viewer-4x': _vm.frame === 'accountsSkin',
            'spin': _vm.spinSkin},style:(_vm.userStyle),attrs:{"id":"skin-viewer"},on:{"mouseenter":_vm.spinIt,"mouseleave":_vm.stopIt}},[_c('div',{staticClass:"player",class:{'mgnBot': _vm.frame === 'mainMenuSkin', 'headOnly': _vm.frame === 'accountsSkin', }},[_vm._m(0),(_vm.frame !== 'accountsSkin')?_c('div',{staticClass:"body"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"}),_vm._m(1)]):_vm._e(),(_vm.frame !== 'accountsSkin')?_c('div',{staticClass:"left-arm"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"}),_vm._m(2)]):_vm._e(),(_vm.frame !== 'accountsSkin')?_c('div',{staticClass:"right-arm"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"}),_vm._m(3)]):_vm._e(),(_vm.frame !== 'accountsSkin')?_c('div',{staticClass:"left-leg"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"}),_vm._m(4)]):_vm._e(),(_vm.frame !== 'accountsSkin')?_c('div',{staticClass:"right-leg"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"}),_vm._m(5)]):_vm._e(),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"}),_c('div',{staticClass:"accessory"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accessory"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accessory"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accessory"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accessory"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accessory"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cape"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"front"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"back"}),_c('div',{staticClass:"bottom"})])
}]

export { render, staticRenderFns }