<template>
    <div class="u-r-f-r">
        <div class="u-r-f-r-f">
            <div>
                <div class="frame-title">Ваши рефералы</div>
            </div>

            <div class="u-r-f-r-f-flex-c-w">
                <div class="lk-border"/>

                <div class="info-text-title">
                    Уважаемые игроки!
                </div>

                <div class="info-text">
                    Приводя новых игроков на проект по своей реферральной ссылке, вы сможете получить дополнительный
                    заработок! Заработок выражается в процентах от
                    доходов рефералом на ресурсе. <br><br>10% за донат и 1% за голосование.
                </div>

                <div class="lk-border"/>


                <div class="preloader-container" v-if="userReferals === null"/>

                <div class="to-user-text" v-if="userReferals !== null && userReferals.length === 0">У вас пока нету
                    рефералов.
                </div>

                <div class="userReferals_table" style="margin-top: 5px"
                     v-if="userReferals !== null && userReferals.length !== 0">
                    <table>
                        <tr>
                            <th>Ник игрока</th>
                            <th>Дата регистрации</th>
                            <th>Баланс реферала</th>
                            <th>Общий доход</th>
                        </tr>

                        <tr v-for="referal in userReferals">
                            <td>{{ referal.name }}</td>
                            <td>{{ datetimeNormalizer(referal.created_at) }}</td>
                            <td>{{ referal.balance }}</td>
                            <td style="color: #07ff07">{{ referal.profit }}</td>
                        </tr>

                    </table>
                </div>


            </div>
        </div>
    </div>
</template>


<script>

import {PreloaderMixin} from "../../../mixins/PreloaderMixin";
import {TimeFormatterMixin} from "../../../mixins/TimeFormatterMixin";

export default {
    name: "LkReferals",
    mixins: [PreloaderMixin, TimeFormatterMixin],

    data() {
        return {
            userReferals: null,

            preloaderContainer: '.preloader-container'
        }
    },

    methods: {
        getReferalsRequest() {
            require('axios').get('/api/refer').then(data => {
                this.userReferals = data.data
                this.preloaderStop(this.preloaderContainer)
            }).catch(err => {
                console.log(err)
            })
        }
    },

    mounted() {
        this.preloaderStartLk(this.preloaderContainer, '#1c1c1d')
        this.getReferalsRequest()

    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

@include l-c-r();

.lk-border {
    width: 95%;
    height: 1px;
    border-top: 1px solid rgba(128, 128, 128, 0.29);
}

.frame-title {
    font-size: 1.2em;
    color: #dbd8d8;
    padding: 15px 20px;
}

table {

}

tr th {
    padding: 0 20px;

    font-family: AventirN;
    color: #dbd8d8;
    font-weight: 300;
    -webkit-user-select: none;
}

tr td {
    font-size: 0.8em;
    text-align: center;
    height: 25px;
}

.preloader-container {
    padding: 70px 300px;
}

.userReferals_table {
    padding: 5px 0 20px 0;
}

.to-user-text {
    padding: 30px 20px;
    font-size: 1em;
}

@include lkInfoText;

.info-text {
    width: 80%;
}

</style>