<template>
    <div class="lk">
        <transition name="fade" mode="out-in">
            <div class="c-lk" v-if="$root.getPlayerData() !== null">
                <div class="c-d-textblock">
                    <h1 class="c-d-t-Title">Личный кабинет</h1>
                    <p class="c-info-text">
                        Личный кабинет - это специальный раздел сайта, доступный только для Вас. В своем личном кабинете
                        Вы сможете получить исчерпывающую информацию о состоянии своего аккаунта, подключенных
                        услугах, статусах и тд.
                        Для входа в Личный кабинет необходима авторизация.
                    </p>
                </div>

                <div class="lk-acc-block">

                    <LkUserSpace/>

                    <div class="u-actions">
                        <div class="u-actions-menu">
                            <div class="u-actions-m-wrapper">
                                <div class="u-actions-m-el"
                                     :class="{'active_tab': LkUserActions_tabs.actions}"
                                     @click="changeFrameCustom('actions', LkUserActions_tabs)">Действия
                                </div>
                                <div class="u-actions-m-el"
                                     :class="{'active_tab': LkUserActions_tabs.control}"
                                     @click="changeFrameCustom('control', LkUserActions_tabs)">Управление
                                </div>
                                <div class="u-actions-m-el"
                                     :class="{'active_tab': LkUserActions_tabs.shop}"
                                     @click="changeFrameCustom('shop', LkUserActions_tabs)">Магазин
                                </div>
                                <div class="u-actions-m-el"
                                     :class="{'active_tab': LkUserActions_tabs.cart}"
                                     @click="changeFrameCustom('cart', LkUserActions_tabs)">Корзина
                                </div>
                                <div class="u-actions-m-el"
                                     :class="{'active_tab': LkUserActions_tabs.referals}"
                                     @click="changeFrameCustom('referals', LkUserActions_tabs)">Рефералы
                                </div>

                                <div class="u-actions-m-el" style="background:#a13131;height: 43px;"
                                     :class="{'active_tab': LkUserActions_tabs.votes}"
                                     @click="changeFrameCustom('votes', LkUserActions_tabs)">Голосование
                                </div>
                                <div class="u-actions-m-el"
                                     :class="{'active_tab': LkUserActions_tabs.kits}"
                                     @click="changeFrameCustom('kits', LkUserActions_tabs)">Киты
                                </div>

                                <a class="u-actions-m-el" style="" href="https://plan.greatray.ru" target="_blank">Статистика</a>

                            </div>
                        </div>


                        <div class="u-actions-frame" v-if="LkUserActions_tabs.actions">
                            <LkActions/>
                        </div>

                        <div class="u-actions-frame" v-if="LkUserActions_tabs.control">
                            <LkControl/>
                        </div>
                        <div class="u-actions-frame" v-if="LkUserActions_tabs.shop">
                            <LkShop/>
                        </div>
                        <div class="u-actions-frame" v-if="LkUserActions_tabs.cart">
                            <LkCart empty_message="Ваша корзина пуста" type="cart"/>
                        </div>
                        <div class="u-actions-frame" v-if="LkUserActions_tabs.referals">
                            <LkReferals/>
                        </div>

                        <div class="u-actions-frame" v-if="LkUserActions_tabs.votes">
                            <LkVotes/>
                        </div>
                        <div class="u-actions-frame" v-show="LkUserActions_tabs.kits">
                            <LkCart empty_message="Упс, тут ничего нет..." type="kits"/>
                        </div>

                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import LkUserSpace from "../components/elements/lk/LkUserSpace";

import LkActions from "../components/elements/lk/LkActions";
import LkControl from "../components/elements/lk/LkControl";
import LkReferals from "../components/elements/lk/LkReferals";
import LkCart from "../components/elements/lk/LkCart";
import LkShop from "../components/elements/lk/LkShop";
import LkVotes from "../components/elements/lk/LkVotes";


import {LogItMixin} from "../mixins/LogItMixin";
import {ChangeTabMixin} from "../mixins/ChangeTabMixin";

export default {
    name: "lk",
    components: {LkUserSpace, LkActions, LkControl, LkCart, LkReferals, LkVotes, LkShop},

    mixins: [LogItMixin, ChangeTabMixin],

    metaInfo: {
        title: 'GreatRay',
        titleTemplate: '%s: Личный кабинет',
    },

    data() {
        return {
            tabsKey: 0,
            LkUserActions_tabs: {
                'actions': true,
                'control': false,
                'cart': false,
                'shop': false,
                'referals': false,
                'votes': false,
                'kits': false,
            }
        }
    },

    created() {
        this.$root.update_PlayerData();

        let self = this

        this.$root.$on('chLkTab', function (tabname) {
            self.changeFrameCustom(tabname, self.LkUserActions_tabs)
        })

        if (this.$root.getPlayerData() === null) {
            this.$root.redirect('/')
        }
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

input[type='file'] {
    opacity: 0;
    cursor: pointer;
}

@include ct-n-rp-transitions();

.serverName {
    color: white;
}

.c-lk {
    min-width: 900px;
    max-width: 1200px;
    min-height: 800px;
    margin: 0px auto;
    padding-top: 90px;
    padding-bottom: 80px;
}

.c-d-textblock {
    width: 95%;
    padding-left: 20px;
    -webkit-user-select: none;
}

.c-d-t-Title {
    @include text-normalize();
    font-family: $main-font;
    font-size: 2em;
    color: $not-bright-white;
}

.c-info-text {
    display: flex;
    flex-wrap: wrap;
    opacity: 0.9;
    @include text-normalize();
    @include text-1-0();
    color: rgba(138, 143, 152, 0.9);
}

.lk-acc-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    font-family: Roboto-regular;
    padding-bottom: 50px;
}


.u-actions {
    @include flexible-column();
    justify-content: space-between;
    width: 68%;
    height: 100%;
    min-width: 800px;
    padding-left: 10px;
}

.u-actions-menu {
    width: 100%;
    height: 43px;
    background-color: $shitty-hex-gray;
    border-radius: 3px;
}

.u-actions-m-wrapper {
    @include flexible-row-start();
    height: 100%;
    margin-left: 10px;
    font-family: AventirN;
}

.u-actions-m-el {
    @include actions-els();
    text-decoration: none;
}

.u-actions-frame {
    @include flex-column();
    width: 100%;
    min-width: 800px;
    height: 93.5%;
    transition: all 0.5s ease;
}


.lk-border {
    border: 1px solid rgba(128, 128, 128, 0.29);
    width: 95%;
}

@include active-el();


</style>
