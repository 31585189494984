<template>
    <div class="u-r-f-r">
        <div class="u-r-f-r-f">
            <div>
                <div class="frame-title">Голование за проект</div>
            </div>

            <div class="u-r-f-r-f-flex-c-w">
                <div class="lk-border"/>

                <div class="preloader-container" v-if="votesData === null"/>
                <div class="col-block" v-if="votesData !== null">

                    <div class="v-block" style="flex-direction: column">
                        <div class="info-text-title">
                            Уважаемые игроки!
                        </div>
                        <div class="info-text">
                            Голосование необходимо для увеличения рейтинга нашего проекта в мониторингах игровых
                            серверов. Это помогает новым игрокам выбрать именно наш проект, а вам — получить за это
                            награду.
                            Голосовать можно в трёх топах, один раз за сутки в каждом. <br><br> Голосуя регулярно вы
                            имеете
                            возможность получить 100/200/300 рублей в личный кабинет. <br>Для получения максимальной
                            награды, необходимо голосовать каждый день в течении месяца - удачи!
                        </div>
                    </div>

                    <div class="v-block">
                        <div class="vertical-button-vote-link">
                            <a id="blue" href="http://mcrate.su/project/5846" target="_blank">MC<p>Rate</p></a>
                        </div>
                        <div class="vertical-button-vote-link">
                            <a id="orange" href="https://mctop.su/servers/4037/" target="_blank">MC <p>TOP</p>
                            </a>
                        </div>
                        <div class="vertical-button-vote-link">
                            <a id="yellow" href="https://topcraft.club/servers/7071/" target="_blank"><p>TOP</p>
                                CRAFT</a>
                        </div>
                    </div>

                    <div class="lk-border" style="margin-top: 10px" v-show="lastMonthArwards.length != 0"/>
                    <div v-if="lastMonthArwards.length != 0">
                        <div class="v-block">
                            <div class="info-text-title">
                                Награды прошлого месяца
                            </div>
                        </div>

                        <div class="v-block">
                            <div class="tll-block">
                                <table style="width: 500px">
                                    <thead>
                                    <tr>
                                        <th style="width: 175px">Игрок</th>
                                        <th>Голосов</th>
                                        <th>Премия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="player in lastMonthArwards">
                                        <td >{{ player.playerName }}</td>
                                        <td>{{ player.countVotes }}</td>
                                        <td>{{ player.awards }} ₽ </td>
                                    </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>

                    </div>

                    <div class="lk-border" style="margin-top: 10px"/>

                    <div class="v-block">
                        <div class="info-text-title">
                            Прогресс вашего голосования
                        </div>
                    </div>

                    <div class="v-block" style="margin-top: -5px">
                        <div class="progress">
                            <div class="bar"
                                 :style="{'width': (votesData.goal.this['300'] > 0 ? 500*votesData.me.this/votesData.goal.this['300'] : 0) + 'px'}">
                                <div class="votes-num" v-if="votesData.me.this != 0"> {{ votesData.me.this }}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="v-block" style="padding-bottom: 10px; align-items: start">
                        <div class="t-l-block">
                            <div
                                style="display:block; width: 100%; text-align: center; margin-left: 10px; padding: 10px 0">
                                Текущий месяц
                            </div>
                            <table style="width: 200px">
                                <thead>
                                <tr>
                                    <th>Игрок</th>
                                    <th>Голосов</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="player in Object.keys(votesData.top.this)">
                                    <td>{{ player }}</td>
                                    <td>{{ votesData.top.this[player] }}</td>
                                </tr>
                                </tbody>

                            </table>
                        </div>
                        <div class="t-r-block">
                            <div
                                style="display:block; width: 100%; text-align: center; margin-left: 10px; padding: 10px 0">
                                Прошлый месяц
                            </div>

                            <table style="width: 200px">
                                <thead>
                                <tr>
                                    <th>Игрок</th>
                                    <th>Голосов</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="player in Object.keys(votesData.top.last)">
                                    <td>{{ player }}</td>
                                    <td>{{ votesData.top.last[player] }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

import {PreloaderMixin} from "../../../mixins/PreloaderMixin";

export default {
    name: "LkVotes",
    mixins: [PreloaderMixin],
    data() {
        return {
            votesData: null,
            lastMonthArwards: []
        }
    },
    methods: {
        getRating() {
            this.preloaderStartLk('.preloader-container')
            require('axios').get('/api/voters').then(data => {
                this.votesData = data.data
                this.getPreviousMonthArwards(data.data)
                this.preloaderStop('.preloader-container')
            }).catch(err => {
                console.log(err)
                this.preloaderStop('.preloader-container')
            })
        },
        getPreviousMonthArwards(arwardsData) {
            for (let player of Object.keys(arwardsData.top.last)) {
                let possibleArward = {
                    playerName: player,
                    winArward: false,
                }
                for (let arward of Object.keys(arwardsData.goal.last)) {
                    if (arwardsData.top.last[player] >= arwardsData.goal.last[arward]) {
                        possibleArward.winArward = true
                        possibleArward.awards = arward
                        possibleArward.countVotes = arwardsData.top.last[player]
                    }
                }
                possibleArward.winArward && this.lastMonthArwards.push(possibleArward)
            }
        }
    },
    mounted() {
        this.getRating()
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

@include l-c-r();

.preloader-container {
    padding: 70px 300px;
}

.u-c-frame-r-f-tb {
    @include flexible-row-sp-bt();
    width: 100%;
}

.col-block {
    @include flexible-column();
    width: 90%;
}

.progress {
    display: flex;
    width: 500px;
    height: 21px;
    border: 3px solid $not-bright-white;
    border-radius: 15px;
    margin-top: 20px;
}

.bar {
    display: flex;
    background: $not-bright-white;
    height: 21px;
    border-radius: 10px;

    -webkit-user-select: none;

    .votes-num {
        width: 100%;
        height: 100%;
        text-align: center;
        color: $most-dull-gray;
        font-size: 1em;
    }
}

.v-block {
    @include flexible-row-sp-ev();
    @include fill-all-block();

    .t-l-block, .t-r-block {
        padding: 0px 0 10px 0;
    }

    .tll-block, trr-block {
        padding: 0;
        margin-top: 5px;
        padding-bottom: 5px;
        width: auto;

        table {
            border-spacing: 0px;
            table-layout: fixed;
            margin-left: auto;
            margin-right: auto;

            text-align: center;


            tr {
                border: 1px groove grey;

                td {
                    border: 1px groove grey;
                    height: auto;
                }
            }
        }
    }


    table {
        text-align: center;

        tr {
            td {

            }

            th {
                -webkit-user-select: none;
            }
        }
    }

    @include lkInfoText;

    @include vote-btn();

    .vertical-button-vote-link {
        width: 140px;
        height: 40px;
    }
}


</style>
