<template>
    <div class="u-r-f-r">
        <div class="u-r-f-r-f">
            <div class="title-block">
                <div class="frame-title" style="display: flex;">
                    <CustomSelect v-bind:options="getServers()" default="HiTech" @send="changeServer($event)"
                                  style="font-size: 0.75em"/>

                    <div v-if="itemsByServer[ServerName] !== undefined" class="pageInfo">
                        Отображено {{ itemsByServer[ServerName].data.length }} из
                        {{ itemsByServer[ServerName].total }} товаров
                    </div>
                    <div v-else class="pageInfo">
                        Загрузка данных...
                    </div>
                </div>
            </div>
            <div class="u-r-f-r-f-flex-c-w" :key="cartKey">
                <div class="lk-border"/>
                <div class="preloader-container" v-if="itemsByServer[ServerName] === undefined"/>


                <div v-if="itemsByServer[ServerName] !== undefined" class="flexer">
                    <div class="commodity-box" :class="{'action': item.end != null}"
                         v-for="item in itemsByServer[ServerName].data"
                         v-tooltip="{content: item.end ? 'Акция! Только до\n' + datetimeNormalizer(item.end) + ' !' : null, theme:'LkToolTips-top-withPd'}">
                        <div class="item-name">{{ item.name }}</div>
                        <div class="info">
                            <div class="commodity"
                                 :style="`background: transparent url(${item.icon}) center no-repeat`">
                            </div>
                            <div class="info">{{ item.amount }} шт. - ₽{{ item.price }}</div>
                            <div class="info">
                                <div class="u-s-b-r-btn" @click="shopModal(item)">Подробнее</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CustomSelect from "@/components/elements/donat/CustomSelect";
import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import {PreloaderMixin} from "@/mixins/PreloaderMixin";
import CommodityBuyModal from "@/components/modals/CommodityBuyModal";

export default {
    name: "LkShop",
    components: {CustomSelect},
    mixins: [PreloaderMixin, TimeFormatterMixin],
    data() {
        return {
            ServerName: 'HiTech',
            itemsByServer: {},
            cartKey: 0,
            preloaderContainer: '.preloader-container',
        }
    },
    methods: {
        getServers() {
            let servers = []
            for (let i in this.$root.getPlayerData().luckperms)
                servers.push(i)
            return servers
        },
        changeServer(server) {
            this.ServerName = server
            if (server !== 'HiTech')
                requestAnimationFrame(this.getShop)
        },
        shopModal(item) {
            this.$modal.show(
                CommodityBuyModal,
                {
                    "item": item
                },
                {
                    styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none; ",
                    width: "600px",
                    height: "auto",
                    reset: true,
                }
            )
        },
        getShop() {
            if (this.itemsByServer[this.ServerName] === undefined) {
                this.preloaderStartLk(this.preloaderContainer, '#1c1c1d')
                require('axios').get(`/api/commodity/${this.ServerName}`).then(
                    data => {
                        this.preloaderStop(this.preloaderContainer)
                        this.itemsByServer[this.ServerName] = data.data
                        this.cartKey++
                    }
                ).catch(e => {
                    this.preloaderStop(this.preloaderContainer)
                    this.itemsByServer[this.ServerName] = []
                    this.cartKey++
                })
            }
        }
    },
    mounted() {
        this.getShop()
    },
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

.cartbox {
    width: 100%;
}

@include l-c-r();

.preloader-container {
    padding: 50px 300px;
}

.commodity {
    display: inline-flex;
    flex-direction: column-reverse;
    width: 64px;
    height: 64px;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    text-align: right;
    color: white;
    font-weight: bold;
    font-size: 1.5em;
}

.info {
    margin: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.commodity-box {
    display: flex;
    background: #2d2d2d;
    border-radius: 5px;
    margin: 5px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 18%;
}

.pageInfo {
    font-size: 0.8em;
    line-height: 40px;
    margin-left: 20px;
}

.u-s-b-r-btn {
    @include main-button(4px, 10px)
}

.flexer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.item-name {
    font-size: 0.9em;
    text-align: center;
    margin-top: 5px;
}

.action {
    background: #806c00;
    box-shadow: 0 -200px 100px -120px #dcc814 inset;
    animation: background 3s infinite alternate;
}

@keyframes background {
    50% {
        background: #7d0707;
        box-shadow: 0 -200px 100px -100px #cd3232 inset;
    }
}
</style>