<template>
    <div class="emailChangeBlock">
        <div class="infoText" style="padding: 10px 0"><span
            style="font-size: 1.3em">Будьте осторожны при смене пароля.</span><br> Администрация и персонал проекта
            никогда не будет спрашивать ваш пароль. <br>
            Всегда обращайте внимание на https подключение, а так же проверяйте ссылку ~ greatray.ru
        </div>

        <div class="fbwrappa">
            <div class="flexInputsBox">
                <input type="password" class="LkControlInput" placeholder="Старый пароль" v-model="oldPassword" autocomplete="off">
                <div class="infoText" style="color: red" v-if="">{{ errors.oldPassword }}</div>
                <input type="password" class="LkControlInput" placeholder="Новый пароль" v-model="newPassword" autocomplete="off">
                <div class="infoText" style="color: red">{{ errors.newPassword }}</div>
                <input type="password" class="LkControlInput" placeholder="Повторите пароль"
                       v-model="newPasswordConfirm" autocomplete="off">
                <div class="infoText" style="color: red">{{ errors.newPasswordConfirm }}</div>
            </div>

            <div class="cf-btn" style="margin: 5px 0 10px 0" @click="openConfirmModal">Меняем?</div>

        </div>
    </div>
</template>


<script>

import confirmModal from "../../../modals/ConfirmModal";

export default {
    name: "LkChangePwd",

    data() {
        return {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
            errors: {
                'oldPassword': '',
                'newPassword': '',
                'newPasswordConfirm': '',
            },
        }
    },

    methods: {

        checkForms() {
            this.errors = {
                'oldPassowd': '',
                'newPassword': '',
                'newPasswordConfirm': '',
            }

            let requiredField = 'Данное поле обязательно для заполнения.'
            let fewСharacters = 'Количество символов должно быть не меньше 8.'

            if (this.oldPassword.length === 0)
                this.errors.oldPassword = requiredField
            if (this.newPassword.length === 0)
                this.errors.newPassword = requiredField
            if (this.newPasswordConfirm.length === 0)
                this.errors.newPasswordConfirm = requiredField

            for (let i of Object.keys(this.errors)) {
                if (this.errors[i] !== '') return false
            }

            if (this.oldPassword.length < 8)
                this.errors.oldPassword = fewСharacters
            if (this.newPassword.length < 8)
                this.errors.newPassword = fewСharacters
            if (this.newPasswordConfirm.length < 8)
                this.errors.newPasswordConfirm = fewСharacters

            for (let i of Object.keys(this.errors)) {
                if (this.errors[i] !== '') return false
            }

            if (this.newPassword !== this.newPasswordConfirm) {
                this.errors.newPasswordConfirm = 'Пароли не совпадают, повторите ввод.'
                return false
            }

            return true

        },

        openConfirmModal() {
            if (this.checkForms()) {
                this.$modal.show(confirmModal, {
                    "operation": 'changePassword',
                    "data": {'newPassword': this.newPassword, 'oldPassword': this.oldPassword},
                }, {
                    styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                    width: "420px",
                    height: "auto",
                    reset: true,
                })
            }
        }
    }

}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

.emailChangeBlock {
    @include flex-column();
    align-items: flex-start;
    justify-content: flex-start;
}

.infoText {
    text-align: center;
    @include desctiption-0-8();
}

.fbwrappa,
.flexInputsBox {
    @include flexible-column();
}

.flexInputsBox,
.fbwrappa {
    width: 100%;
}

.LkControlInput {
    width: 50%;
    height: 28px;
    border-radius: 3px;
    margin: 5px 0;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
    text-align: center;
    font-family: AventirN;
    font-size: 0.9em;
    &::placeholder {
        font-size: 0.85em;
    }
}

.cf-btn {
    @include main-button(4px, 10px);
    width: 90px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid rgb(38, 44, 57);
    -webkit-box-shadow: 0 0 0 1000px rgb(31, 32, 35) inset;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;

}


</style>