var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skin-gallery"},[_c('div',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.isShowed),expression:"isShowed"}],staticClass:"s-g-title-wrapp"},[_c('div',{staticClass:"s-g-title"},[_vm._v(" Галерея скинов ")])]),_c('div',{staticClass:"setupInfo"},[(_vm.capeTiming !== null && (_vm.capeTiming / 1000) > 0)?_c('div',[_vm._v(" Бесплатная замена "),_c('span',{staticStyle:{"color":"#00e6ff"}},[_vm._v("плаща:")]),_c('span',[_vm._v(_vm._s(this.timerTimeFormatting(this.capeTiming)))]),_vm._v(". ")]):_vm._e(),(_vm.hd_skinTiming !== null && (_vm.hd_skinTiming / 1000) > 0)?_c('div',[_vm._v(" Бесплатная замена "),_c('span',{staticStyle:{"color":"#ff3333"}},[_vm._v("HD скина:")]),_c('span',[_vm._v(" "+_vm._s(this.timerTimeFormatting(this.hd_skinTiming)))]),_vm._v(". ")]):_vm._e(),(_vm.hd_capeTiming !== null && (_vm.hd_capeTiming / 1000) > 0)?_c('div',[_vm._v(" Бесплатная замена "),_c('span',{staticStyle:{"color":"#ffb500"}},[_vm._v("HD плаща: ")]),_c('span',[_vm._v(" "+_vm._s(this.timerTimeFormatting(this.hd_capeTiming)))]),_vm._v(". ")]):_vm._e()]),_c('div',{staticClass:"b-b-sm"}),_c('div',{staticClass:"s-g-menu"},[_c('div',{staticClass:"s-g-menu-el",class:{'active_tab': _vm.frames.mySkinsTab},on:{"click":function($event){_vm.changeFrameCustom('mySkinsTab', _vm.frames); _vm.k+=1}}},[_vm._v(" Мои скины ")]),_c('div',{staticClass:"s-g-menu-el",class:{'active_tab': _vm.frames.myCapesTab},on:{"click":function($event){_vm.changeFrameCustom('myCapesTab', _vm.frames); _vm.k+=1}}},[_vm._v(" Мои плащи ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({content: 'Тут можно посмотреть публичные <span>скины</span> других игроков :)',
                                theme:'GalleryToolTips-top',
                                shown: false,
                                html:true}),expression:"{content: 'Тут можно посмотреть публичные <span>скины</span> других игроков :)',\n                                theme:'GalleryToolTips-top',\n                                shown: false,\n                                html:true}"}],staticClass:"s-g-menu-el",class:{'active_tab': _vm.frames.publicSkinsTab},on:{"click":function($event){_vm.changeFrameCustom('publicSkinsTab', _vm.frames); _vm.k+=1}}},[_vm._v(" Публичные скины ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({content: 'Тут можно посмотреть публичные <span>плащи</span> других игроков :)',
                                theme:'GalleryToolTips-top',
                                shown: false,
                                html:true}),expression:"{content: 'Тут можно посмотреть публичные <span>плащи</span> других игроков :)',\n                                theme:'GalleryToolTips-top',\n                                shown: false,\n                                html:true}"}],staticClass:"s-g-menu-el",class:{'active_tab': _vm.frames.publicCapesTab},on:{"click":function($event){_vm.changeFrameCustom('publicCapesTab', _vm.frames); _vm.k+=1}}},[_vm._v(" Публичные плащи ")])]),(_vm.frames.mySkinsTab)?_c('div',{key:_vm.k,staticClass:"s-g-action-frame-wrappa"},[_c('MyGallery',{attrs:{"type":'skin',"filters":`player_id=${_vm.$root.getPlayerData().id}&nogroup=1`,"pp":_vm.pCountToLoad}})],1):_vm._e(),(_vm.frames.myCapesTab)?_c('div',{key:_vm.k,staticClass:"s-g-action-frame-wrappa"},[_c('MyGallery',{attrs:{"type":'cape',"filters":`player_id=${_vm.$root.getPlayerData().id}&nogroup=1`,"pp":_vm.pCountToLoad}})],1):_vm._e(),(_vm.frames.publicSkinsTab)?_c('div',{key:_vm.k,staticClass:"s-g-action-frame-wrappa"},[_c('PublicGallery',{attrs:{"type":"skin","filters":`type=public`,"pp":_vm.pCountToLoad}})],1):_vm._e(),(_vm.frames.publicCapesTab)?_c('div',{key:_vm.k,staticClass:"s-g-action-frame-wrappa"},[_c('PublicGallery',{attrs:{"type":"cape","filters":`type=public`,"pp":_vm.pCountToLoad}})],1):_vm._e(),_c('div',{staticClass:"paginationRow"},[_c('div',{staticClass:"pagesCount"},[_vm._v(" "+_vm._s(_vm.pCurrent)+" / "+_vm._s(_vm.pCountAll)+" ")]),_c('div',{staticClass:"pagesCount"},[_vm._v(" Текстур : "+_vm._s(_vm.txtrTotal)+" ")]),(_vm.pCurrent > 1 )?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({content: 'Загрузить <span>прошлую</span> страницу',
                                theme:'GalleryToolTips-top',
                                html:true}),expression:"{content: 'Загрузить <span>прошлую</span> страницу',\n                                theme:'GalleryToolTips-top',\n                                html:true}"}],staticClass:"toUp"},[_c('svg',{staticClass:"bi bi-arrow-up-circle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"25","height":"25","fill":"white","viewBox":"0 0 16 16"},on:{"click":function($event){return _vm.doThatShit('loadBack')}}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"}})])]):_vm._e(),(_vm.pCurrent != _vm.pCountAll)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({content: 'Загрузить <span>следующую</span> страницу',
                                theme:'GalleryToolTips-top',
                                html:true}),expression:"{content: 'Загрузить <span>следующую</span> страницу',\n                                theme:'GalleryToolTips-top',\n                                html:true}"}],staticClass:"toDown"},[_c('svg',{staticClass:"bi bi-arrow-up-circle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"25","height":"25","fill":"white","viewBox":"0 0 16 16"},on:{"click":function($event){return _vm.doThatShit('loadMore')}}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"}})])]):_vm._e(),_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({content: '<span>Количество</span> элементов на странице.<br> <span>Внимание!:</span> установка большого количества отображаемых текстур негативно влияет на производительность в браузере google chrome. К firefox - это не относится.',
                                theme:'GalleryToolTips-top-moreWidth',
                                html:true}),expression:"{content: '<span>Количество</span> элементов на странице.<br> <span>Внимание!:</span> установка большого количества отображаемых текстур негативно влияет на производительность в браузере google chrome. К firefox - это не относится.',\n                                theme:'GalleryToolTips-top-moreWidth',\n                                html:true}"},{name:"model",rawName:"v-model",value:(_vm.pCountToLoad),expression:"pCountToLoad"}],staticClass:"s-m-input",domProps:{"value":(_vm.pCountToLoad)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.doThatShit('reload')},"keypress":_vm.onlyNumber,"input":function($event){if($event.target.composing)return;_vm.pCountToLoad=$event.target.value}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }