<template>
    <div class="user-space">
        <div class="skin-frame">
            <div class="s-f-skin-front">
                <SkinViewer v-if="skinUrl !== null"
                            ref="skinFront"
                            v-bind:skinUrl="skinUrl"
                            v-bind:cape-url="capeUrl"
                            v-bind:frame="'lkSkinFront'"
                            :key="componentKeySkinFront"/>
            </div>
            <div class="s-f-skin-back">
                <div class="skin-back-wrapper">
                    <SkinViewer v-if="skinUrl !== null"
                                ref="skinBack"
                                v-bind:skinUrl="skinUrl"
                                v-bind:cape-url="capeUrl"
                                v-bind:frame="'lkSkinBack'"
                                :key="componentKeySkinBack"/>
                </div>
            </div>
        </div>

        <div class="account-details" :key="skinInfoKey">
            <div class="skin-helper">
                <div class="skin-helper-btn"
                     @click="$refs.filePath.click()">
                    <input type="file" style="display: none" ref="filePath" v-on:change="getFilePath">Обзор..
                </div>
                <div class="file-path"
                     v-if="file === null">Название файла
                </div>
                <div class="file-path" v-else>
                    {{
                        $refs.filePath.value.split('\\').slice(-1)[0].length >= 15 ? ['...', $refs.filePath.value.split('\\').slice(-1)[0].slice($refs.filePath.value.split('\\').slice(-1)[0].length - 15, $refs.filePath.value.split('\\').slice(-1)[0].length)].join('') : $refs.filePath.value.split('\\').slice(-1)[0]
                    }}
                </div>
            </div>

            <div class="u-skin-btns">
                <div class="u-skin-btns-row">
                    <!--Кнопка скина-->
                    <div class="u-s-b-r-btn" v-tooltip="{content: 'Установка бесплатно', theme:'LkToolTips-top-withPd'}"
                         @click="openModalSet('skin', 'set')">
                        Обычный
                        скин
                    </div>

                    <!--Кнопки плаща-->
                    <div class="u-s-b-r-btn" v-if="capeTiming !== null && (capeTiming / 1000) > 0"
                         v-tooltip="{content: getTooltipTextureContent('cape'),
                                    theme:'LkToolTips-top-withPd',
                                    shown: true,
                                    html:true}"
                         @click="openModalSet('cape', 'set')">
                        Обычный
                        плащ
                    </div>

                    <div class="u-s-b-r-btn" v-else
                         v-tooltip="{content: `Стоимость <span>${cost.cape} ₽</span>`, theme:'LkToolTips-top-withPd', html:true}"
                         @click="openModalSet('cape', 'set')">
                        Обычный
                        плащ
                    </div>
                </div>

                <!--Кнопки hd скина-->
                <div class="u-skin-btns-row">
                    <div class="u-s-b-r-btn"
                         v-if="hd_skinTiming !== null && (hd_skinTiming / 1000) > 0"
                         v-tooltip="{content: getTooltipTextureContent('hd_skin'),
                                    theme:'LkToolTips-bot',
                                    shown: true,
                                    html:true}"
                         @click="openModalSet('hd_skin', 'set')">HD
                        Скин
                    </div>

                    <div class="u-s-b-r-btn" v-else
                         v-tooltip="{content: `Стоимость <span>${cost.hd_skin} ₽</span>`, theme:'LkToolTips-top-withPd', html:true}"
                         @click="openModalSet('hd_skin', 'set')">HD
                        Скин
                    </div>

                    <!--Кнопки hd плаща-->
                    <div class="u-s-b-r-btn"
                         v-if="hd_capeTiming !== null && (hd_capeTiming / 1000) > 0"
                         v-tooltip="{content: getTooltipTextureContent('hd_cape'),
                          theme:'LkToolTips-bot',
                            shown: true,
                            html:true
                         }"
                         @click="openModalSet('hd_cape', 'set')">HD
                        Плащ
                    </div>

                    <div class="u-s-b-r-btn" v-else
                         v-tooltip="{content: `Стоимость <span>${cost.hd_cape} ₽</span>`, theme:'LkToolTips-top-withPd', html:true}"
                         @click="openModalSet('hd_cape', 'set')">HD
                        Плащ
                    </div>

                </div>

                <div class="u-skin-btns-row">
                    <!--Кнопки удаления-->
                    <div class="u-s-b-r-btn" v-tooltip="{content: `Удаление бесплатно`, theme:'LkToolTips-top-withPd', html:true}"
                         @click="openModalSet('SKIN', 'delete')">
                        Удалить
                        скин
                    </div>
                    <div class="u-s-b-r-btn" v-tooltip="{content: `Удаление бесплатно`, theme:'LkToolTips-top-withPd', html:true}"
                         @click="openModalSet('CAPE', 'delete')">
                        Удалить
                        плащ
                    </div>
                </div>

                <div class="u-skin-btns-centre">
                    <div class="u-s-b-r-btn" style="width: 94%" @click="openModalGallery">
                        Галерея скинов
                    </div>
                </div>

            </div>

            <div class="lk-border"/>

            <div class="lk__accountBlock_userSpace_accountDetails_usernameBlock">
                <div class="a-d-description">Имя игрока</div>
                <div class="a-d-info">
                    {{ $root.getPlayerData().name }}
                </div>
            </div>
            <div class="lk__accountBlock_userSpace_accountDetails_userUUIDBlock">
                <div class="a-d-description">UUID (уникальный
                    идентификатор)
                </div>
                <div class="a-d-info">
                    {{ $root.getPlayerData().uuid }}
                </div>
            </div>
            <div class="a-d-user-dates">
                <div class="a-d-user-dates-dt-col">
                    <div class="a-d-description">Профиль создан
                    </div>
                    <div class="a-d-info">
                        {{ datetimeNormalizer($root.getPlayerData().created_at) }}
                    </div>
                </div>
                <div class="a-d-user-dates-dt-col">
                    <div class="a-d-description">Последний визит
                    </div>
                    <div class="a-d-info">
                        {{ datetimeNormalizer($root.getPlayerData().updated_at) }}
                    </div>
                </div>
            </div>

            <div class="lk__accountBlock_userSpace_accountDetails_userRefLink">
                <div class="a-d-description">Реферальные ссылки
                </div>
                <div class="a-d-info">
                    greatray.ru/referal/{{ $root.getPlayerData().id }}
                    <br>
                    greatray.ru/play/{{ $root.getPlayerData().id }}
                </div>
            </div>
            <div class="lk-border"/>
        </div>
    </div>
</template>

<script>


import confirmModal from "../../modals/ConfirmModal";
import SkinViewer from '../skin/SkinViewer'
import LkSkinGalleryModal from "@/components/elements/lk/LkSkinGallery/LkSkinGalleryModal";

import {TimeFormatterMixin} from "../../../mixins/TimeFormatterMixin";
import {PreloaderMixin} from "../../../mixins/PreloaderMixin";
import {sexyTooltipMixin} from "@/mixins/SexyTooltipMixin";

export default {
    name: "LkUserSpace",
    mixins: [TimeFormatterMixin, PreloaderMixin, sexyTooltipMixin],
    components: {SkinViewer},

    data() {
        return {
            skinUrl: null,
            capeUrl: null,
            file: null,
            componentKeySkinFront: 0,
            componentKeySkinBack: 0,
            skinInfoKey: 0,

            capeTiming: null,
            hd_capeTiming: null,
            hd_skinTiming: null,
        }
    },

    methods: {
        openModalSet(type, operation) {
            let data = null
            if (operation === 'set') {
                data = this.file
            }

            this.$modal.show(confirmModal, {
                "type": type,
                "operation": operation,
                "data": data
            }, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "420px",
                height: "auto",
                reset: true,
            })
        },

        openModalGallery() {
            this.$modal.show(LkSkinGalleryModal, {}, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none; z-index: 15000 !important;",
                width: "900px",
                height: "auto",
                reset: true,
            })
        },

        getSkinData(userName) {
            this.preloaderStartLk('.skin-frame')
            require('axios').get(`https://api.greatray.ru/api/launcher/textures/${userName}`).then(
                data => {
                    this.preloaderStop('.skin-frame')
                    this.skinUrl = data.data.skin
                    this.capeUrl = data.data.cape
                }
            ).catch(err => {
                console.log('getSkinData Error.')
                console.log(err)
            })
        },

        getFilePath(event) {
            let files = event.target.files;
            if (files.length)
                this.file = files[0];
        },

        updateVisualPresentation(type, data) {
            if (['skin', 'SKIN', 'hd_skin'].includes(type)) {
                this.skinUrl = data.data.url
            } else {
                this.capeUrl = data.data.url
            }
            this.componentKeySkinFront += 1
            this.componentKeySkinBack += 1
            this.$root.update_PlayerData().then(() => {
                this.syncTxrTimings('uploading')
                this.skinInfoKey += 1
            })
        },
    },

    created() {
        let self = this
        this.$root.$on('updVisualPresentation', function (edata) {
            edata.operation === 'set' && self.updateVisualPresentation(edata.type, edata.data)
            edata.operation === 'delete' && self.getSkinData(self.$root.getPlayerData().name)
        })
    },

    mounted() {
        this.getSkinData(this.$root.getPlayerData().name)
        this.syncTxrTimings('uploading')
    }

}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.user-space {
    @include flexible-column-sp-ev();
    min-width: 30%;
    width: 30%;
    height: 700px;
    padding-bottom: 10px;
    border-radius: 3px;
    background-color: $super-shitty-hex-gray;
    text-align: center;
}

.skin-frame {
    @include flexible-row-sp-ev();
    width: 93%;
    height: 40%;
}

.s-f-skin-front {
    width: 50%;
    height: 100%;
    border-right: 1px outset gray;
    border-bottom: 1px outset gray;
}

.s-f-skin-back {
    width: 50%;
    height: 100%;
    border-bottom: 1px outset gray;
}

.skin-back-wrapper {
    margin-left: 15px;
}

.account-details {
    @include flexible-column-sp-ev();
    z-index: 3;
    width: 93%;
    height: 55%;
}

.a-d-description {
    font-size: 0.7em;
    color: rgba(134, 133, 133, 0.68);
    -webkit-user-select: none;
}

.a-d-info {
    font-family: $main-font;
    font-size: 0.8em;
    color: rgba(191, 190, 190, 0.91);
}

.a-d-user-dates {
    @include flexible-row-sp-bt();
}

.a-d-user-dates-dt-col {
    @include flexible-column();
    padding: 0 10px;
}

.skin-helper {
    @include flexible-row-start();
    border: 1px ridge rgba(128, 128, 128, 0.22);
    width: 93%;
    height: 35px;
}

.skin-helper-btn {
    @include main-button(4px, 5px);
    margin-left: 5px;
    width: 85px;
}

.file-path {
    @include flexible();
    @include desctiption-0-8();
    border-radius: 2px;
    margin-left: 6px;
    color: rgba(191, 190, 190, 0.91);
    -webkit-user-select: none;
}

.u-skin-btns {
    @include flexible-column();
    width: 95%;
    height: auto;
}

.u-skin-btns-row {
    @include flexible-row-sp-bt();
    width: 100%;
    padding: 5px 0;
}

.u-skin-btns-centre {
    @include flexible-column();
    width: 100%;
    padding: 5px 0;
}

.u-s-b-r-btn {
    @include main-button(4px, 10px)
}

.lk-border {
    border: 1px solid rgba(128, 128, 128, 0.29);
    width: 95%;
}

#green {
    color: $nice-green !important;
}


</style>