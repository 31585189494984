<template>
    <div class="u-r-f-r">
        <div class="u-r-f-r-f">
            <div class="title-block">
                <div class="frame-title" style="display: flex;">
                    <CustomSelect v-bind:options="getServers()" default="HiTech" @send="changeServer($event)"
                                  style="font-size: 0.75em"/>
                </div>
            </div>
            <div class="u-r-f-r-f-flex-c-w" :key="cartKey">
                <div v-show="type !== 'kits'" class="lk-border"/>
                <div class="preloader-container" v-if="itemsByServer[ServerName] === undefined"/>

                <div v-if="itemsByServer[ServerName] !== undefined" class="cartbox">
                    <div v-for="kit in itemsByServer[ServerName]" style="font-size: 0.8em">
                        <div v-show="type === 'kits'" class="lk-border"/>
                        <div v-if="type === 'kits'" style="padding: 10px 0 0 20px">
                            <div class="kit_title" >Кит {{ kit.name }}, раз в {{ getInterval(kit.interval) }}</div>
                            <div v-if="hasKit(kit)" class="has_kit">
                                У вас есть доступ к этому киту
                            </div>
                            <div v-else class="no_has_kit">
                                У вас нет доступа к этому киту
                            </div>
                            <div class="has_kits" style="">
                                Данный кит может получать:<span v-for="has in kit.has" style="margin-top: 5px"
                                                                    class="has_status">{{ has.name }}</span>
                            </div>
                        </div>
                        <div class="to-user-text" style="font-size: 1.2em" v-if="kit.items.length === 0">
                            {{ empty_message }}
                        </div>
                        <div>
                            <div class="spec" v-if="kit.items.length > 0" :style="{'paddingBottom': type === 'kits' ? '5px': 0}">
                                <div :class="type === 'kits' ? 'cSmall' : 'commodity'"
                                     v-for="item in kit.items"
                                     v-tooltip="{content: item.item_name, theme:'LkToolTips-top-withPd'}"
                                     :style="`background: #242424 url(${item.icon_url}) center no-repeat`">
                                    <div class="amount">
                                        x{{ item.amount }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CustomSelect from "@/components/elements/donat/CustomSelect";
import {PreloaderMixin} from "@/mixins/PreloaderMixin";

export default {
    name: "LkCart",
    components: {CustomSelect},
    mixins: [PreloaderMixin],
    props: ['empty_message', 'type'],
    data() {
        return {
            ServerName: 'HiTech',
            itemsByServer: {},
            cartKey: 0,
            preloaderContainer: '.preloader-container',
        }
    },
    methods: {
        getServers() {
            let servers = []
            for (let i in this.$root.getPlayerData().luckperms)
                servers.push(i)
            return servers
        },
        changeServer(server) {
            this.ServerName = server
            if (server !== 'HiTech')
                requestAnimationFrame(this.getCart)
        },
        getInterval(interval) {
            if(interval % 86400 === 0)
                return interval/86400 + (interval/86400 === 1 ? ' день' : (interval/86400 >= 5 ? ' дней' : ' дня'))
            if(interval % 3600 === 0)
                return interval/3600 + (interval/3600 === 1 ? ' час' : (interval/3600 >= 5 ? ' часов' : ' часа'))
            if(interval % 60 === 0)
                return interval/60 + (interval/60 === 1 ? ' минуту' : (interval/60 >= 5 ? ' минут' : ' минуты'))
        },
        hasKit(kit) {
            if (this.$root.getPlayerData().luckperms[this.ServerName] !== undefined) {
                let h = false
                this.$root.getPlayerData().luckperms[this.ServerName].statuses.forEach(status => {
                    kit.has.forEach(s => {
                        if (status.status.id === s.id)
                            h = true
                    })
                })
                if (h)
                    return h
            }
            return false
        },
        getCart() {
            if (this.itemsByServer[this.ServerName] === undefined) {
                this.preloaderStartLk(this.preloaderContainer, '#1c1c1d')
                require('axios').get(`/api/${this.type}/${this.ServerName}`).then(
                    data => {
                        this.preloaderStop(this.preloaderContainer)
                        if (this.type === 'kits') {
                            this.itemsByServer[this.ServerName] = data.data
                        } else {
                            this.itemsByServer[this.ServerName] = [{'items': data.data, 'name': 'cart'}]
                        }
                        this.cartKey++
                    }
                ).catch(e => {
                    this.preloaderStop(this.preloaderContainer)
                    this.itemsByServer[this.ServerName] = []
                    this.cartKey++
                })
            }
        }
    },
    mounted() {
        this.getCart()
    },
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

.cartbox .spec {
    padding: 0 20px;
}

.cartbox {
    width: 100%;
}

@include l-c-r();

.preloader-container {
    padding: 50px 300px;
}

.to-user-text {
    @include text-normalize();
    @include text-1-0();
    color: $brightless-white;
    padding: 30px 20px;
}

.commodity, .cSmall {
    display: inline-flex;
    flex-direction: column-reverse;
    width: 64px;
    height: 64px;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    text-align: right;
    color: white;
    font-weight: bold;
    font-size: 1.5em;
}

.cSmall {
    width: 40px;
    height: 40px;
    background-size: 85% !important;
    font-size: 1em;
}

.kit_title {
    color: white;
    font-size: 1.5em;
}

.has_kit, .no_has_kit, .has_kits {
    font-size: 0.95em;
}

.has_kit {
    color: greenyellow;
}

.no_has_kit {
    color: orangered;
}

.has_kits {
    color: whitesmoke;
}

.has_status {
    margin: 4px;
    background: #2d2d2d;
    padding: 4px;
    border-radius: 4px;
    display: inline-block;
}
</style>