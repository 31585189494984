export const sexyTooltipMixin = {
    data() {
        return {
            capeTiming: null,
            hd_capeTiming: null,
            hd_skinTiming: null,

            cost: {
                skin: 'бесплатно',
                cape: 49,
                hd_skin: 79,
                hd_cape: 99
            }
        }
    },
    methods: {
        syncTxrTimings(type) {
            let upl = this.$root.getPlayerData().texture_permissions[type]
            if (upl !== undefined) {
                if (upl.cape !== undefined) {
                    this.capeTiming = (new Date(upl.cape.end)).getTime() - Date.now()
                    setInterval(() => {
                        this.capeTiming = (new Date(upl.cape.end)).getTime() - Date.now()
                    }, 1000);
                }
                if (upl.hd_cape !== undefined) {
                    this.hd_capeTiming = (new Date(upl.hd_cape.end)).getTime() - Date.now()
                    setInterval(() => {
                        this.hd_capeTiming = (new Date(upl.hd_cape.end)).getTime() - Date.now()
                    }, 1000);
                }

                if (upl.hd_skin !== undefined) {
                    this.hd_skinTiming = (new Date(upl.hd_skin.end)).getTime() - Date.now()
                    setInterval(() => {
                        this.hd_skinTiming = (new Date(upl.hd_skin.end)).getTime() - Date.now()
                    }, 1000);
                }
            }
        },
        timerTimeFormatting(timing) {
            if (Math.ceil(timing) > 120)
                return `${Math.ceil(timing / (1000 * 60))} минут`
            else
                return `${Math.ceil(timing)} секунд`

        },
        getTooltipTextureContent (textureType) {
            switch (textureType) {
                case 'skin':
                    return 'Обычные скины: <span>бесплатно!</span>'
                case 'hd_skin':
                    if (this.hd_skinTiming !== null && (this.hd_skinTiming / 1000) > 0)
                        return `Поздравляем с установкой HD скина! <br> У вас есть ещё <span>${this.timerTimeFormatting(this.hd_skinTiming)}</span> на бесплатную замену HD скина.`
                    else
                        return `Стоимость <span>${this.cost.hd_skin / 2} ₽</span>`
                    break
                case 'hd_cape':
                    if (this.hd_capeTiming !== null && (this.hd_capeTiming / 1000) > 0)
                        return `Поздравляем с установкой HD плаща! <br> У вас есть ещё <span>${this.timerTimeFormatting(this.hd_capeTiming)}</span> на бесплатную замену HD плаща.`
                    else
                        return `Стоимость <span>${this.cost.hd_cape / 2} ₽</span>`
                    break
                case 'cape':
                    if (this.capeTiming !== null && (this.capeTiming / 1000) > 0)
                        return `Поздравляем с установкой плаща! <br> У вас есть ещё <span>${this.timerTimeFormatting(this.capeTiming)}</span> на бесплатную замену плаща.`
                    else
                        return `Стоимость <span>${this.cost.cape / 2} ₽</span>`
                    break
            }
        },
    }

}