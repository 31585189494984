<template>
    <div>
        <LkFinances/>
        <LkServers/>
    </div>
</template>

<script>

import LkFinances from "./LkActions/LkFinances";
import LkServers from "./LkActions/LkServers";

export default {
    name: "LkActions",
    components: {LkFinances, LkServers}
}
</script>

<style scoped>

</style>