<template>
    <div class="act-row" ref="arw">
        <div v-if="textures.length" class="m-s-skin-frame" v-for="texture in textures">
            <div class="txr-type pulse">{{ trx_types[texture.texture_type] }}</div>
            <SkinViewer v-bind:skin-url="formatLinkToWebp(texture.url)" v-bind:textureID="texture.id"
                        frame="lkSkinFront" v-if="type==='skin'"/>
            <SkinViewer v-bind:cape-url="formatLinkToWebp(texture.url)" v-bind:textureID="texture.id"
                        frame="lkSkinBack" v-if="type==='cape'"/>
            <div class="info">
                <div class="owner">Загрузил: <span>{{ texture.player.name }}</span></div>
                <div class="owow-time">Загружено: <span>{{ datetimeNormalizer(texture.updated_at) }}</span></div>
                <div class="used">Используют:
                    <span>{{ texture.used }} {{ declOfNum(texture.used, ['игрок', 'игрока', 'игроков']) }}</span></div>
            </div>

            <div class="m-button" v-tooltip="{content: getTooltipTextureContent(texture.texture_type),
                                    theme:'GalleryToolTips-top',
                                    html:true}" @click="set(texture, type)"
                 v-if="!texture.set && !texture.applying && !texture.er && texture.in_gallery !== 1">Установить себе
            </div>
            <div class="m-button" v-else-if="texture.applying">Применяем...</div>
            <div class="m-button-disabled" style="background-color:#ff3838;" v-tooltip="{content: getTooltipTextureContent(texture.texture_type),
                                    theme:'GalleryToolTips-top',
                                    html:true}" v-else-if="texture.er">{{ texture.er }}
            </div>
            <div class="m-button-disabled" v-else-if="texture.in_gallery === 1">Уже есть в галерее</div>
            <div class="m-button-disabled" v-else>Установлен</div>

        </div>
    </div>
</template>

<script>
import {chSCtypeMixin} from "@/components/elements/lk/LkSkinGallery/chSCtypeMixin";
import {sexyTooltipMixin} from "@/mixins/SexyTooltipMixin";
import {PreloaderMixin} from "@/mixins/PreloaderMixin";
import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import {DeclOfNumMixin} from "@/mixins/DeclOfNumMixin";

import SkinViewer from "@/components/elements/skin/SkinViewer";

export default {
    name: "PublicGallery",
    mixins: [chSCtypeMixin, PreloaderMixin, TimeFormatterMixin, DeclOfNumMixin, sexyTooltipMixin],
    components: {SkinViewer},
    props: ['type', 'filters', 'pp'],
    data() {
        return {}
    },
    methods: {},
    mounted() {
        if (this.pp !== undefined)
            this.perPage = this.pp
        this.preloaderStartLk('.s-g-action-frame-wrappa')
        this.getTextures(this.type, this.filters, false)
        this.syncTxrTimings('gallery')
    },

}
</script>

<style scoped lang="scss">

@import "src/assets/scss/vars";
@import "src/assets/scss/mixins";
@import "src/assets/scss/animations";

.act-row {
    @include flexible-row-sp-ev;
    @include fill-all-block;

    flex-wrap: wrap;
    overflow-y: scroll;
}

.m-s-skin-frame {
    @include flexible-column;
    width: 200px;
    min-width: 180.5px;
    height: 95%;
    border-right: 1px groove rgba(128, 128, 128, 0.52);
    border-left: 1px groove rgba(128, 128, 128, 0.52);
    margin-top: 5px;
    padding-bottom: 5px;

}

.skin-m-frame {
    @include flexible-column();
}

.m-button, .m-button-disabled {
    @include main-button(5px, 5px);
    margin-top: 10px;
    width: 80%;
}

.m-button-disabled {
    background: #808080;
    cursor: default;

    &:hover {
        transform: none;
    }
}


.m-row {
    @include flexible-row;
    justify-content: space-between;
    margin-top: 5px;
    margin-left: 46px;

    .lbl {
        font-family: $main-font;
        font-size: 0.8em;
        color: $brightless-white;
    }
}

@include switch(#5b5a5a, #583abe, #7a323b, white, 45px, 21px, 6px, 4px);

.switch {
    margin-left: 10px;
}

.info {
    @include flexible-column-start;

    .owner, .owow-time, .used {
        @include desctiption-0-8;
        margin-top: 5px;
        color: rgba(134, 133, 133, 0.68);

        span {
            color: $brightless-gray;
        }
    }
}

.txr-type {
    position: relative;
    width: 100%;
    text-align: right;
    margin-right: 20px;
    font-family: $main-font;
    font-size: 0.8em;
    color: $brightless-white;
}

.pulse {
    animation: 5s ease infinite alternate text-shadow-gain-pulse-more2;
    transition: all 0.5s ease;
    -webkit-user-select: none;
}


.paginatorNext {
    @include flexible-column;
    @include desctiption-0-8;
    color: $not-bright-white;
    width: 100%;
    height: 70px;

    .sexyPaginator, .sexyPaginator2 {
        transform: rotate(180deg);
        cursor: pointer;
        transition: 2s ease all;

        &:hover {
            transition: 0.5s ease all;
        }
    }
    .sexyPaginator2{
        transform: rotate(0deg);
    }
}

</style>