<template>
    <div class="f-r">
        <div class="f-r-s-field">
            <div class="field-trow">
                <div class="field-title">Настройки
                    серверов{{ selectedServerTab !== null ? ':' : '' }} {{ subServerTitles[selectedSubServerTab] }}
                </div>
                <div class="btn-wrapp">
                    <div class="s-f-trow-btn"
                         v-if="selectedServerTab !== null"
                         @click="toogleBackServerTabFrame">Вернуться
                    </div>
                </div>
            </div>

            <div class="sm-border"/>

            <div class="r-f-servers">
                <div style="width: 100%"
                     v-for="sname in Object.keys(serversData['servers'])"
                     v-show="serversData['servers'][sname].visible"
                     :class="{'r-f-oslbg': selectedSubServerTab === null}">

                    <div class="r-f-concrete-server" v-if="serversData.servers[sname].possiblePuns.length === 0"
                         @click="toogleSomeServerTabFrame(sname)">
                        <div class="s-t-wrapp smaller-tab">
                            <div class="r-f-concrete-server-title"
                                 @click="toogleSomeServerSubTabFrame('serverTab', sname)"
                                 :class="{'tabbrdrz': selectedSubServerTab === 'serverTab'}">
                                {{ sname }}
                            </div>
                        </div>
                        <div class="s-t-wrapp">
                            <div
                                class="r-f-concrete-server-status"
                                v-if="serversData['servers'][sname].statuses.length > 0"
                                @click="toogleSomeServerSubTabFrame('statusTab', sname)"
                                :class="{'tabbrdrz': selectedSubServerTab === 'statusTab'}">
                                {{ serversData['servers'][sname].statuses[0].status.name }}
                            </div>
                            <div
                                class="r-f-concrete-server-status"
                                v-else
                                @click="toogleSomeServerSubTabFrame('statusTab', sname)"
                                :class="{'tabbrdrz': selectedSubServerTab === 'statusTab'}">
                                Игрок
                            </div>
                        </div>

                        <div class="s-t-wrapp">
                            <div
                                class="r-f-concrete-server-prefix"
                                @click="toogleSomeServerSubTabFrame('prefixTab', sname)"
                                :class="{'tabbrdrz': selectedSubServerTab === 'prefixTab'}">
                            <span
                                v-for="char in getClicedDecorated([serversData['servers'][sname].prefix.length > 0 ? serversData['servers'][sname].prefix : '&f[Игрок]', serversData['player_name']].join(' '), 25)"
                                :style="{ color : Object.keys(colorDecorObj).includes(Object.values(char)[0].colorFormat) ? colorDecorObj[Object.values(char)[0].colorFormat] : 'white',
                                   fontWeight :  Object.values(char)[0].textFormat.includes('&l') ? textDecorObj['&l'] : 300,
                                   textDecoration: Object.values(char)[0].textFormat.includes('&m') ? textDecorObj['&m'] : (Object.values(char)[0].textFormat.includes('&n') ? textDecorObj['&n'] : 'none' ),
                                   fontStyle: Object.values(char)[0].textFormat.includes('&o') ? textDecorObj['&o'] : 'normal',
                                   padding: Object.keys(char)[0] === ' ' ? '0 1px' : '0'}">{{
                                    Object.keys(char)[0]
                                }}</span>
                            </div>
                        </div>
                        <div class="s-t-wrapp">
                            <div
                                class="r-f-concrete-server-grace"
                                @click="toogleSomeServerSubTabFrame('graceTab', sname)"
                                :class="{'tabbrdrz': selectedSubServerTab === 'graceTab'}">
                                {{
                                    serversGracesData !== null && serversGracesData !== undefined ? findGracePlayerData(sname) : '?'
                                }}
                                GR
                            </div>
                        </div>
                        <div class="s-t-wrapp smaller-tab">
                            <div
                                class="r-f-concrete-server-grace"
                                @click="toogleSomeServerSubTabFrame('varpsTab', sname)"
                                :class="{'tabbrdrz': selectedSubServerTab === 'varpsTab'}">
                                {{
                                    serversWarpsData !== null && serversWarpsData !== undefined && serversWarpsData[sname] !== undefined && serversWarpsData[sname] !== null ? serversWarpsData[sname].length : '?'
                                }}
                                {{
                                    serversWarpsData !== null && serversWarpsData !== undefined && serversWarpsData[sname] !== undefined && serversWarpsData[sname] !== null ? declOfNum(serversWarpsData[sname].length, ['варп', 'варпа', 'варпов']) : 'варпов'
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="r-f-concrete-server" v-else
                         style="background: rgba(255,0,0,0.09)">
                        <div class="s-t-wrapp smaller-tab">
                            <div class="r-f-concrete-server-title"
                                 :class="{'tabbrdrz': selectedSubServerTab === 'punsTab'}"
                                 @click="toogleSomeServerTabFrame(sname, false); toogleSomeServerSubTabFrame('punsTab', sname)"
                            >
                                {{ sname }} : У вас есть {{ serversData.servers[sname].possiblePuns.length }}
                                {{
                                    declOfNum(serversData.servers[sname].possiblePuns.length, ['активное', 'активных', 'активных'])
                                }}
                                <span
                                    style="color: red; padding-left: 5px"> {{
                                        declOfNum(serversData.servers[sname].possiblePuns.length, ['наказание', 'наказаний', 'наказаний'])
                                    }}</span>.
                            </div>
                        </div>

                        <div class="s-t-wrapp smaller-tab">
                            <div class="r-f-concrete-server-title"
                                 @click="selectedServerTab = null;selectedSubServerTab = null;serversData.servers[sname].possiblePuns = [];toogleBackServerTabFrame();serversKey++">
                                Вернуться к вкладке сервера.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="r-f-concrete-server-frame"
                     v-if="selectedServerTab !== null">
                    <div class="s-f-wrapper"
                         v-if="selectedSubServerTab === 'serverTab'">

                        <div class="s-f-server-tab">
                            <div class="statusTab_statusTableBlock" style="margin-top: 10px">
                                <div class="sst-th-center-wrapp"
                                     style="width: auto; font-size: 0.84em; margin-top: 15px">
                                    <div class="lk-servers-btn" style="width: auto; padding: 3px 10px"
                                         @click="teleportSpawn">
                                        Телепорт на спавн
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="s-f-wrapper"
                         v-if="selectedSubServerTab === 'statusTab'" :key="dateKey">

                        <div class="s-f-status-tab"
                             v-if="serversData['servers'][selectedServerTab].statuses.length > 0">
                            <div class="statusTab_statusTableBlock" style="margin-top: 10px">
                                <table class="statusTable">
                                    <colgroup>
                                        <col/>
                                        <col/>
                                        <col/>
                                        <col/>
                                    </colgroup>

                                    <thead>
                                    <tr>
                                        <th class="statusTable_title">Статус</th>
                                        <th class="statusTable_title">Срок действия</th>
                                        <!--заглушки-->
                                        <th class="statusTable_title"></th>
                                        <th class="statusTable_title"></th>
                                    </tr>
                                    </thead>

                                    <tbody style="margin-top: 10px;">
                                    <tr v-for="i in serversData['servers'][selectedServerTab].statuses">
                                        <td class="statusServerTab_th">{{ i.status.name }}</td>
                                        <td class="statusServerTab_th">
                                            {{
                                                i.lp.expiry !== 0 ? datetimeNormalizer(i.lp.expiry * 1000, true) : '-/-'
                                            }}
                                        </td>
                                        <td class="statusServerTab_th">
                                            <div class="sst-th-center-wrapp"
                                                 v-if="i.lp.expiry !== 0">
                                                <div class="lk-servers-btn"
                                                     @click="renewStatus(selectedServerTab, i.status.id, i.status.name)">
                                                    Продлить
                                                </div>
                                            </div>
                                        </td>
                                        <td class="statusServerTab_th">
                                            <div class="sst-th-center-wrapp"
                                                 v-if="i.lp.expiry !== 0">
                                                <div class="lk-servers-btn" @click="$root.redirect('/donat')">
                                                    Возможности
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                                <div class="sst-th-center-wrapp"
                                     style="width: auto; font-size: 0.84em; margin-top: 15px">
                                    <div class="lk-servers-btn" style="width: auto; padding: 3px 10px"
                                         @click="$root.redirect('/donat')">
                                        Купить новый статус
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="s-f-status-tab"
                             v-else>
                            <div class="statusTab_bigText" style="margin-top: 15px">У вас нету никаких активных
                                статусов
                                на сервере {{ selectedServerTab }}
                            </div>

                            <div class="sst-th-center-wrapp"
                                 style="width: auto; font-size: 0.84em; margin-top: 15px">
                                <div class="lk-servers-btn" style="width: auto; padding: 3px 10px"
                                     @click="$root.redirect('/donat')">
                                    Купить новый статус
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="s-f-wrapper"
                         v-if="selectedSubServerTab === 'prefixTab'">
                        <div class="prefixTab_prefixBlock" style="margin-top: 10px">
                            <table class="prefixTable">

                                <tbody style="margin-top: 10px; width: 100%;">

                                <tr>
                                    <td class="prefixServerTab_labelTd">Текст префикса:</td>
                                    <td class="prefixServerTab_inputTd">
                                        <input type="text" class="prefixTabInput" v-model="prefixText"
                                               @change="updateFormating" @keyup="updateFormating"
                                               @keydown="updateFormating" v-on:keypress="filterPrefix">
                                    </td>
                                    <td class="prefixServerTab_costTd">{{ changedCustom.prefix ? '49 р' : '0 р' }}</td>
                                </tr>

                                <tr>
                                    <td class="prefixServerTab_labelTd">Цвет префикса:</td>
                                    <td class="prefixServerTab_inputTd">
                                        <div class="prefixServerTab_color_buttons">
                                        <span class="colorSquare" v-for="color in Object.keys(colorDecorObj)"
                                              :style="{background: colorDecorObj[color]}"
                                              @click="setPrefixColor(color)"></span>
                                        </div>
                                    </td>
                                    <td class="prefixServerTab_costTd">{{ changedCustom.prefixColor ? '29 р' : '0 р' }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="prefixServerTab_labelTd">Цвет ника:</td>
                                    <td class="prefixServerTab_inputTd">
                                        <div class="prefixServerTab_color_buttons">
                                        <span class="colorSquare" v-for="color in Object.keys(colorDecorObj)"
                                              :style="{background: colorDecorObj[color]}"
                                              @click="setNickColor(color)"></span>
                                        </div>
                                    </td>
                                    <td class="prefixServerTab_costTd">{{
                                            changedCustom.nickColor ? '39 р' : '0 р'
                                        }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="prefixServerTab_labelTd">Особое оформление:</td>
                                    <td class="prefixServerTab_inputTd"><input type="text" class="prefixTabInput"
                                                                               v-model="prefixFeatureText"
                                                                               @change="updateFormatingCustom"
                                                                               @keyup="updateFormatingCustom"
                                                                               @keydown="updateFormatingCustom"></td>
                                    <td class="prefixServerTab_costTd">{{
                                            changedCustom.feature ? '199 р' : '0 р'
                                        }}
                                    </td>
                                </tr>

                                <tr style="">
                                    <td class="prefixServerTab_labelTd">Финальный вид:</td>

                                    <td class="prefixServerTab_inputTd">
                                    <span
                                        v-for="char in getClicedDecorated([this.prefixFeatureText, serversData['player_name']].join(' '), 30)"
                                        :style="{ color : Object.keys(colorDecorObj).includes(Object.values(char)[0].colorFormat) ? colorDecorObj[Object.values(char)[0].colorFormat] : 'white',
                                   fontWeight :  Object.values(char)[0].textFormat.includes('&l') ? textDecorObj['&l'] : 300,
                                   textDecoration: Object.values(char)[0].textFormat.includes('&m') ? textDecorObj['&m'] : (Object.values(char)[0].textFormat.includes('&n') ? textDecorObj['&n'] : 'none' ),
                                   fontStyle: Object.values(char)[0].textFormat.includes('&o') ? textDecorObj['&o'] : 'normal',
                                   padding: Object.keys(char)[0] === ' ' ? '0 1px' : '0'}">{{
                                            Object.keys(char)[0]
                                        }}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td style="height: 25px">
                                        <div
                                            style="display: flex; align-items: center; justify-content: center; margin-top: -14px">
                                            <div class="prefix-tab-cfrm"
                                                 @click="resetChatFormatting(selectedServerTab)"
                                                 v-if="changedCustom.feature * 199 + changedCustom.prefix * 49 + changedCustom.nickColor * 39 + changedCustom.prefixColor * 29 !== 0"
                                                 style="margin-top: 10px">Сбросить
                                            </div>

                                            <div class="prefix-tab-cfrm prefixTab_inactive" v-else
                                                 style="margin-top: 10px">Сбросить
                                            </div>
                                        </div>

                                    </td>
                                    <td style="height: 35px">
                                        <div
                                            style="display: flex; align-items: center; justify-content: center; margin-bottom: 7px">
                                            <div class="prefix-tab-cfrm"
                                                 v-if="changedCustom.feature * 199 + changedCustom.prefix * 49 + changedCustom.nickColor * 39 + changedCustom.prefixColor * 29 !== 0"
                                                 @click="setPrefixOpenBuyModal">Применить
                                            </div>

                                            <div class="prefix-tab-cfrm prefixTab_inactive" v-else>Применить
                                            </div>

                                        </div>
                                    </td>
                                    <td style="display: flex; align-items: center; justify-content: center">
                                        <div style="white-space: nowrap;margin-bottom: 8px">Итог:
                                            {{
                                                changedCustom.feature * 199 + changedCustom.prefix * 49 + changedCustom.nickColor * 39 + changedCustom.prefixColor * 29
                                            }} р
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="s-f-wrapper"
                         v-if="selectedSubServerTab === 'graceTab'">
                        <div class="graceTab_graceBlock" style="margin-top: 10px">
                            <table class="graceTable">
                                <tr>
                                    <td class="graceServerTab_labelTd">Текущий баланс GR на сервере:</td>
                                    <td class="graceServerTab_costTd">
                                        {{
                                            serversGracesData !== null && serversGracesData !== undefined ? findGracePlayerData(selectedServerTab) : ''
                                        }}
                                        GR
                                    </td>
                                </tr>

                                <tr>
                                    <td class="graceServerTab_labelTd">Курс перевода:</td>
                                    <td class="graceServerTab_costTd">1 р. - 100 GR</td>
                                </tr>
                            </table>

                            <div class="graceServerTab_info" style="margin-top: 5px; color: #4ad5ea;">GR используется
                                для внутрисерверной торговли между игроками.
                            </div>
                            <div class="serverTab_border" style="margin-top: 10px"/>

                            <div class="graceServerTab_info" style="margin-top: 5px; color: #f7f8f8">Перевод рублей в GR
                                на сервер
                            </div>
                            <div class="serverTab_flexInputs_container">
                                <input type="text" class="graceTabInput" placeholder="Рубли" v-model="inRubCash"
                                       @keypress="onlyNumber" @keydown="updatingTransferPriceGr"
                                       @keyup="updatingTransferPriceGr">
                                <div class="arrowRight">→</div>
                                <input type="text" class="graceTabInput" placeholder="GR" v-model="inGrCash"
                                       @keypress="onlyNumber" @keydown="updatingTransferPriceRub"
                                       @keyup="updatingTransferPriceRub">
                            </div>

                            <div class="s-f-trow-btn" style="margin-top: 10px"
                                 v-if="inGrCash" @click="rubToGrOpenConfirmModal">Выполнить
                                перевод
                            </div>
                            <div class="s-f-trow-btn prefixTab_inactive"
                                 style="margin-top: 10px" v-else>Выполнить перевод
                            </div>
                        </div>
                    </div>

                    <div class="s-f-wrapper"
                         v-if="selectedSubServerTab === 'varpsTab'">
                        <div class="warpsTab_warpsBlock">

                            <div class="warpsTab_unsetedWarps_wrapp">
                                <div class="warpsTab_unsetedWarps"
                                     v-if="serversWarpsData[selectedServerTab].length > 0">
                                    <div class="wt-un-wa-count"
                                         v-if="serversWarpsData[selectedServerTab].filter(server => server.warp_name === null).length > 0">
                                        У вас есть
                                        {{
                                            serversWarpsData[selectedServerTab].filter(server => server.warp_name === null).length
                                        }}
                                        {{
                                            declOfNum(serversWarpsData[selectedServerTab].filter(server => server.warp_name === null).length, ['неустановленный варп', 'неустановленных варпов', 'неустановленных варпов'])
                                        }}
                                    </div>
                                    <div class="wt-un-wa-count" v-else>
                                        Все варпы установлены
                                    </div>

                                    <div class="btn-group-wrapper">
                                        <div class="single-use-buy-btn lk-servers-btn"
                                             @click="buyWarpOpenModal('single_warp')">Купить одноразовый
                                        </div>
                                        <div class="multi-use-buy-btn lk-servers-btn"
                                             @click="buyWarpOpenModal('multi_warp')">Купить многоразовый
                                        </div>
                                    </div>
                                </div>

                                <div class="warpsTab_unsetedWarps_col" v-else>
                                    <div class="wt-un-wa-count">
                                        У вас нет приобретённых варпов
                                    </div>

                                    <div class="btn-group-col-wrapper">
                                        <div class="single-use-buy-btn lk-servers-btn"
                                             @click="buyWarpOpenModal('single_warp')">Купить одноразовый
                                        </div>
                                        <div class="multi-use-buy-btn lk-servers-btn"
                                             @click="buyWarpOpenModal('multi_warp')">Купить многоразовый
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <table class="warpsTable" v-if="serversWarpsData[selectedServerTab].length > 0">
                                <thead>
                                <tr class="warpsTable_head_tr">
                                    <th>Название</th>
                                    <th>Тип варпа</th>
                                    <th style="width: 12%">Мир</th>
                                    <th>Владелец</th>
                                    <th style="width: 8%">X</th>
                                    <th style="width: 8%">Y</th>
                                    <th style="width: 8%">Z</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="varpsData in serversWarpsData[selectedServerTab]" class="warpsTable_body_tr">
                                    <td>{{ varpsData.warp_name }}</td>
                                    <td>
                                        {{
                                            varpsData.warp_type == 0 ? 'Однораз.' : varpsData.warp_type == 1 ? 'Многораз.' : 'Очень странный варп'
                                        }}
                                    </td>
                                    <td>{{ varpsData.world_name }}</td>
                                    <td>{{ varpsData.owner_name }}</td>
                                    <td>{{ Math.round(varpsData.x) }}</td>
                                    <td>{{ Math.round(varpsData.y) }}</td>
                                    <td>{{ Math.round(varpsData.z) }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="s-f-wrapper"
                         v-if="selectedSubServerTab === 'punsTab'">
                        <div class="punsTab_pubsBlock">
                            <div class="statusTab_statusTableBlock" style="margin-top: 10px">
                                <table class="statusTable">
                                    <colgroup>
                                        <col/>
                                        <col/>
                                        <col/>
                                        <col/>
                                    </colgroup>

                                    <thead>
                                    <tr>
                                        <th class="statusTable_title">Наказание</th>
                                        <th class="statusTable_title">Причина</th>
                                        <th class="statusTable_title">Срок действия</th>
                                        <th class="statusTable_title">Модератор</th>
                                        <th class="statusTable_title">Цена залога</th>
                                        <th class="statusTable_title">Решаем вопросик?</th>
                                    </tr>
                                    </thead>

                                    <tbody style="margin-top: 10px;">
                                    <tr v-for="i of serversData['servers'][selectedServerTab].possiblePuns">
                                        <td class="statusServerTab_th">{{ i.type }}</td>
                                        <td class="statusServerTab_th">
                                            <div class="sst-th-center-wrapp">
                                                {{i.reason}}
                                            </div>
                                        </td>
                                        <td class="statusServerTab_th">
                                            {{
                                                i.expire !== 0 ? datetimeNormalizer(i.expire_at, true) : 'Вечный бан'
                                            }}
                                        </td>
                                        <td class="statusServerTab_th">
                                            {{i.moderator}}
                                        </td>
                                        <td class="statusServerTab_th">
                                            {{i.unpun_price.toFixed(2)}}
                                        </td>
                                        <td class="statusServerTab_th">
                                            <div class="sst-th-center-wrapp">
                                                <div class="lk-servers-btn" @click="buyUnPunOpenModal(i.type, i.id, i.server_id, i.unpun_price)">
                                                    Снимаем наказание
                                                </div>
                                            </div>
                                        </td>


                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import StatusBuyModal from "../../../modals/StatusBuyModal";
import confirmModal from "../../../modals/ConfirmModal";

import {TimeFormatterMixin} from "../../../../mixins/TimeFormatterMixin";
import {TextInputControlMixin} from "../../../../mixins/TextInputControlMixin";
import {LogItMixin} from "../../../../mixins/LogItMixin";
import {DeclOfNumMixin} from "../../../../mixins/DeclOfNumMixin";

export default {
    name: "LkServers",
    mixins: [TextInputControlMixin, TimeFormatterMixin, DeclOfNumMixin],
    data() {
        return {
            serversData: {'servers': []},
            selectedServerTab: null,
            selectedSubServerTab: null,
            //rerender servers block
            dateKey: 0,

            serversGracesData: null,
            serversWarpsData: null,

            subServerTitles: {
                'serverTab': 'опции сервера.',
                'statusTab': 'текущие статусы.',
                'prefixTab': 'настройка чата.',
                'graceTab': 'конвертация валюты.',
                'varpsTab': 'варпы.',
                'punsTab': 'наказания.'
            },

            colorDecorObj: {
                // color's
                '&0': '#000',
                '&1': '#0000aa',
                '&2': '#00aa00',
                '&3': '#00aaaa',
                '&4': '#aa0000',
                '&5': '#aa00aa',
                '&6': '#ffaa00',
                '&7': '#aaaaaa',
                '&8': '#555555',
                '&9': '#5555ff',
                '&a': '#55ff55',
                '&b': '#55ffff',
                '&c': '#ff5555',
                '&d': '#ff55ff',
                '&e': '#ffff55',
                '&f': '#fff',
            },

            textDecorObj: {
                // font-weight
                '&l': '600',
                // text-decoration
                '&m': 'line-through',
                '&n': 'underline',
                // font-style:
                '&o': 'italic',
            },

            prefixText: '&f',
            prefixColor: '&f',
            nickColor: '',
            prefixFeatureText: '',
            changedCustom: {
                prefix: false,
                prefixColor: false,
                nickColor: false,
                feature: false
            },

            inRubCash: null,
            inGrCash: null,
        }
    },
    methods: {
        // Выгрузка даты об юзере в локальные поля компонента.
        prepareServerTabsPlayerData() {
            let sdata = {
                'player_name': this.$root.getPlayerData().name,
                'servers': this.$root.getPlayerData()['luckperms'],
                'puns': this.$root.getPlayerData()['puns']
            }

            for (let k of Object.keys(sdata['servers'])) {
                // обогащение объекта серверов - наказаниями.
                sdata.servers[k].possiblePuns = []
                sdata.puns.forEach(pun => {
                    if (pun.server === k)
                        sdata.servers[k].possiblePuns.push(pun)
                })

                sdata.servers[k].visible = true
                sdata.servers[k].subVisibleTabs = {
                    'statusTab': false,
                    'prefixTab': false,
                    'graceTab': false,
                    'varpsTab': false,
                    'punsTab': false,
                }

            }
            this.serversData = sdata
        },

        // обрезка с учетом декора
        getClicedDecorated(str, len) {
            let length_without_symbols = 0
            let length_pos = 0
            for (let v = 0; v < str.length; v++) {
                if (str[v] === '&' && str.length > v + 1) {
                    let char = str[v] + str[v + 1]
                    if (char in this.colorDecorObj || char in this.textDecorObj || char === '&r') {
                        v++
                        length_pos += 2
                        continue
                    }
                }
                length_pos++
                length_without_symbols++
                if (length_without_symbols >= len) {
                    str = str.slice(0, length_pos) + '...'
                    break
                }
            }
            return this.createDecorObj(str)
        },

        // Метод обновления даты
        updateServerTabsPlayerData() {
            const tempTabStates = this.serversData
            let sdata = {
                player_name: this.$root.getPlayerData().name,
                servers: this.$root.getPlayerData()['luckperms'],
                puns: this.$root.getPlayerData()['puns']
            }
            for (let k of Object.keys(sdata['servers'])) {
                sdata.servers[k].possiblePuns = []
                sdata.puns.forEach(pun => {
                    if (pun.server === k)
                        sdata.servers[k].possiblePuns.push(pun)
                })

                sdata['servers'][k].visible = tempTabStates['servers'][k].visible
                sdata['servers'][k].subVisibleTabs = tempTabStates['servers'][k].subVisibleTabs
            }
            this.serversData = sdata
        },

        syncUpdate_playerData(backToMenu = false) {
            let self = this
            self.$root.update_PlayerData().then(() => {
                self.getPlayerWarpsData()
                self.getPlayerGraceBalance()
                self.updateServerTabsPlayerData()
                self.resetChatFormatting(self.selectedServerTab)

                if (backToMenu && self.serversData.servers[self.selectedServerTab].possiblePuns.length == 0) {
                    self.toogleBackServerTabFrame()
                }
            })
        },

        // Обработка нажатия на какой либо сервер в меню.
        toogleSomeServerTabFrame(serverName, pun = false) {
            if (pun) return
            if (this.selectedServerTab === null) {
                for (let k of Object.keys(this.serversData['servers'])) {
                    this.serversData['servers'][k].visible = false
                }
                this.serversData['servers'][serverName].visible = true
                this.selectedServerTab = serverName
            }

            // Сначала будет вызываться метод более вложенный в блок, эта проверка нужна,
            // что бы в случае не нажатия на какую либо подгруппу, открылась первая вкладка.
            if (this.selectedSubServerTab === null)
                this.selectedSubServerTab = 'serverTab'
        },

        // Обработка нажатия на подтайтл меню серверов
        // При первой нажатии на тайтл подруппы, сначала вызовется этот метод, а уже после тот что выше.
        toogleSomeServerSubTabFrame(subtabName, serverName) {
            for (let k of Object.keys(this.serversData['servers'][serverName].subVisibleTabs)) {
                this.serversData['servers'][serverName].subVisibleTabs[k] = false
            }
            this.serversData['servers'][serverName].subVisibleTabs[subtabName] = true
            this.selectedSubServerTab = subtabName

            if (subtabName === 'prefixTab') {
                this.resetChatFormatting(serverName)
            }
        },

        updateFormating() {
            this.prefixFeatureText = this.prefixColor + this.prefixText + ' ' + this.nickColor
            this.checkChangedNoFeature();
        },

        checkChangedNoFeature() {
            this.changedCustom.feature = false
            let old = this.serversData['servers'][this.selectedServerTab].prefix
            this.changedCustom.nickColor = this.nickColor !== old.slice(old.length - 3).trim()
            this.changedCustom.prefix = this.prefixText !== old.slice(2, old.length - 3)
            this.changedCustom.prefixColor = this.prefixColor !== old.slice(0, 2)
        },

        updateFormatingCustom() {
            if (/^&[\da-f][a-zа-яё_ .\-\d\[\]]+ &[\da-f]$/isu.test(this.prefixFeatureText)) {
                this.prefixText = this.prefixFeatureText.slice(2, this.prefixFeatureText.length - 3)
                this.prefixColor = this.prefixFeatureText.slice(0, 2)
                this.nickColor = this.prefixFeatureText.slice(this.prefixFeatureText.length - 3).trim()
                this.checkChangedNoFeature()
            } else {
                this.changedCustom.nickColor = false
                this.changedCustom.prefix = false
                this.changedCustom.prefixColor = false
                this.changedCustom.feature = this.serversData['servers'][this.selectedServerTab].prefix !== this.prefixFeatureText
            }
        },

        filterPrefix(evt) {
            let key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
            if (!/^[a-zа-яё_ .\-\d\[\]]+$/isu.test(key)) {
                event.preventDefault();
                return false;
            }
        },

        resetChatFormatting(serverName) {
            if (serverName === null)
                return
            this.prefixFeatureText = this.serversData['servers'][serverName].prefix
            this.prefixText = ''
            for (let p of this.createDecorObj(this.prefixFeatureText.slice(2, this.prefixFeatureText.length - 3))) {
                this.prefixText += Object.keys(p)[0]
            }

            this.prefixColor = this.prefixFeatureText.slice(0, 2)
            this.nickColor = this.prefixFeatureText.slice(this.prefixFeatureText.length - 3).trim()
            if (!(this.prefixColor in this.colorDecorObj))
                this.prefixColor = '&f'
            if (!(this.nickColor in this.colorDecorObj))
                this.nickColor = '&f'
            this.changedCustom.nickColor = false
            this.changedCustom.prefix = false
            this.changedCustom.prefixColor = false
            this.changedCustom.feature = false
        },

        setPrefixOpenBuyModal() {
            this.$modal.show(confirmModal, {
                "type": 'prefix',
                "operation": 'setPrefix',
                "data": {'serverName': this.selectedServerTab, 'prefixFeatureText': this.prefixFeatureText},
                "cost": this.changedCustom.feature * 199 + this.changedCustom.prefix * 49 + this.changedCustom.nickColor * 39 + this.changedCustom.prefixColor * 29,
            }, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "420px",
                height: "auto",
                reset: true,
            })
        },

        buyWarpOpenModal(type) {
            this.$modal.show(confirmModal, {
                'type': type,
                'operation': 'buyWarp',
                'data': {'serverName': this.selectedServerTab}
            }, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "420px",
                height: "auto",
                reset: true,
            })
        },

        buyUnPunOpenModal(type, pun_id, server_id, cost) {
            this.$modal.show(confirmModal, {
                type: type,
                cost: cost.toFixed(2),
                operation: 'buyUnPun',
                data: {serverName: this.selectedServerTab, serverId: server_id, punId: pun_id}
            }, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "420px",
                height: "auto",
                reset: true,
            })
        },

        // Обработка нажатия на кнопку возврата, обратно к меню серверов.
        toogleBackServerTabFrame() {
            for (let k of Object.keys(this.serversData['servers'])) {
                this.serversData['servers'][k].visible = true
            }
            this.selectedServerTab = null
            this.selectedSubServerTab = null
            this.inRubCash = null
            this.inGrCash = null
            this.serversKey++
        },

        // Получение информации о балансе грейсов игрока, на разных серверах.
        getPlayerGraceBalance() {
            require('axios').get('/api/game/balance').then(data => {
                this.serversGracesData = data.data
            }).catch(err => {
                console.log('Ошибка при получении информации о грейсах юзера.')
                console.log(err)
            })
        },

        // Метод сопоставления информации о грейсах со списком серверов.
        findGracePlayerData(serverName) {
            let finded = '?'
            for (let i in this.serversGracesData) {
                if (this.serversGracesData[i].server.name === serverName) {
                    finded = this.serversGracesData[i].balance
                    break
                }
            }
            return finded
        },

        getPlayerWarpsData() {
            require('axios').get('/api/warps').then(data => {
                this.serversWarpsData = data.data
            }).catch(err => {
                console.log('Произошла ошибка при получении информации о варпах')
                console.log(err)
            })
        },

        renewStatus(serverName, status_id, statusName) {
            this.$modal.show(
                StatusBuyModal,
                {
                    "statusName": statusName,
                    "status_id": status_id,
                    "serverName": serverName,
                    "rebuy": true,
                    "is_authorize": true,
                    "in_lk": true,
                },
                {
                    styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; ",
                    width: "430px",
                    height: "auto",
                    reset: true,
                }
            )
        },

        /*
        * Алгоритм создания массива с объектами декорации.
        * Этот алгоритм необходим, для создания каскада из спанов.
        *
        * Если тек символ & это означает что дальше будет символ форматирования.
        * Инициализируется захват дескриптора символа(символа форматирования).
        * Проверяется есть ли он в объекте декорационных значений. (Если есть - то далее
        * алгоритм использует этот дескриптор для всех значений которые в него прилетят, пока на проверку снова не
        * прийдёт знак амперсанта.)
        *
        * Алгоритм будет добавлять в массив chDecorObj, объекты до тех пор, пока не кончится строка.
        *
        * */
        createDecorObj(x) {
            let chDecorObj = [], textFormatDescriptor = []
            let colorFormatDescriptor = ''
            let initChangeDescriptor = false

            for (let char of x) {
                if (char === '&') {
                    initChangeDescriptor = true
                    continue
                }

                if (initChangeDescriptor) {
                    let noClassifiedDescriptor = `&${char}`

                    if (noClassifiedDescriptor in this.colorDecorObj) {
                        colorFormatDescriptor = noClassifiedDescriptor
                        textFormatDescriptor = []
                    } else if (noClassifiedDescriptor in this.textDecorObj)
                        textFormatDescriptor.push(noClassifiedDescriptor)
                    else if (noClassifiedDescriptor === '&r') {
                        colorFormatDescriptor = ''
                        textFormatDescriptor = []
                    } else {
                        let s1 = {}
                        s1['&'] = {
                            'colorFormat': colorFormatDescriptor,
                            'textFormat': [...textFormatDescriptor],
                        }
                        chDecorObj.push(s1)
                        let s2 = {}
                        s2[char] = {
                            'colorFormat': colorFormatDescriptor,
                            'textFormat': [...textFormatDescriptor],
                        }
                        chDecorObj.push(s2)
                    }
                    initChangeDescriptor = false
                    continue
                }

                let charObj = {}
                charObj[char] = {
                    'colorFormat': colorFormatDescriptor,
                    'textFormat': [...textFormatDescriptor],
                }

                chDecorObj.push(charObj)
            }

            return chDecorObj
        },

        setPrefixColor(color) {
            this.prefixColor = color
            this.updateFormating()
        },

        setNickColor(color) {
            this.nickColor = color
            this.updateFormating()
        },

        updatingTransferPriceGr() {
            this.inGrCash = this.inRubCash * 100
        },

        updatingTransferPriceRub() {
            this.inRubCash = this.inGrCash / 100
        },

        rubToGrOpenConfirmModal() {
            this.$modal.show(confirmModal, {
                "type": '',
                "operation": 'grTransfer',
                "data": {
                    'serverName': this.selectedServerTab,
                    'transferCost': this.inRubCash,
                    'gracesSummary': this.inGrCash
                },
                "cost": '',
            }, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "420px",
                height: "auto",
                reset: true,
            })
        },
        teleportSpawn() {
            this.$modal.show(confirmModal, {
                "type": '',
                "operation": 'teleportSpawn',
                "data": {
                    'serverName': this.selectedServerTab
                },
                "cost": '',
            }, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "420px",
                height: "auto",
                reset: true,
            })
        }
    },

    mounted() {
        this.$root.update_PlayerData();
    },

    created() {
        this.prepareServerTabsPlayerData()
        this.getPlayerGraceBalance()
        this.getPlayerWarpsData()

        let self = this
        this.$root.$on('lkRenewIsSuccess', function (edata) {
            if (edata === 'OK') self.syncUpdate_playerData()
            if (edata === 'PUNOK') self.syncUpdate_playerData(true)
        })
    },
    beforeDestroy() {
        this.$root.$off('lkRenewIsSuccess')
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.f-r {
    @include frame-row();
    height: auto;
}

.f-r-s-field {
    @include flexible-column-start();
    @include fill-all-block();
    background-color: $super-shitty-hex-gray;
    border-radius: 3px;
    padding-bottom: 20px;
    transition: all 0.5s ease;
}

.field-title {
    width: 10%;
    white-space: nowrap;
    font-size: 1.2em;
    color: $brightless-white;
    padding: 12px 21px;
}

.field-trow {
    @include flexible-row-start();
    width: 100%;
}

.s-f-wrapper,
.s-f-server-tab,
.s-f-status-tab,
.s-t-wrapp,
.s-f-trow-btn,
.sst-th-center-wrapp,
.prefix-tab-cfrm,
.lk-servers-btn {
    @include flexible()
}


.prefix-tab-cfrm,
.s-f-trow-btn {
    width: auto;
    height: 23px;
    padding: 0 10px;
    background-image: linear-gradient(to top, #3b3d90, #5f39c9, #522cb9), linear-gradient(to top, #1a1339, #5f36cf);
    border-radius: 5px;
    font-size: 0.80em;
    color: $brightless-white;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
        transform: scale(1.04);
        transition: all 0.5s ease;
    }
}

.prefix-tab-cfrm {
    font-size: 0.75em;
    font-weight: 300;
    width: auto;
    padding: 0 30px;
}

.prefixTab_inactive {
    background-image: none;
    background: rgba(128, 128, 128, 0.63);
    cursor: default;
    transition: all 0.5s ease;
}

.prefixTab_inactive:hover {
    transform: none;
}

.btn-wrapp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80%;
}

.r-f-servers {
    @include flexible-column-start();
    justify-content: space-evenly;
    width: 95%;
    height: 100%;
}

.r-f-concrete-server {
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 26px;
    margin: 4px 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.29);
    font-size: 0.82em;
    cursor: pointer;
}

.r-f-oslbg {
    transition: all 0.3s ease;

    &:hover {
        background: rgba(128, 128, 128, 0.04);
        transition: all 0.3s ease;
    }
}

.r-f-concrete-server-frame {
    @include flexible();
    width: 100%;
    height: 90%;
}

.s-t-wrapp {
    width: 110px;
    height: auto;
}

.smaller-tab {
    width: 70px;
}

.r-f-concrete-server-title,
.r-f-concrete-server-status,
.r-f-concrete-server-prefix,
.r-f-concrete-server-grace,
.r-f-concrete-server-warps {
    @include flexible();
    height: 100%;
    color: #b4b0b0;
    width: auto;
    padding: 5px 10px;
    -webkit-user-select: none;
    white-space: nowrap;

    &:hover {
        border-left: 1px solid rgba(128, 128, 128, 0.29);
        border-right: 1px solid rgba(128, 128, 128, 0.29);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background: rgba(42, 47, 47, 0.34);
        color: $not-bright-white;
    }

    &:active {
        background-image: linear-gradient(to top, #3b3d90, #5f39c9, #522cb9), linear-gradient(to top, #1a1339, #5f36cf);
        color: $not-bright-white;
    }

}

.tabbrdrz {
    border-left: 1px solid rgba(128, 128, 128, 0.29);
    border-right: 1px solid rgba(128, 128, 128, 0.29);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
}

//.tabrdrz-mini {
//    width: 100%;
//}

.s-f-wrapper {
    height: auto;
}

.s-f-status-tab,
.s-f-server-tab, {
    flex-direction: column;
    width: 100%;
    height: auto;
    transition: all 0.5s ease;
}

.statusTab_infos {
    @include flexible();
    width: 100%;
    margin-top: 15px;
}

.plainText {
    font-size: 0.85em;
    color: #dbd8d8;
}

.graceTable,
.prefixTable,
.statusTable {
    flex-direction: column;
    border-spacing: 0;
    width: 100%;
    height: auto;
    padding-top: 20px;
    border-collapse: collapse;
    border-top: none;
    font-family: $main-font;
    font-size: 0.9em;
    color: #dbd8d8;
}

.prefixTable {
    font-size: 1em;
}

.statusTable_title {
    width: 150px;
    height: 15px;
    font-family: $main-font;
    @include text-normalize();
    font-size: 1em;
    color: $not-bright-white;
    text-align: center;
    padding: 5px 0;
}

@mixin nt-normal() {
    @include text-normalize();
    font-family: $main-font;
    color: $not-bright-white;
    text-align: center;
}

.statusServerTab_th {
    width: 39px;
    height: 31px;
    @include nt-normal();
    font-size: 0.9em;
    border: 1px solid rgba(128, 128, 128, 0.29);
}

.graceServerTab_labelTd,
.prefixServerTab_labelTd {
    @include flexible();
    width: auto;
    height: 31px;
    @include nt-normal();
    font-size: 0.85em;
}

.prefixServerTab_inputTd {
    width: 400px;
    height: 31px;
    @include nt-normal();
    font-size: 0.85em;
}

.graceServerTab_costTd,
.prefixServerTab_costTd {
    width: 100px;
    height: 31px;
    font-size: 0.85em;
    @include nt-normal();
}

.lk-servers-btn {
    @include main-button(3px, 10px);
}

.statusTab_bigText {
    font-family: AventirN;
    font-size: 1.1em;
    color: #dbd8d8;
}

.graceTab_graceBlock,
.prefixTab_prefixBlock,
.warpsTab_warpsBlock,
.punsTab_pubsBlock {
    @include flexible-column();
    width: 100%;
}

.graceTabInput,
.prefixTabFinalView,
.prefixTabInput {
    width: 95%;
    height: 80%;
    border-radius: 3px;
    margin: 5px 0;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
    text-align: center;
    font-family: AventirN;
    font-size: 0.9em;
}

.prefixServerTab_color_buttons {
    @include flexible();
    width: 100%;
}

.colorSquare {
    width: 13px;
    height: 13px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 0 2px;

    &:hover {
        width: 15px;
        height: 15px;
        transition: all 0.05s ease-in-out;
        margin: 0 5px;
    }

    &:active {
        width: 17px;
        height: 17px;
        transition: all 0.09s ease-in-out;
        margin: 0 8px;
    }
}

.graceServerTab_costTd,
.graceServerTab_labelTd {
    justify-content: flex-start;
    text-align: left;
    font-size: 0.9em;
}

.graceTable {
    width: 90%;
}

.graceServerTab_info {
    width: 100%;
    text-align: center;
    font-size: 0.83em;
}

.serverTab_border {
    width: 120%;
    height: 1px;
    border-bottom: 1px groove gray;
}

.serverTab_border_smaller {
    width: 80%;
}

.arrowRight {
    @include flexible();
    text-align: center;
    font-size: 2em;
    color: rgb(255, 170, 0);
    height: 5px;
    white-space: nowrap;
    margin-top: -12px
}

.serverTab_flexInputs_container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.graceTabInput {
    width: 30%;
    height: 24px;

    &::placeholder {
        font-size: 0.9em;
    }
}

.warpsTab_unsetedWarps_wrapp {
    @include flexible;
    width: 100%;
    padding: 5px 0 10px 0;

    .warpsTab_unsetedWarps_col {
        @include flexible-column();

        @include title-1-1;
        @include text-normalize;
        color: $brightless-white;

        .btn-group-col-wrapper {
            @include flex-row();
            @include text-normalize();
            font-size: 0.9em;
            margin-top: 10px;

            .multi-use-buy-btn {
                margin-left: 5px;
            }
        }
    }

    .warpsTab_unsetedWarps {
        @include flexible-row-start;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        @include title-1-1;
        @include text-normalize;
        color: $brightless-white;

        .btn-group-wrapper {
            @include flex-row();
            @include text-normalize();
            font-size: 0.9em;

            .multi-use-buy-btn {
                margin-left: 10px;
            }
        }
    }
}


.warpsTable {
    display: table;
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
    text-align: center;
    border-collapse: collapse;
    border: 1px solid rgba(128, 128, 128, 0.29);

    font-family: $main-font;
    font-size: 0.8em;

    th {
        border: 1px solid rgba(128, 128, 128, 0.29);
    }

    td {
        border: 1px solid rgba(128, 128, 128, 0.29);
    }


    .warpsTable_head_tr {
        color: $brightless-white;
    }

    .warpsTable_body_tr {
        color: $dull-gray
    }

}

</style>