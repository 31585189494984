import axios from "axios";

export const chSCtypeMixin = {
    data() {
        return {
            textures: [],
            curPage: null,
            lastPage: null,
            trx_types: {
                skin: 'Обычный скин',
                cape: 'Обычный плащ',
                hd_skin: 'HD скин',
                hd_cape: 'HD плащ'
            }
        }
    },
    methods: {
        loadMoreOrBack(typePipe, filterPipe = '', next = true, first = false) {
            this.preloaderStartLk('.s-g-action-frame-wrappa')
            this.getTextures(typePipe, filterPipe, false, `&page=${this.curPage + (first ? 0 : next ? 1 : -1)}`)
        },

        getTextures(type, filters = '', needAdd = false, pagePipe = '') {

            axios.get(`/api/custom/gallery/${type}s?${filters}${pagePipe}&per_page=${this.perPage}`).then(
                data => {
                    this.curPage = data.data.current_page
                    if (!this.lastPage)
                        this.lastPage = data.data.last_page

                    if (!needAdd)
                        this.textures = []

                    this.$root.$emit('pagesCountPipe', {
                        pCountAll: data.data.last_page,
                        pCurrent: data.data.current_page,
                        txtrEvType: type,
                        txtrFilters: filters,
                        ppage: this.perPage,
                        txtrTotal: data.data.total
                    })

                    data.data.data.forEach(c => {
                        c.access_disabled = false
                        c.applying = false
                        c.deleting = false
                        this.textures.push(c)
                    })

                    this.preloaderStop('.s-g-action-frame-wrappa')
                })
        },

        set(texture, type) {
            if (texture.applying)
                return
            texture.applying = true
            let payload = {
                id: texture.id
            }
            axios.post('api/custom/gallery/set', payload).then(data => {
                this.$root.$emit('updVisualPresentation', {
                    operation: 'set',
                    type: type,
                    data: {data: {url: texture.url}}
                })
                this.$root.$emit('sexyGalleryUpdTimer')
                this.textures.forEach(s => {
                    s.set = 0
                    if (s.id === texture.id) {
                        s.set = 1
                        s.in_gallery = 1
                    }
                })
                texture.applying = false
            }).catch(err => {
                texture.applying = false
                texture.er = err.response.data.errors._all[0]
                console.log(err)
            })
        },

        chDel(texture, type) {
            if (texture.deleting)
                return
            texture.deleting = true
            let payload = {
                id: texture.id,
                type: 'deleted'
            }
            axios.post('api/custom/gallery/change', payload).then(data => {
                this.$root.$emit('sexyGalleryUpdTimer')
                this.$root.$emit('updVisualPresentation', {operation: 'delete'})
                let newtxts = []
                this.textures.forEach(s => {
                    if (s.id !== texture.id) {
                        newtxts.push(s)
                    }
                })
                this.textures = newtxts
                texture.deleting = false
            }).catch(err => {
                texture.deleting = false
                texture.er = err.response.data.errors._all[0]
                console.log(err)
            })
        },

        accessChange(texture) {
            if (texture.access_disabled)
                return
            texture.access_disabled = true
            let old_type = texture.type
            let payload = {
                id: texture.id,
                type: texture.type === 'private' ? 'public' : 'private'
            }
            texture.type = payload.type
            axios.post('api/custom/gallery/change', payload).then(data => {
                texture.type = data.data.type
                texture.access_disabled = false
            }).catch(err => {
                texture.access_disabled = false
                texture.type = old_type
                console.log(err)
            })
        },
        formatLinkToWebp(url) {
            return [url, '.webp'].join('')
        },
        scrollToTop() {
            this.$refs.arw.scrollTo(0, 0)
        }
    },
    mounted() {
        this.$root.$on('sempaiPlzLoadMoreThatShit', (data) => {
            this.scrollToTop()
            this.perPage = data.perPage ?? 8
            if (data.operation === 'loadMore')
                this.loadMoreOrBack(data.type, data.filters)
            else if (data.operation === 'loadBack')
                this.loadMoreOrBack(data.type, data.filters, false)
            else if (data.operation === 'reload') {
                this.curPage = 1
                this.loadMoreOrBack(data.type, data.filters, false, true)
            }
        })

    },
    beforeDestroy() {
        this.$root.$off('sempaiPlzLoadMoreThatShit')
    }
}