<template>
    <div class="act-row" ref="arw">
        <div v-if="textures.length" class="m-s-skin-frame" v-for="texture in textures">
            <div class="txr-type shad">{{trx_types[texture.texture_type]}}</div>
            <SkinViewer v-bind:skin-url="texture.url" frame="lkSkinFront" v-if="type==='skin'"/>
            <SkinViewer v-bind:cape-url="texture.url" frame="lkSkinBack" v-if="type==='cape'"/>

            <div class="m-button" v-tooltip="{content: 'Установка из своей галереи: <span>бесплатно!</span>',
                                    theme:'GalleryToolTips-top',
                                    html:true}" @click="set(texture, type)" v-if="!texture.set && !texture.applying">Применить</div>
            <div class="m-button" v-else-if="texture.applying">Применяем...</div>
            <div class="m-button-disabled" v-else>Установлен</div>
            <div class="m-button" @click="chDel(texture, type)" v-if="!texture.deleting">Удалить</div>
            <div class="m-button" v-else>Удаляем...</div>
            <div class="m-row" v-if="texture.source !== 'gallery'">
                <div class="lbl">Публичный</div>
                <label class="switch" style="margin-right: 47px">
                    <input type="checkbox" class="togglesw" @click="accessChange(texture)"
                           :checked="texture.type === 'public'" :disabled="texture.access_disabled">
                    <span class="sliderMods round"></span>
                </label>
            </div>
            <div class="m-row" v-else>
                <div class="lbl-once">Публичный</div>
            </div>
        </div>

    </div>
</template>

<script>

import {PreloaderMixin} from "@/mixins/PreloaderMixin";
import {sexyTooltipMixin} from "@/mixins/SexyTooltipMixin";
import {chSCtypeMixin} from "@/components/elements/lk/LkSkinGallery/chSCtypeMixin";
import SkinViewer from "@/components/elements/skin/SkinViewer";

export default {
    name: "Gallery",
    mixins: [PreloaderMixin, chSCtypeMixin, sexyTooltipMixin],
    components: {SkinViewer},
    props: ['type', 'filters', 'pp'],

    mounted() {
        if (this.pp !== undefined)
            this.perPage = this.pp
        this.preloaderStartLk('.s-g-action-frame-wrappa')
        this.getTextures(this.type, this.filters, false)
        this.syncTxrTimings('gallery')
    },
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/vars";
@import "src/assets/scss/mixins";
@import "src/assets/scss/animations";

.act-row {
    @include flexible-row-sp-ev;
    @include fill-all-block;

    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
}

.m-s-skin-frame {
    @include flexible-column;
    width: 200px;
    min-width: 180.5px;
    height: 95%;
    border-right: 1px groove rgba(128, 128, 128, 0.52);
    border-left: 1px groove rgba(128, 128, 128, 0.52);
    margin-top: 5px;
    padding-bottom: 5px;

}

.skin-m-frame {
    @include flexible-column();
}

.m-button, .m-button-disabled {
    @include main-button(5px, 5px);
    margin-top: 10px;
    width: 80%;
}

.m-button-disabled {
    background: #808080;
    cursor: default;

    &:hover {
        transform: none;
    }
}


.m-row {
    @include flexible-row;
    justify-content: space-between;
    margin-top: 5px;
    margin-left: 46px;

    .lbl, .lbl-once {
        text-align: center;
        font-family: $main-font;
        font-size: 0.8em;
        color: $brightless-white;
    }

    .lbl-once {
        margin-top: 5px;
        margin-right: 44px;
        height: 18px;
    }
}

@include switch(#5b5a5a, #583abe, #7a323b, white, 45px, 21px, 6px, 4px);

.switch {
    margin-left: 10px;
}

.blank-stabilize {
    display: block;
    height: 23px;
}

.txr-type {
    position: relative;
    width: 100%;
    text-align: right;
    margin-right: 20px;
    font-family: $main-font;
    font-size: 0.8em;
    color: $brightless-white;
}

.shad {
    text-shadow: rgba(203, 203, 203, 0.9) 0 0 5px;
}

</style>